import {
  LOAD_FAVORITES_SUCCESS,
  PROFILE_SITTER_REQUEST,
  PROFILE_SITTER_SUCCESS,
  PROFILE_SITTER_FAILURE,
  PROFILE_SITTER_REVIEWS_SUCCESS,
  PROFILE_SITTER_REVIEWS_REQUEST,
  PROFILE_SITTER_REFERENCES_SUCCESS,
  PROFILE_SITTER_REFERENCES_REQUEST,
  PROVIDER_SCHEDULE_SUCCESS
} from 'constants/parent/profile';

export function getDefaultState () {
  return {
    availabilities: [],
    loading: true,
    reviews: null,
    reviewsCount: 0,
    references: {},
    sitter: {},
    favorites: []
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === PROVIDER_SCHEDULE_SUCCESS) {
    const {availabilities} = action.response.entities.availability[undefined];
    return {
      ...state,
      availabilities
    };
  }
  if (action.type === LOAD_FAVORITES_SUCCESS) {
    return {
      ...state,
      favorites: action.response.result.sitters.map(i => action.response.entities.sitter[i])
    };
  }
  if (action.type === PROFILE_SITTER_REFERENCES_REQUEST) {
    return {
      ...state,
      references: {}
    };
  }
  if (action.type === PROFILE_SITTER_REFERENCES_SUCCESS) {
    const {reference} = action.response.entities;
    return {
      ...state,
      references: reference || {}
    };
  }
  if (action.type === PROFILE_SITTER_REVIEWS_REQUEST) {
    return {
      ...state,
      reviews: null
    };
  }
  if (action.type === PROFILE_SITTER_REVIEWS_SUCCESS) {
    const reviews = action.response.entities.reviews || null;
    const reviewsCount = action.response.result.total_count || 0;

    return {
      ...state,
      reviews,
      reviewsCount
    };
  }
  if (action.type === PROFILE_SITTER_REQUEST) {
    return {
      ...state,
      sitter: {},
      loading: true
    };
  }
  if (action.type === PROFILE_SITTER_SUCCESS) {
    const sitter = action.response.entities.sitter[action.response.result];
    return {
      ...state,
      sitter,
      loading: false
    };
  }
  if (action.type === PROFILE_SITTER_FAILURE) {
    return {
      ...state,
      loading: false
    };
  }
  return state;
}
