import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {Link} from 'react-router';
import {IntercomAPI} from 'react-intercom';

import * as SVGIcon from 'components/SVGIcon';
import {HOME_PAGE} from 'constants/parent/navigation';
import {track} from 'utils/track';
import FormButton from 'forms/controls/FormButton';
import {setTitle as setTitleAction} from 'actions/navigation';
import {
  listOpenSearches as listOpenSearchesAction,
  setConversationId as setConversationIdAction,
  closeSearch as closeSearchAction
} from 'actions/parent/search';

export class OpenSearches extends React.Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    setConversationId: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    listOpenSearches: PropTypes.func.isRequired,
    openSearches: PropTypes.array.isRequired
  };

  constructor (props) {
    super(props);
    this.buildSearchItem = this.renderSearchItem();
  }

  componentDidMount () {
    const {authToken} = this.props;
    this.props.setTitle('Open Search Requests');
    track('Open Searches Page');
    this.props.listOpenSearches(authToken);
  }

  renderSearchItem () {
    return (search, key) => {
      const {
        child_profiles, // eslint-disable-line camelcase
        location,
        start_time, // eslint-disable-line camelcase
        end_time // eslint-disable-line camelcase
      } = search;
      const {authToken} = this.props;
      const ChildIcon = SVGIcon.ChildInfant;
      return ( /* eslint-disable camelcase */
        <li
            className="u-margin-bottom c-open-search__item u-padding-bottom"
            {...{key}}
        >
          <div className="o-layout">
            <div className="o-layout__item u-3/5">
              <h3 className="c-h3 u-margin-bottom-tiny">{moment(start_time).format('MMMM D')}</h3>
              <h3 className="c-h3 u-margin-bottom-tiny">{moment(start_time).format('h:mma')}{' - '}{moment(end_time).format('h:mma')}</h3>
              <p className="c-text-small c-text-small--muted u-margin-bottom-tiny">{`${location.street}, ${location.city}, ${location.state} ${location.zip_code}`}</p>
              <p className="u-margin-bottom-tiny"><span className="c-icon-medium"><ChildIcon active /></span> <span className="c-text-small">{child_profiles.map(c => c.first_name).join(', ')}</span></p>
            </div>
            <div className="o-layout__item u-2/5">
              <FormButton
                  className="c-form-button--primary c-form-button--inverse c-form-button--block"
                  label="View"
                  onClick={() => {
                    // search.intercom_conversation_id
                    IntercomAPI('showMessages');
                  }}
                  type="button"
              />
              <FormButton
                  className="c-form-button--primary c-form-button--destructive c-form-button--inverse c-form-button--block"
                  label="Close"
                  onClick={() => {
                    if (confirm('Are you sure you\'d like to close this search?')) { // eslint-disable-line no-alert
                      this.props.closeSearch(authToken, search.id).then(() => this.props.listOpenSearches(authToken));
                    }
                  }}
                  type="button"
              />
            </div>
          </div>
        </li>
      ); /* eslint-enable camelcase */
    };
  }

  render () {
    const {openSearches} = this.props;
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/2@tablet u-push-1/4@tablet">
          <h1 className="c-h1 u-center-text c-search-header">{'Open Search Requests'}</h1>
          <ul className="o-list-bare">
            {openSearches.map(this.buildSearchItem)}
          </ul>
          {!openSearches.length && (
            <div className="u-center-text">
              <p>{'Once you request to be notified, all of your open search requests will appear here.'}</p>
              <Link
                  className="c-form-button c-form-button--primary c-form-button--inverse"
                  to={`/${HOME_PAGE}`}
              >{'Search for providers'}</Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const {
    session: {
      authToken
    },
    search: {
      openSearches
    }
  } = state;
  return {
    authToken,
    openSearches
  };
}

const mapDispatchToProps = {
  setTitle: setTitleAction,
  listOpenSearches: listOpenSearchesAction,
  closeSearch: closeSearchAction,
  setConversationId: setConversationIdAction
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenSearches);
