import {schema} from 'normalizr';

// import {location as locationSchema} from 'schemas/location';

export const sitter = new schema.Entity(
  'sitter',
  {},
  { idAttribute: 'id' }
);

export const sitters = { sitters: new schema.Array(sitter) };

/*
sitter.define({
  default_location: locationSchema // eslint-disable-line camelcase
});
*/
