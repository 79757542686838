import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FormSection
} from 'redux-form';

import {
  formatters,
  parsers
} from 'forms';
import AddressControl from 'forms/controls/Address';
import FormCheckbox from 'forms/controls/FormCheckbox';
import ScheduleFields from 'forms/fields/Schedule';

export default class SearchFields extends React.Component {
  static propTypes = {
    noRemove: PropTypes.bool,
    targetDateActive: PropTypes.bool.isRequired,
    timeRangeActive: PropTypes.bool.isRequired,
    childProfiles: PropTypes.array.isRequired,
    constants: PropTypes.object.isRequired,
    error: PropTypes.string,
    max: PropTypes.string,
    min: PropTypes.string,
    onFormChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    noRemove: false
  };

  render () {
    const {
      noRemove,
      timeRangeActive,
      targetDateActive,
      error,
      childProfiles,
      constants,
      onFormChange,
      min,
      max
    } = this.props;
    const childrenOptions = childProfiles.map(({id, first_name}) => ({ // eslint-disable-line camelcase
      classes: 'u-1/3',
      label: first_name, // eslint-disable-line camelcase
      value: '' + id
    }));
    const petOptions = [ // refactor, extract pet control/field
      {
        classes: 'u-1/3',
        icon: 'PetDog',
        label: 'Dogs',
        value: 'has_dogs',
      }, {
        classes: 'u-1/3',
        icon: 'PetCat',
        label: 'Cats',
        value: 'has_cats'
      }, {
        classes: 'u-1/3',
        icon: 'PetBird',
        label: 'Other',
        value: 'has_other'
      }
    ];
    return (
      <div>
        <Field
            component={AddressControl}
            formatter={formatters.JSON}
            name="address"
            parser={parsers.JSON}
        />
        <div className="c-schedule-search">
          <FormSection name="schedule">
            <ScheduleFields {...{constants, onFormChange, error, min, max, targetDateActive, timeRangeActive, noRemove}} />
          </FormSection>
        </div>
        <div className="u-clearfix">
          <div className="c-form-section">
            <Field
                bordered
                component={FormCheckbox}
                label="Select Children"
                limit={constants.booking.max_children}
                name="children"
                options={childrenOptions}
            />
          </div>
        </div>
        <Field
            bordered
            component={FormCheckbox}
            label="Select Pets"
            name="pets"
            options={petOptions}
        />
      </div>
    );
  }
}
