import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  Field,
  reduxForm
} from 'redux-form';

import Radio from 'forms/controls/Radio';
import FormButton from 'forms/controls/FormButton';
import {
  validateFields
} from 'utils/formValidations';

export class PaymentComponent extends React.Component {
  static propTypes = {
    creditPurchaseCopy: PropTypes.object.isRequired,
    creditPurchaseOptions: PropTypes.object.isRequired
  };

  render () {
    const {
      // creditPurchaseCopy,
      creditPurchaseOptions,
      handleSubmit
    } = this.props;
    const options = Object.keys(creditPurchaseOptions.options).map(key => {
      const {
        cost,
        credits,
        savings
      } = creditPurchaseOptions.options[key];
      return ({
        content: () => (
          <div className="o-layout u-center-text">
            <div className="o-layout__item u-1/3 c-primary-text">
              <h3 className="u-margin-bottom-none c-h3">{`$${cost}`}</h3>
              <p className="c-text-small u-margin-bottom-none">{'Cost'}</p>
            </div>
            <div className="o-layout__item u-1/3">
              <h3 className="u-margin-bottom-none c-h3">{`$${credits}`}</h3>
              <p className="c-text-small c-text-small--muted u-margin-bottom-none">{'Credits'}</p>
            </div>
            <div className="o-layout__item u-1/3">
              <h3 className="u-margin-bottom-none c-h3">{`${savings}%`}</h3>
              <p className="c-text-small c-text-small--muted u-margin-bottom-none">{'Savings'}</p>
            </div>
          </div>
        ),
        value: key
      });
    });
    return (
      <form onSubmit={handleSubmit}>
        <FormSection name="creditPurchase">
          <Field
              component={Radio}
              name="creditOption"
              {...{options}}
          />
        </FormSection>
        <div>
          <FormButton
              className="c-form-button--primary c-form-button--block"
              label="Purchase"
              type="submit"
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'purchaseCredits',
  validate: validateFields({
  }, {
  })
})(PaymentComponent);
