import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {browserHistory} from 'react-router';

import {track} from 'utils/track';
import {setTitle as setTitleAction} from 'actions/navigation';
import {HOME_PAGE} from 'constants/parent/navigation';
import {PAYMENT_STEP} from 'constants/parent/signUpFlow';
import {COMPLETE_REGISTRATION_PAGE} from 'constants/parent/navigation';
import PurchaseCreditsForm from 'forms/PurchaseCredits';
import {purchaseCredit as purchaseCreditAction} from 'actions/parent/booking';
import {PURCHASE_CREDIT_SUCCESS} from 'constants/parent/booking';
import FormButton from 'forms/controls/FormButton';
import {
  closeModal as closeModalAction,
  openModal as openModalAction
} from 'actions/modal';

export class PurchaseCredits extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    purchaseCredit: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    constants: PropTypes.object,
    copy: PropTypes.object,
    setTitle: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.handleSubmit = this.onSubmit();
  }

  componentDidMount () {
    track('Buy Credits Page');
    this.props.setTitle('Buy Credits');
  }

  componentWillReceiveProps (newProps) {
    const {
      user,
      router
    } = newProps;

    if (user && !user.status.has_payment_method) { // eslint-disable-line no-constant-condition
      router.replace({
        pathname: `/${COMPLETE_REGISTRATION_PAGE}/${PAYMENT_STEP}`,
        state: {nextPathname: `${router.location.pathname}${router.location.search}`}
      });
    }
  }

  onSubmit () {
    return ({creditPurchase}) => {
      const {
        authToken,
        constants,
        copy,
        purchaseCredit
      } = this.props;
      const creditOption = constants.options[creditPurchase.creditOption];
      if (confirm(copy.credit_purchase.confirmation.body.split(/:cost/).join(creditOption.cost))) { // eslint-disable-line no-alert
        purchaseCredit(authToken, creditPurchase.creditOption).then(action => {
          if (action.type === PURCHASE_CREDIT_SUCCESS) {
            this.props.openModal(() => ( // refactor, extract this.  I got so lazy here...
              <div>
                <p className="u-center-text">{'Success!'}</p>
                <FormButton
                    className="c-form-button--primary c-form-button--block c-form-button--inverse"
                    label="Done"
                    onClick={() => {
                      browserHistory.push(`/${HOME_PAGE}`); // refactor, extract action and saga
                      this.props.closeModal();
                    }}
                    type="button"
                />
              </div>
            ), 'Credits Purchased', {
            }, {
              'c-discount-code-modal': true
            });
          }
        });
      }
    };
  }

  render () {
    const {
      constants,
      copy
    } = this.props;
    if (!constants || !copy) {
      return (
        <h1 className="c-h1">{'Loading...'}</h1>
      );
    }
    const creditPurchaseCopy = copy.credit_purchase;
    const creditPurchaseOptions = constants;
    const initialValues = {
      creditPurchase: {
        creditOption: 'option1'
      }
    };
    return ( /* eslint-disable camelcase */
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/2@desktop u-push-1/4@desktop">
          <h1 className="c-h1 u-center-text c-search-header">{'Buy Credits'}</h1>
          <p className="u-center-text u-margin-bottom-large">{creditPurchaseCopy.options.title}</p>
          <PurchaseCreditsForm
              onSubmit={this.handleSubmit}
              {...{creditPurchaseCopy, creditPurchaseOptions, initialValues}}
          />
          <p className="c-text-small c-text-small--muted u-center-text u-margin-top">{creditPurchaseCopy.options.disclaimer}</p>
        </div>
      </div>
    ); /* eslint-enable camelcase */
  }
}

function mapStateToProps (state, ownProps) {
  const {
    settings: {
      constants: {
        credit_purchase // eslint-disable-line camelcase
      },
      copy: {
        en
      }
    },
    session: {
      authToken,
      user
    }
  } = state;
  return {
    authToken,
    constants: credit_purchase, // eslint-disable-line camelcase
    copy: en,
    user: user,
    router: ownProps.router
  };
}

const mapDispatchToProps = {
  setTitle: setTitleAction,
  purchaseCredit: purchaseCreditAction,
  openModal: openModalAction,
  closeModal: closeModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseCredits);
