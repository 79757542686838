import React from 'react';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import FormButton from 'forms/controls/FormButton';
import ReasonFeedbackField from 'forms/fields/ReasonFeedback';
import {
  validateFields,
  validateReasonFeedback
} from 'utils/formValidations';

export class WaitListComponent extends React.Component {
  render () {
    const {handleSubmit} = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <FormSection name="reasonFeedback">
            <ReasonFeedbackField />
          </FormSection>
          <div>
            <div className="o-layout__item u-1/1 u-1/2@tablet">
              <FormButton
                  className="c-form-button--primary c-form-button--block"
                  label="No, Keep Booking"
                  onClick={() => this.props.closeModal()}
                  type="button"
              />
            </div>
            <div className="o-layout__item u-1/1 u-1/2@tablet">
              <FormButton
                  className="c-form-button--primary c-form-button--block c-form-button--destructive"
                  label="Yes, Cancel Booking"
                  type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'bookingCancel',
  validate: validateFields({
    reasonFeedback: validateReasonFeedback
  })
})(WaitListComponent);
