import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';

import {setTitle as setTitleAction} from 'actions/navigation';
import RegisterForm from 'forms/Register';
import WaitListForm from 'forms/WaitList';
import ZipForm from 'forms/Zip';
import {LOGIN_PAGE} from 'constants/parent/navigation';
import {DEFAULT_PARENT_PHOTO_URL} from 'constants/settings';
import {
  auth as facebookAuthAction,
  getLoginStatus as getLoginStatusAction
} from 'actions/facebook';
import {
  REGISTER_STEP,
  WAIT_LIST,
  ZIP_STEP,
  ZIP_SUCCESS,
  ZIP_FAILURE
} from 'constants/parent/signUpFlow';
import {
  submitProfile as submitProfileAction,
  moveToRegisterStep as moveToRegisterStepAction,
  moveToWaitList as moveToWaitListAction,
  registration as registrationAction,
  waitListRegistration as waitListRegistrationAction,
  setZip as setZipAction,
  checkZip as checkZipAction
} from 'actions/parent/signUp';

import {
  change as changeFormAction,
} from 'redux-form';

export class SignUp extends React.Component {
  static propTypes = {
    setZip: PropTypes.func.isRequired,
    facebookAuth: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    moveToRegisterStep: PropTypes.func.isRequired,
    moveToWaitList: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    registration: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    constants: PropTypes.object,
    setTitle: PropTypes.func.isRequired,
    waitListRegistration: PropTypes.func.isRequired,
    submitProfile: PropTypes.func.isRequired
  };

  componentDidMount () {
    this.props.setTitle('Sign up');
  }

  currentFormStep (currentStep, form, constants, params, isLoading) { // refactor, extract these
    switch (currentStep) {
      case ZIP_STEP:
        return (
          <ZipForm
              errors={this.props.form.zip}
              onSubmit={({zip}) => {
                this.props.checkZip(zip.zip).then(action => {
                  this.props.setZip(zip.zip);
                  if (action.type === ZIP_SUCCESS) {
                    if (action.response.entities.location[action.response.result].active) {
                      this.props.moveToRegisterStep(zip.zip);
                    } else {
                      this.props.moveToWaitList(zip.zip);
                    }
                  } else if (action.type === ZIP_FAILURE) {
                    this.props.changeForm('zip', 'zip.error', 'error');
                  }
                });
              }}
          />
        );
      case REGISTER_STEP: {
        const {getLoginStatus} = this.props;
        let facebookDisabled = false;
        if (this.props.form.signUp) {
          if (process.env.APP_ROLE === 'sitter') {
            facebookDisabled = !(this.props.form.signUp.values.registration.acceptedTerms && this.props.form.signUp.values.registration.acceptAge);
          } else {
            facebookDisabled = !this.props.form.signUp.values.registration.acceptedTerms;
          }
        }
        const initialValues = {
          registration: {
            acceptedTerms: false
          }
        };
        return (
          <RegisterForm
              {...{getLoginStatus, isLoading, initialValues, facebookDisabled}}
              onFacebookClick={() => {
                this.props.facebookAuth();
              }}
              onSubmit={({registration}) => { // refactor, extract this
                const {zipCode} = params;
                const {
                  email,
                  firstLastName,
                  password,
                  phoneNumber
                } = registration;
                this.props.registration(email, firstLastName, password, phoneNumber, zipCode).then(action => { // refactor, extract saga
                  const authToken = action.response.entities.user[action.response.result].authentication_token;
                  return this.props.submitProfile(
                    authToken,
                    firstLastName,
                    email,
                    phoneNumber,
                    password,
                    DEFAULT_PARENT_PHOTO_URL);
                });
              }}
          />
        );
      }
      case WAIT_LIST: {
        return (
          <WaitListForm
              onSubmit={({waitList}) => {
                return this.props.waitListRegistration(waitList.email, params.zipCode);
              }}
              zipCode={params.zipCode}
          />
        );
      }
      default:
        return null;
    }
  }

  render () {
    const {
      constants,
      form,
      params,
      loading
    } = this.props;
    if (!constants || loading.constants) {
      return null;
    }
    const isLoading = loading.registration;
    const formStep = params.currentStep || ZIP_STEP;
    if (formStep == REGISTER_STEP && process.env.APP_ROLE === 'parent') {
      window.location.replace('https://usetrusted.com/care')
      return null
    }
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-2/6@desktop u-push-2/6@desktop">
          <h1 className="c-h1 u-center-text c-search-header">{'Sign up'}</h1>
          {this.currentFormStep(formStep, form, constants, params, isLoading)}
          <div className="u-center-text">
            <Link to={`/${LOGIN_PAGE}`}>{'Already have an account? Log in'}</Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state/* , ownProps */) {
  const {
    settings: {
      constants
    },
    form,
    session,
    loading
  } = state;
  return {
    form,
    constants,
    session,
    loading
  };
}

const mapDispatchToProps = {
  facebookAuth: facebookAuthAction,
  getLoginStatus: getLoginStatusAction,
  moveToRegisterStep: moveToRegisterStepAction,
  moveToWaitList: moveToWaitListAction,
  submitProfile: submitProfileAction,
  setTitle: setTitleAction,
  registration: registrationAction,
  waitListRegistration: waitListRegistrationAction,
  setZip: setZipAction,
  checkZip: checkZipAction,
  changeForm: changeFormAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
