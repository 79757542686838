import { channel, delay } from 'redux-saga'
import {
  put,
  take,
  select,
  fork
} from 'redux-saga/effects'

import ConfirmModal from 'containers/ConfirmModal'
import {
  getFeatures,
  getCopy
} from 'selectors/settings'
import {
  HEALTH_CHECK
} from 'constants/healthCheck'
import {
  openModal
} from 'actions/modal'
import {track} from 'utils/track'

export function *healthCheck () {
  while (true) { // eslint-disable-line no-constant-condition
    const action = yield take(HEALTH_CHECK);

    const features = yield select(getFeatures)

    if (features.essential_worker && process.env.APP_ROLE === 'parent') {
      yield fork(checkEssentialWorkerEffect, action);
    } else {
      yield fork(healthCheckEffect, action);
    }
  }
}

function *checkEssentialWorkerEffect (action) {
  const {
    onPass
  } = action

  track('Health Check Essential Worker Show')

  const copy = yield select(getCopy)

  const modalChannel = channel()

  yield put(openModal(ConfirmModal, copy.essential_worker.primary_prompt.title, {
    message: copy.essential_worker.primary_prompt.body_markdown.join('\n\n'),
    onConfirm: () => {
      track('Health Check Essential Worker Pass')
      modalChannel.put({
        type: 'MODAL_ACTION_CONFIRM',
        onPass
      })
    },
    onDeny: () => {
      track('Health Check Essential Worker Fail')
      modalChannel.put({
        type: 'MODAL_ACTION_DENY',
        onPass
      })
    }
  }, {}))

  const result = yield take(modalChannel);

  // TODO: Sometimes there's a longer delay than expected
  yield delay(15); // Race condition, to allow MODAL_CLOSE to finish

  if (result.type === 'MODAL_ACTION_CONFIRM') {
    yield fork(healthCheckEffect, result);
  } else {
    yield fork(essentialWorkerFailEffect, result);
  }
}

function *healthCheckEffect (action) {
  const {
    onPass
  } = action

  track('Health Check Primary Show')

  const copy = yield select(getCopy)

  const modalChannel = channel()

  yield put(openModal(ConfirmModal, copy.health_check.primary_prompt.title, {
    message: copy.health_check.primary_prompt.body_markdown.join('\n\n'),
    onConfirm: () => {
      track('Health Check Primary Fail')
      modalChannel.put({
        type: 'MODAL_ACTION_CONFIRM',
      })
    },
    onDeny: () => {
      track('Health Check Primary Pass')
      onPass()
    }
  }, {}))

  yield take(modalChannel);

  // TODO: Sometimes there's a longer delay than expected
  yield delay(15); // Race condition, to allow MODAL_CLOSE to finish

  yield fork(healthCheckFollowUpEffect, onPass);
}

function *healthCheckFollowUpEffect (onPass) {

  track('Health Check Secondary Show')

  const copy = yield select(getCopy)

  const modalChannel = channel()

  yield put(openModal(ConfirmModal, copy.health_check.secondary_prompt.title, {
    message: copy.health_check.secondary_prompt.body_markdown.join('\n\n'),
    onConfirm: () => {
      track('Health Check Secondary Pass')
      onPass()
    },
    onDeny: () => {
      track('Health Check Secondary Fail')
      modalChannel.put({
        type: 'MODAL_ACTION_DENY',
      })
    }
  }, {}))

  yield take(modalChannel);

  // TODO: Sometimes there's a longer delay than expected
  yield delay(15); // Race condition, to allow MODAL_CLOSE to finish

  yield fork(healthCheckFailEffect);
}

function *healthCheckFailEffect () {
  track('Health Check Failure Show')

  const copy = yield select(getCopy)

  yield put(openModal(ConfirmModal, copy.health_check.failure_prompt.title, {
    message: copy.health_check.failure_prompt.body_markdown.join('\n\n'),
    confirmText: copy.health_check.failure_prompt.action,
  }, {}))
}

function *essentialWorkerFailEffect () {
  track('Health Check Essential Worker Failure Dismiss')

  const copy = yield select(getCopy)

  yield put(openModal(ConfirmModal, copy.essential_worker.failure_prompt.title, {
    message: copy.essential_worker.failure_prompt.body_markdown.join('\n\n'),
    confirmText: copy.essential_worker.failure_prompt.action,
  }, {}))
}
