import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as SVGIcon from 'components/SVGIcon';

export default class FormRadio extends React.PureComponent {
  static propTypes = {
    error: PropTypes.string,
    hint: PropTypes.string,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      classes: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired
  };

  handleChange (value) {
    return () => {
      this.props.input.onChange(value);
    };
  }

  render () {
    const {
      hint,
      input: {
        value,
        name
      },
      meta: {
        error,
        touched
      },
      options
    } = this.props;
    const message = (touched && error) || hint;
    const className = classNames({
      'c-form-radio': true,
      'c-form-radio--error': (touched && !!error)
    });
    return (
      <div {...{className}}>
        <div className="o-layout">
          {options.map((field, key) => {
            const Icon = SVGIcon[field.icon];
            const fieldClasses = field.classes || '';
            return (
              <div
                  className={`c-form-radio__item o-layout__item ${fieldClasses}`}
                  {...{key}}
              >
                <input
                    checked={value === field.value}
                    className="c-form-radio__field u-hidden-visually"
                    id={`${name}-${key}`}
                    onChange={this.handleChange(field.value)}
                    type="radio"
                    value={field.value}
                    {...{name}}
                />
                <label
                    className="c-form-radio__label"
                    htmlFor={`${name}-${key}`}
                >
                  {field.icon && SVGIcon[field.icon] && (<Icon active={value === field.value} />)}
                  {field.label}
                </label>
              </div>
            );
          })}
          <div className="o-layout__item u-1/1 c-form-radio__message">
            <div className="c-form-radio__hint">{message}</div>
          </div>
        </div>
      </div>
    );
  }
}
