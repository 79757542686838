import {schema} from 'normalizr';

export const review = new schema.Entity(
  'reviews',
  {},
  { idAttribute: 'hash_id' }
);

export const reviews = { reviews: new schema.Array(review) };

export const reference = new schema.Entity(
  'references',
  {},
  {idAttribute: 'hash_id' }
);

export const references = { references: new schema.Array(reference) };

