import React from 'react';
import PropTypes from 'prop-types';
import {
  FieldArray
} from 'redux-form';

import {
  validatePaymentMethod,
  validateFieldArray
} from 'utils/formValidations';
import FormButton from 'forms/controls/FormButton';
import FormListItem from 'forms/controls/FormListItem';
import {
  PAYMENT_SUBMIT_SUCCESS,
  PAYMENT_SUCCESS
} from 'constants/parent/signUpFlow';

export default class PaymentMethodsFields extends React.Component {
  static propTypes = {
    readOnly: PropTypes.bool
  };

  constructor (props) {
    super(props);
    this.buildPaymentMethods = this.renderPaymentMethods();
    this.validatePaymentMethods = validateFieldArray(validatePaymentMethod);
    this.handleAddedMethod = this.handleAddedMethod.bind(this);
  }
  handleAddedMethod (fields) {
    if (!document.querySelector('#dropin-container')) {
      fields.push({});
      this.props.onPaymentInitialize();
    }
  }

  renderPaymentMethods () {
    const {readOnly} = this.props;
    const disabled = !!document.querySelector('#dropin-container');
    return ({fields, meta}) => {
      return (
        <ul className="o-list-bare">
          {fields.map((member, key) => (
            <li
                className="u-margin-bottom"
                key={member}
            >
              <PaymentMethodFieldItem
                  handleDeletePayment={this.props.handleDeletePayment}
                  index={key}
                  payments={this.props.payments}
                  tokenizeCard={this.props.tokenizeCard}
                  {...{fields, member, meta, readOnly}}
                  updatePayments={this.props.updatePayments}
                  openModal={this.props.openModal}
                  closeModal={this.props.closeModal}
              />
            </li>
          ))}
          <li>
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                disabled={disabled}
                label="+ Add a payment method"
                onClick={() => {
                  this.handleAddedMethod(fields);
                }}
                type="button"
            />
          </li>
          {meta.error && (<li className="c-error-text">{meta.error}</li>)}
        </ul>
      );
    };
  }

  render () {
    return (
      <FieldArray
          component={this.buildPaymentMethods}
          name="paymentMethods"
          warn={this.validatePaymentMethods}
      />
    );
  }
}

export class PaymentMethodFieldItem extends FormListItem {
  constructor (props) {
    super(props);
    const field = props.fields.get(`${props.index}`);
    if (props.readOnly && Object.keys(field).length) {
      setTimeout(() => {
        this.setState({ // eslint-disable-line react/no-set-state
          isOpen: false,
          isNew: false
        });
      });
    }
  }

  renderUpdate (fields, member, key/* , meta */) {
    const field = fields.get(`${key}`);
    const {
      cardType,
      cardNumber,
      isDefault,
      id
    } = field;
    const lastFour = cardNumber;
    const cardLabel = cardType;
    const isPrimary = isDefault ? 'Primary' : null;
    return (
      <div>
        <span className="u-margin-right-small">{cardLabel}</span><span>{`**** ${lastFour}`}</span><span className="c-form-button--float"> {isPrimary}</span>
        {(!isPrimary ? <button className="c-form-button--no-border" onClick={() => this.props.updatePayments(id)}>Set as Primary</button> : null)}
        <div className="o-layout">
          <div className="o-layout__item u-1/2@tablet">
            <FormButton
                className="c-form-button--destructive c-form-button--block"
                label="Remove"
                onClick={() => {
                  this.props.openModal(() => (
                    <div>
                      <p className="u-center-text">{`Are you sure you want to remove the card ending with ${lastFour} ?`}</p>
                      <div className="o-layout__item u-1/2@tablet">
                        <FormButton
                            className="c-form-button--primary c-form-button--inverse c-form-button--block"
                            label="Cancel"
                            onClick={() => this.props.closeModal()}
                            type="button"
                        />
                      </div>
                      <div className="o-layout__item u-1/2@tablet">
                        <FormButton
                            className="c-form-button--destructive c-form-button--block"
                            label="Remove"
                            onClick={() => this.props.handleDeletePayment(id, action => {
                              if (action.type === PAYMENT_SUCCESS) {
                                this.props.closeModal();
                              }
                            })}
                            type="button"
                        />
                      </div>
                    </div>
                  ), '', {
                  }, {
                    'c-discount-code-modal': true
                  });
                }}
                type="button"
            />
          </div>
          <div className="o-layout__item u-1/2@tablet">
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="Done"
                onClick={() => {
                  this.setState({isOpen: false}); // eslint-disable-line react/no-set-state
                }}
                type="button"
            />
          </div>
        </div>
      </div>
    );
  }

  renderRead (fields, member, key/* , meta */) {
    const {
      cardNumber,
      cardType,
      isDefault
    } = fields.get(`${key}`);
    const cardLabel = cardType;
    const lastFour = cardNumber;
    const isPrimary = isDefault ? 'Primary' : null;
    return (
      <div
          className="c-widget-item__read-item"
          onClick={() => {
            this.setState({isOpen: true}); // eslint-disable-line react/no-set-state
          }}
      >
        <span className="u-margin-right-small">{cardLabel}</span><span>{`**** ${lastFour}`}</span><span className="c-form-button--float"> {isPrimary}</span>
      </div>
    );
  }

  renderCreate (fields, member, key) {
    return (
      <div>
        <div id="dropin-container"></div>
        <div className="o-layout">
          <div className="o-layout__item u-1/2@tablet">
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="Cancel"
                onClick={() => fields.remove(key)}
                type="button"
            />
          </div>
          <div className="o-layout__item u-1/2@tablet">
            <button
                className="c-form-button c-form-button--primary c-form-button--block"
                id="submit-button"
                onClick={() => {
                  this.props.tokenizeCard(true, action => {
                    if (action.type === PAYMENT_SUBMIT_SUCCESS) {
                      this.setState({isOpen: false, isNew: false});
                    }
                  });
                }}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }
}
