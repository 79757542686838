import {
  SET_AUTH_TOKEN,
  HYDRO,
  SET_CURRENT_PAGE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILURE
} from 'constants/session';
import {SECRET_KEY} from 'constants/settings';
import {user as userSchema} from 'schemas/user';
import {CALL_API} from 'middleware/api';

const scope = process.env.APP_ROLE === 'sitter' ? 'sitter' : 'parent';

export function setAuthToken (authToken) {
  return {
    type: SET_AUTH_TOKEN,
    authToken
  };
}

export function hydro (authToken) {
  return {
    type: HYDRO,
    authToken
  };
}

export function setCurrentPage (currentPage) {
  return {
    type: SET_CURRENT_PAGE,
    currentPage
  };
}

export function resetPassword (email, resolve, reject) {
  return {
    [CALL_API]: {
      endpoint: `${scope}/password`,
      data: {
        email
      },
      headers: {'X-Identity-Key': SECRET_KEY},
      method: 'POST',
      schema: userSchema,
      resolve,
      reject,
      types: [
        PASSWORD_RESET_REQUEST,
        PASSWORD_RESET_SUCCESS,
        PASSWORD_RESET_FAILURE
      ]
    }
  };
}

export function changePassword (token, password, resolve, reject) {
  return {
    [CALL_API]: {
      endpoint: `${scope}/password`,
      data: {
        token,
        password
      },
      headers: {'X-Identity-Key': SECRET_KEY},
      method: 'PUT',
      schema: userSchema,
      resolve,
      reject,
      types: [
        PASSWORD_CHANGE_REQUEST,
        PASSWORD_CHANGE_SUCCESS,
        PASSWORD_CHANGE_FAILURE
      ]
    }
  };
}
