import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_REQUEST
} from 'constants/login';
import {
  LOAD_FAVORITES_FAILURE,
  LOAD_FAVORITES_SUCCESS,
  LOAD_FAVORITES_REQUEST,
  PROVIDER_SCHEDULE_FAILURE,
  PROVIDER_SCHEDULE_REQUEST,
  PROVIDER_SCHEDULE_SUCCESS,
  PROFILE_SITTER_REVIEWS_FAILURE,
  PROFILE_SITTER_REVIEWS_REQUEST,
  PROFILE_SITTER_REVIEWS_SUCCESS,
  PROFILE_SITTER_REFERENCES_FAILURE,
  PROFILE_SITTER_REFERENCES_REQUEST,
  PROFILE_SITTER_REFERENCES_SUCCESS,
} from 'constants/parent/profile';
import {
  PROFILE_FAILURE,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_OTHER_FAILURE,
  PROFILE_OTHER_REQUEST,
  PROFILE_OTHER_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE
} from 'constants/profile';
import {
  SEARCH_FAILURE,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  CLOSE_SEARCH_REQUEST,
  CLOSE_SEARCH_SUCCESS,
  CLOSE_SEARCH_FAILURE,
  OPEN_SEARCH_REQUEST,
  OPEN_SEARCH_SUCCESS,
  OPEN_SEARCH_FAILURE
} from 'constants/parent/search';
import {
  BOOKING_FAILURE,
  BOOKING_REQUEST,
  BOOKING_SUCCESS,
  UNREVIEWED_BOOKING_LISTING_REQUEST,
  UNREVIEWED_BOOKING_LISTING_SUCCESS,
  UNREVIEWED_BOOKING_LISTING_FAILURE,
  BOOKING_DETAILS_FAILURE,
  BOOKING_DETAILS_REQUEST,
  BOOKING_DETAILS_SUCCESS,
  BOOKING_PRICE_REQUEST,
  BOOKING_PRICE_SUCCESS,
  BOOKING_PRICE_FAILURE
} from 'constants/parent/booking';
import {
  CONSTANTS_REQUEST,
  CONSTANTS_SUCCESS,
  CONSTANTS_FAILURE,
  LOAD_NOTIFICATION_SETTINGS_REQUEST,
  LOAD_NOTIFICATION_SETTINGS_SUCCESS,
  LOAD_NOTIFICATION_SETTINGS_FAILURE,
  SAVE_NOTIFICATION_SETTINGS_REQUEST,
  SAVE_NOTIFICATION_SETTINGS_SUCCESS,
  SAVE_NOTIFICATION_SETTINGS_FAILURE
} from 'constants/settings';
import {
  CREATE_CHILD_PROFILE_FAILURE,
  CREATE_CHILD_PROFILE_REQUEST,
  CREATE_CHILD_PROFILE_SUCCESS,
  PAYMENT_SUBMIT_FAILURE,
  PAYMENT_SUBMIT_REQUEST,
  PAYMENT_SUBMIT_SUCCESS,
  REGISTRATION_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  SET_HOME_FAILURE,
  SET_HOME_REQUEST,
  SET_HOME_SUCCESS,
  UPDATE_HOME_REQUEST,
  UPDATE_HOME_SUCCESS,
  UPDATE_HOME_FAILURE,
  ADD_CHILD_PROFILE_REQUEST,
  ADD_CHILD_PROFILE_SUCCESS,
  ADD_CHILD_PROFILE_FAILURE,
  PAYMENT_CHANGE_REQUEST,
  PAYMENT_CHANGE_SUCCESS,
  PAYMENT_CHANGE_FAILURE,
  SUBMIT_PROFILE_FAILURE,
  SUBMIT_PROFILE_REQUEST,
  SUBMIT_PROFILE_SUCCESS
} from 'constants/parent/signUpFlow';
import {
  STREAM_TOKEN_REQUEST,
  STREAM_TOKEN_SUCCESS,
  STREAM_TOKEN_FAILURE
} from 'constants/stream';

export function getDefaultState () {
  return {
    paymentChange: false,
    addChildProfile: false,
    updateHome: false,
    constants: false,
    bookingRequest: false,
    braintree: false,
    createChildProfile: false,
    login: false,
    paymentSubmit: false,
    registration: false,
    search: false,
    setHome: false,
    submitProfile: false,
    unreviewedBookings: false,
    openSearchRequest: false,
    closeSearchRequest: false,
    favorites: false,
    priceEstimate: false
  };
}

const loadingTypes = {
  profile: {
    [PROFILE_REQUEST]: true,
    [PROFILE_SUCCESS]: false,
    [PROFILE_FAILURE]: false
  },
  profileOther: {
    [PROFILE_OTHER_REQUEST]: true,
    [PROFILE_OTHER_SUCCESS]: false,
    [PROFILE_OTHER_FAILURE]: false
  },
  profileUpdate: {
    [PROFILE_UPDATE_REQUEST]: true,
    [PROFILE_UPDATE_SUCCESS]: false,
    [PROFILE_UPDATE_FAILURE]: false
  },
  priceEstimate: {
    [BOOKING_PRICE_REQUEST]: true,
    [BOOKING_PRICE_SUCCESS]: false,
    [BOOKING_PRICE_FAILURE]: false
  },
  closeSearchRequest: {
    [CLOSE_SEARCH_REQUEST]: true,
    [CLOSE_SEARCH_SUCCESS]: false,
    [CLOSE_SEARCH_FAILURE]: false
  },
  openSearchRequest: {
    [OPEN_SEARCH_REQUEST]: true,
    [OPEN_SEARCH_SUCCESS]: false,
    [OPEN_SEARCH_FAILURE]: false
  },
  unreviewedBookings: {
    [UNREVIEWED_BOOKING_LISTING_REQUEST]: true,
    [UNREVIEWED_BOOKING_LISTING_SUCCESS]: false,
    [UNREVIEWED_BOOKING_LISTING_FAILURE]: false
  },
  bookingDetails: {
    [BOOKING_DETAILS_REQUEST]: true,
    [BOOKING_DETAILS_SUCCESS]: false,
    [BOOKING_DETAILS_FAILURE]: false
  },
  paymentChange: {
    [PAYMENT_CHANGE_REQUEST]: true,
    [PAYMENT_CHANGE_SUCCESS]: false,
    [PAYMENT_CHANGE_FAILURE]: false
  },
  addChildProfile: {
    [ADD_CHILD_PROFILE_REQUEST]: true,
    [ADD_CHILD_PROFILE_SUCCESS]: false,
    [ADD_CHILD_PROFILE_FAILURE]: false
  },
  updateHome: {
    [UPDATE_HOME_REQUEST]: true,
    [UPDATE_HOME_SUCCESS]: false,
    [UPDATE_HOME_FAILURE]: false
  },
  constants: {
    [CONSTANTS_REQUEST]: true,
    [CONSTANTS_SUCCESS]: false,
    [CONSTANTS_FAILURE]: false
  },
  loadNotifications: {
    [LOAD_NOTIFICATION_SETTINGS_REQUEST]: true,
    [LOAD_NOTIFICATION_SETTINGS_SUCCESS]: false,
    [LOAD_NOTIFICATION_SETTINGS_FAILURE]: false
  },
  saveNotifications: {
    [SAVE_NOTIFICATION_SETTINGS_REQUEST]: true,
    [SAVE_NOTIFICATION_SETTINGS_SUCCESS]: false,
    [SAVE_NOTIFICATION_SETTINGS_FAILURE]: false
  },
  bookingRequest: {
    [BOOKING_REQUEST]: true,
    [BOOKING_SUCCESS]: false,
    [BOOKING_FAILURE]: false
  },
  /* braintreeLoading: {
    [BRAINTREE_TOKEN_REQUEST]: true,
    [BRAINTREE_TOKEN_SUCCESS]: false,
    [BRAINTREE_TOKEN_FAILURE]: false
  }, */
  createChildProfile: {
    [CREATE_CHILD_PROFILE_REQUEST]: true,
    [CREATE_CHILD_PROFILE_SUCCESS]: false,
    [CREATE_CHILD_PROFILE_FAILURE]: false
  },
  paymentSubmit: {
    [PAYMENT_SUBMIT_REQUEST]: true,
    [PAYMENT_SUBMIT_SUCCESS]: false,
    [PAYMENT_SUBMIT_FAILURE]: false
  },
  registration: {
    [REGISTRATION_REQUEST]: true,
    [REGISTRATION_SUCCESS]: false,
    [REGISTRATION_FAILURE]: false
  },
  setHome: {
    [SET_HOME_REQUEST]: true,
    [SET_HOME_SUCCESS]: false,
    [SET_HOME_FAILURE]: false
  },
  submitProfile: {
    [SUBMIT_PROFILE_REQUEST]: true,
    [SUBMIT_PROFILE_SUCCESS]: false,
    [SUBMIT_PROFILE_FAILURE]: false
  },
  login: {
    [LOGIN_REQUEST]: true,
    [LOGIN_SUCCESS]: false,
    [LOGIN_FAILURE]: false
  },
  search: {
    [SEARCH_REQUEST]: true,
    [SEARCH_SUCCESS]: false,
    [SEARCH_FAILURE]: false
  },
  favorites: {
    [LOAD_FAVORITES_REQUEST]: true,
    [LOAD_FAVORITES_SUCCESS]: false,
    [LOAD_FAVORITES_FAILURE]: false
  },
  providerSchedule: {
    [PROVIDER_SCHEDULE_REQUEST]: true,
    [PROVIDER_SCHEDULE_FAILURE]: false,
    [PROVIDER_SCHEDULE_SUCCESS]: false
  },
  sitterReviews: {
    [PROFILE_SITTER_REVIEWS_REQUEST]: true,
    [PROFILE_SITTER_REVIEWS_SUCCESS]: false,
    [PROFILE_SITTER_REVIEWS_FAILURE]: false
  },
  sitterReferences: {
    [PROFILE_SITTER_REFERENCES_REQUEST]: true,
    [PROFILE_SITTER_REFERENCES_SUCCESS]: false,
    [PROFILE_SITTER_REFERENCES_FAILURE]: false
  },
  streamToken: {
    [STREAM_TOKEN_REQUEST]: true,
    [STREAM_TOKEN_SUCCESS]: false,
    [STREAM_TOKEN_FAILURE]: false
  }
};

export default function (state = getDefaultState(), action) {
  return {
    ...state,
    ...handleLoading(loadingTypes, action)
  };
}

export function handleLoading (types, action) {
  const loadingTypesKeys = Object.keys(types);
  for (let index = 0; index < loadingTypesKeys.length; index++) {
    const loadingPropName = loadingTypesKeys[index];
    const loadingType = types[loadingPropName];
    if (loadingType[action.type] !== undefined) {
      return {
        [loadingPropName]: loadingType[action.type]
      };
    }
  }
}
