import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// import * as SVGIcon from 'components/SVGIcon';

export default class ProviderSearch extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    toggleSearchCollapse: PropTypes.func.isRequired
  };

  render () {
    const {
      address,
      children,
      schedule: {
        endTime,
        startTime,
        targetDate,
        targetDateActive,
        timeRangeActive
      }
    } = this.props.values;
    return (
      <div
          className="o-layout c-provider-search c-provider-search--condensed"
          onClick={() => this.props.toggleSearchCollapse()}
      >
        <div className="o-layout__item u-1/1 c-provider-search__title">
          <h3 className="c-h3 u-center-text">{address.streetAddress}</h3>
        </div>
        <div className="o-layout__item u-1/1 c-provider-search__details">
          <div className="o-layout">
            <div className="o-layout__item u-1/3">{targetDateActive ? moment(targetDate).format('MMM D') : 'Any Date'}</div>
            <div className="o-layout__item u-1/3">{timeRangeActive ? `${moment(startTime).format('h:mma')} - ${moment(endTime).format('h:mma')}` : 'Any time'}</div>
            <div className="o-layout__item u-1/3">{`${children.length} ${children.length === 1 ? 'Child' : 'Children'}`}</div>
          </div>
        </div>
        <div className="o-layout__item u-1/1">
          <div className="c-provider-search__border" />
        </div>
      </div>
    );
  }
}
