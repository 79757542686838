import moment from 'moment';

import bookingScheduleService from 'services/bookingSchedule';
import scheduleLogic from 'services/bookingSchedule';
import {CONSTANTS_SUCCESS} from 'constants/settings';
import {
  // SET_END_TIME,
  // SET_START_TIME,
  // SET_TARGET_DATE,
  LOAD_SEARCH_STATE,
  SEARCH_SUCCESS,
  SET_CHILDREN_COUNT,
  SET_CONVERSATION_ID,
  SET_LOCATION,
  OPEN_SEARCH_SUCCESS,
  CLOSE_SEARCH_SUCCESS,
  LIST_OPEN_SEARCHES_SUCCESS
} from 'constants/parent/search';

export function getDefaultState () {
  const targetDate = moment().startOf('day');
  // const startTime = targetDate.clone().set({hours: 7, minutes: 0}); // refactor, hardcoded values
  // const endTime = startTime.clone().add(defaultBookingSettings.min_lead_time, 'minutes');
  const startTime = targetDate.clone(); // refactor, hardcoded values
  const endTime = startTime.clone().add(defaultBookingSettings.default_length, 'hours');
  const state = { // refactor, this is very wrong, but it's an anti-pattern to base them off props
    conversationId: null,
    searchForm: {
      childProfiles: [],
      pets: [],
      location: '{}',
      targetDate: targetDate.toISOString(),
      ...scheduleLogic.calculateTimeRange(normalizeScheduleSettings(defaultBookingSettings), moment().toISOString(), targetDate.toISOString(), startTime.toISOString(), endTime.toISOString(), undefined, undefined, true)
    },
    bookingSettings: defaultBookingSettings,
    childrenCount: 1,
    location: {
      city: undefined,
      lat: undefined,
      lng: undefined,
      state: undefined,
      streetAddress: undefined,
      zip: undefined
    },
    result: null,
    openSearches: []
    // targetDate: moment().startOf('day')
  };
  // const startTime = moment().startOf('minute').add(defaultBookingSettings.min_lead_time, 'hours'); // refactor, doesn't use the settings from the server
  // const endTime = startTime.clone().add(2, 'hours'); // refactor, hardcoded value
  return state;
}

export default function (state = getDefaultState(), action) {
  if (action.type === SET_CONVERSATION_ID) {
    return {
      ...state,
      conversationId: action.conversationId
    };
  }
  if (action.type === LIST_OPEN_SEARCHES_SUCCESS) {
    return {
      ...state,
      openSearches: action.response.result.searches.map(id => action.response.entities.search[id])
    };
  }
  if (action.type === CLOSE_SEARCH_SUCCESS) {
    return {
      ...state,
      result: action.response.entities.search[action.response.result]
    };
  }
  if (action.type === OPEN_SEARCH_SUCCESS) {
    return {
      ...state,
      result: action.response.entities.search[action.response.result]
    };
  }
  if (action.type === CONSTANTS_SUCCESS) {
    /*
    const {
      endTime,
      startTime,
      // targetDate
    } = state;
    */
    const bookingSettings = action.response.entities.settings[action.response.entities.result].constants.booking;
    return {
      ...state,
      // ...bookingScheduleService.generateTimeRange(state.bookingSettings, targetDate, startTime, endTime),
      bookingSettings
    };
  }
  if (action.type === LOAD_SEARCH_STATE) {
    const {query} = action;
    const propsIncomplete = !'streetAddress city state zip lat lng'.split(' ').reduce((p, c) => {
      return (p && !!query[c]);
    }, true);
    if (propsIncomplete) { // refactor, redux-form should be taking care of this check
      const location = {
        city: query.city,
        lat: query.lat,
        lng: query.lng,
        state: query.state,
        streetAddress: query.streetAddress,
        zip: query.zip
      };
      // const targetDate = moment(query.startTime).startOf('day');
      return {
        ...state,
        childrenCount: parseInt(query.childrenCount || state.childrenCount, 10), // refactor, needs to be a better way to handle this
        location,
        result: null,
        // targetDate,
        // ...bookingScheduleService.generateTimeRange(state.bookingSettings, targetDate, query.startTime, query.endTime)
      };
    } else { // refactor, is this just a duplicate of the block above?
      const startTime = query.startTime || moment().toISOString();
      const endTime = query.endTime || moment().toISOString();
      const targetDate = moment(query.targetDate).startOf('day');
      const location = JSON.stringify({
        city: query.city,
        lat: query.lat,
        lng: query.lng,
        state: query.state,
        streetAddress: query.streetAddress,
        zip: query.zip
      });
      const searchForm = {
        childProfiles: query.childProfiles.split(',') || state.childProfiles,
        location,
        pets: (!!query.pets && query.pets.split(',')) || state.pets,
        targetDate,
        ...bookingScheduleService.calculateTimeRange(normalizeScheduleSettings(state.bookingSettings), moment().toISOString(), targetDate.toISOString(), startTime, endTime, undefined, undefined, true)
      };
      searchForm.childrenCount = searchForm.childProfiles.length;
      const newSearchForm = {
        ...state.searchForm,
        ...searchForm
      };
      const newState = {
        ...state,
        ...{searchForm: newSearchForm}
      };
      return newState;
    }
  }
  if (action.type === SET_LOCATION) {
    const {location} = action;
    return {
      ...state,
      location
    };
  }
  if (action.type === SET_CHILDREN_COUNT) {
    const {childrenCount} = action;
    return {
      ...state,
      childrenCount
    };
  }
  if (action.type === SEARCH_SUCCESS) {
    return {
      ...state,
      result: action.response.entities.search[action.response.result]
    };
  }
  /*
  if (action.type === SET_TARGET_DATE) {
    const {targetDate} = action;
    const {startTime, endTime} = state;
    return {
      ...state,
      targetDate,
      ...bookingScheduleService.generateTimeRange(state.bookingSettings, targetDate, startTime, endTime)
    };
  }
  if (action.type === SET_START_TIME) {
    const {startTime} = action;
    const {endTime, targetDate} = state;
    return {
      ...state,
      ...bookingScheduleService.generateTimeRange(state.bookingSettings, targetDate, startTime, endTime)
    };
  }
  if (action.type === SET_END_TIME) {
    const {endTime} = action;
    const {startTime, targetDate} = state;
    return {
      ...state,
      ...bookingScheduleService.generateTimeRange(state.bookingSettings, targetDate, startTime, endTime)
    };
  }
  */
  return state;
}

export function normalizeScheduleSettings (bookingSettings) {
  const { /* eslint-disable camelcase */
    day_length,
    day_start_time,
    default_length,
    end_time,
    max_length,
    min_lead_time,
    min_length,
    start_time,
    time_increment,
  } = bookingSettings;
  return {
    dayLength: day_length,
    dayStartTime: day_start_time,
    default_length: default_length,
    endTime: end_time,
    maxDuration: max_length * 60,
    minDuration: min_length * 60,
    minLeadTime: (min_lead_time || 2) * 60, // refactor, hardcoded
    startTime: start_time,
    timeIncrement: time_increment
  }; /* eslint-enable camelcase */
}

export const defaultBookingSettings = { /* eslint-disable camelcase */ // refactor, fix this
  afterhours_padding: 3, // refactor, use `settings` prop
  contact_info_lead_time: 20,
  day_length: 36,
  day_start_time: '0000',
  default_length: 2,
  default_start_time: '0700',
  end_time: '0130',
  ended_statuses: ['ended'],
  max_children: 3,
  max_days_ahead: 30,
  max_length: 12,
  min_lead_time: 2.5,
  min_length: 2,
  start_afterhours: '2200',
  start_time: '0700',
  time_increment: 15,
  time_to_cancel: 90 /* eslint-enable camelcase */
};
