// import 'react-redux-modal/lib/css/react-redux-modal.css';
import 'react-buddy-modal/dist/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-widgets/lib/less/react-widgets.less';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// import ReduxModal from 'react-redux-modal';
import momentLocalizer from 'react-widgets-moment';
import numberLocalizer from 'react-widgets-simple-number';
import {connect, Provider} from 'react-redux';
import {Router, browserHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';

import 'stylesheets/parent.scss';
import * as localStore from 'utils/localStorage';
import {configureStore} from 'stores/parent/configureStore';
import routes from 'routes/parent';
import {hydro as hydroAction} from 'actions/session';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

var bugsnagClient = bugsnag({
  apiKey: process.env.BUGSNAG_API_KEY,
  releaseStage: process.env.NODE_ENV
});
bugsnagClient.use(bugsnagReact, React);

const store = configureStore({});
const history = syncHistoryWithStore(browserHistory, store);

momentLocalizer(moment);
numberLocalizer();

var ErrorBoundary = bugsnagClient.getPlugin('react');

class WrapperComponent extends React.Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    hydro: PropTypes.func.isRequired
  };
  componentWillMount () {
    if (process.env.BROWSER) {
      const authToken = localStore.get('TRUSTED_AUTH_TOKEN_' + process.env.APP_ROLE);
      this.props.hydro(authToken);
    }
  }
  render () {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}
const Wrapper = connect(() => {
  return {
  };
}, {
  hydro: hydroAction
})(WrapperComponent);

ReactDOM.render(
  <ErrorBoundary>
    <Provider {...{store}}>
      <Wrapper>
        <Router {...{history}}>
          {routes(store)}
        </Router>
      </Wrapper>
    </Provider>
  </ErrorBoundary>
  ,document.getElementById('js-app')
);
