import {routerReducer} from 'react-router-redux';
import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import bookingsReducer from 'reducers/parent/bookings';
import hudReducer from 'reducers/hud';
import loadingReducer from 'reducers/parent/loading';
import modalReducer from 'reducers/modal';
import navigationReducer from 'reducers/navigation';
import profileReducer from 'reducers/parent/profile';
import searchReducer from 'reducers/parent/search';
import sessionReducer from 'reducers/session';
import sessionReducerEnhancer from 'reducers/parent/session';
import settingsReducer from 'reducers/settings';
import uiReducer from 'reducers/parent/ui';

export const reducerList = {
  ui: uiReducer,
  bookings: bookingsReducer,
  form: formReducer,
  hud: hudReducer,
  loading: loadingReducer,
  modal: modalReducer,
  navigation: navigationReducer,
  profile: profileReducer,
  routing: routerReducer,
  search: searchReducer,
  session: sessionReducerEnhancer(sessionReducer),
  settings: settingsReducer
};

const rootReducer = combineReducers(reducerList);

export default rootReducer;
