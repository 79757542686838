import React from 'react';
import PropTypes from 'prop-types';

export default class FormListItem extends React.Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    readOnly: PropTypes.bool,
    member: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      warning: PropTypes.array
    })
  };

  state = {
    isNew: true,
    isOpen: true
  };

  constructor (props) {
    super(props);
    const field = props.fields.get(`${props.index}`);
    if (Object.keys(field).length >= 3) { // refactor, not a good way to check
      this.state = {
        isNew: false,
        isOpen: false
      };
    }
    if (props.readyOnly && Object.keys(field).length) {
      this.state = {
        isNew: false,
        isOpen: false
      };
    }
  }

  componentWillReceiveProps (nextProps) { // refactor, is this really the best way? (using warnings, i mean)
    if (nextProps.meta.warning && nextProps.meta.warning[nextProps.index] && !nextProps.readOnly) {
      this.setState({isNew: true, isOpen: true}); // eslint-disable-line react/no-set-state
    }
  }

  render () {
    const {
      fields,
      index,
      member,
      meta
    } = this.props;
    let content;
    if (this.state.isOpen) {
      if (this.state.isNew) {
        content = this.renderCreate(fields, member, index, meta);
      } else {
        content = this.renderUpdate(fields, member, index, meta);
      }
    } else {
      content = this.renderRead(fields, member, index, meta);
    }
    return (
      <div className="c-widget-item o-box">{content}</div>
    );
  }
}
