import React from 'react';
import {connect} from 'react-redux';
import videojs from 'video.js';
import 'videojs-contrib-dash';
import 'dash.js/dist/dash.all.min.js';
import jQuery from 'jquery';

export class VideoFeed extends React.Component {
  componentDidMount () {
    const {sitter} = this.props;
    this.player = videojs(this.videoEl, {});
    this.player.ready(() => {
      this.player.src({
        src: this.m3u8ToMpd(sitter.broadcast_url),
        type: 'application/dash+xml'
      });
      setTimeout(() => {
        jQuery('.c-video-player video').get(0).play();
      }, 1000);
    });
  }

  componentWillUnmount () {
    this.player.dispose();
  }

  m3u8ToMpd (uri) {
    return uri.split(/wowza-secure.usetrusted.com/).join('52.8.160.87:1935').split(/playlist\.m3u8/).join('manifest.mpd');
  }

  shouldComponentUpdate () {
    return false;
  }

  render () {
    return (
      <div>
        <video
            className="c-video-player video-js"
            controls
            preload="auto"
            ref={el => this.videoEl = el}
        />
      </div>
    );
  }
}

function mapStateToProps () {
  return {
  };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoFeed);
