import React from 'react';

import FormField from 'forms/controls/FormField';

export default class ReasonFeedback extends React.Component {
  render () {
    return (
      <div>
        <FormField
            fields={[
              {
                name: 'feedback',
                placeholder: 'Reason',
                type: 'text'
              }
            ]}
            icon="Notes"
        />
      </div>
    );
  }
}
