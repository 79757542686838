import {browserHistory} from 'react-router';
import {
  call,
  fork,
  put,
  select,
  take
} from 'redux-saga/effects';

import {HOME_PAGE} from 'constants/parent/navigation';
import {HYDRO} from 'constants/session';
import {LOGIN_SUCCESS} from 'constants/login';

export function *loginSuccess () {
  while (true) { // eslint-disable-line no-constant-condition
    const {response} = yield take([LOGIN_SUCCESS]);
    const user = response.entities.user[response.result];
    yield fork(loginSuccessEffect, user.authentication_token, user);
  }
}

export function *loginSuccessEffect (authToken) {
  const state = yield select(s => s);
  const {
    routing: {
      locationBeforeTransitions
    }
  } = state;

  yield put({type: HYDRO, authToken});

  if (locationBeforeTransitions && locationBeforeTransitions.state && locationBeforeTransitions.state.nextPathname) {
    yield call(browserHistory.push, locationBeforeTransitions.state.nextPathname);
  } else {
    yield call(browserHistory.push, `/${HOME_PAGE}`);
  }
}
