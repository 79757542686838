import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import classNames from 'classnames';

import PortraitFrame from 'components/PortraitFrame';
import {toggleNav as toggleNavAction} from 'actions/navigation';
import {
  BOOKINGS_FUTURE_PAGE,
  BOOKINGS_PAST_PAGE,
  FAVORITES_PAGE,
  HOME_PAGE,
  PURCHASE_CREDITS_PAGE,
  IN_PROGRESS_BOOKING_PAGE,
  LOGOUT_PAGE,
  LOGIN_PAGE,
  OPEN_SEARCHES_PAGE,
  REFERRAL_PAGE,
  SETTINGS_PAGE,
  MESSAGES_PAGE
} from 'constants/parent/navigation';

export class Menu extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    constants: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleNav: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  constructor (props) {
    super(props);
    this.handleClick = this.onClick();
    this.renderParent = this.renderParent.bind(this);
  }

  onClick () {
    return () => {
      this.props.toggleNav(false);
    };
  }

  renderParent (canNavigate, canPurchaseCredits, user, isAuthenticated) {
    return (
      <>
        {canNavigate && user.in_progress_booking && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${IN_PROGRESS_BOOKING_PAGE}/${user.in_progress_booking}`}
            >{'In-Progress Booking'}</Link>
          </li>
        )}
        {canNavigate && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${HOME_PAGE}`}
            >{'Search'}</Link>
          </li>
        )}
        {canNavigate && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${OPEN_SEARCHES_PAGE}`}
            >{'Open Searches'}</Link>
          </li>
        )}
        {canNavigate && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${MESSAGES_PAGE}`}
            >{'Messages'}</Link>
          </li>
        )}
        {canNavigate && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${BOOKINGS_FUTURE_PAGE}`}
            >{'Upcoming bookings'}</Link>
          </li>
        )}
        {canNavigate && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${BOOKINGS_PAST_PAGE}`}
            >{'Past bookings'}</Link>
          </li>
        )}
        {canNavigate && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${FAVORITES_PAGE}`}
            >{'Favorites'}</Link>
          </li>
        )}
        {canNavigate && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${SETTINGS_PAGE}`}
            >{'Settings'}</Link>
          </li>
        )}
        {canNavigate && canPurchaseCredits && (
          <li>
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${PURCHASE_CREDITS_PAGE}`}
            >{'Purchase Credits'}</Link>
          </li>
        )}
        {canNavigate && (
          <li className="c-burger-nav__referral">
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${REFERRAL_PAGE}`}
            >{`Give $${user.default_referral_code.amount}, Get $${user.default_referral_code.amount}`}</Link>
          </li>
        )}
        {isAuthenticated && (
          <li className="c-burger-nav__sign-out">
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${LOGOUT_PAGE}`}
            >{'Sign out'}</Link>
          </li>
        )}
        {!isAuthenticated && (
          <li className="c-burger-nav__sign-out">
            <Link
                className="c-burger-nav__menu-item c-nav-item c-nav-item__primary"
                to={`/${LOGIN_PAGE}`}
            >{'Log in'}</Link>
          </li>
        )}
      </>
    );
  }

  render () {
    const {
      isAuthenticated,
      constants,
      isOpen,
      user
    } = this.props;
    const className = classNames({
      'c-burger-menu': true,
      'c-burger-menu--hidden': !isOpen
    });
    let name = '';
    let email = '';
    if (user) {
      name = `${user.first_name} ${user.last_name}`;
      email = user.email;
    }
    let canNavigate = false;
    if (!!user && !!user.status) {
      canNavigate = (
        user.status.allow_access &&
        user.status.has_phone_number &&
        user.status.has_child_profile &&
        // user.status.has_payment_method &&
        user.status.has_address
      );
    }
    let canPurchaseCredits = false;
    if (constants && constants.features) {
      canPurchaseCredits = constants.features.credit_purchase;
    }
    return (
      <div {...{className}}>
        <div className="c-burger-menu__wrap">
          <div className="c-burger-menu__content">
            <div className="o-wrapper">
              <div className="o-layout">
                <div className="o-layout__item u-1/2@tablet c-burger-nav__main">
                  <div className="c-burger-nav__wrap">
                    <PortraitFrame
                        classes={['c-portrait-frame--profile c-burger-nav__portrait']}
                        src={user && user.photo_url}
                    />
                    <h1 className="c-burger-nav__user-name c-h2 c-h2--inverse">{name}</h1>
                    <h4 className="c-burger-nav__user-email">{email}</h4>
                    <ul className="o-list-bare c-burger-nav__menu">
                      { this.renderParent(canNavigate, canPurchaseCredits, user, isAuthenticated) }
                    </ul>
                  </div>
                </div>
                <div className="o-layout__item u-1/2@tablet">
                  <div className="c-burger-nav__nav-section">
                    <div className="o-layout">
                      <div className="o-layout__item u-1/2@tablet">
                        <h6 className="c-burger-nav__title">{'Trusted'}</h6>
                        <ul className="o-list-bare c-burger-nav__site-map c-burger-nav__nav-list">
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/"
                                target="_blank"
                            >{'Home'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/about.html"
                                target="_blank"
                            >{'About'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://blog.usetrusted.com/"
                                target="_blank"
                            >{'Blog'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/faqs.html"
                                target="_blank"
                            >{'FAQs'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/gift-cards.html"
                                target="_blank"
                            >{'Gift Cards'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/trusted-at-work-corporate-benefits.html"
                                target="_blank"
                            >{'At Work'}</a>
                          </li>
                        </ul>
                      </div>
                      <div className="o-layout__item u-1/2@tablet">
                        <h6 className="c-burger-nav__title">{'More'}</h6>
                        <ul className="o-list-bare c-burger-nav__nav-list">
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/privacy"
                                target="_blank"
                            >{'Privacy Policy'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/terms"
                                target="_blank"
                            >{'Terms of Service'}</a>
                          </li>
                          <li>
                            <a
                                className="c-nav-item c-nav-item__alternate"
                                href="https://usetrusted.com/contact"
                                target="_blank"
                            >{'Contact Us'}</a>
                          </li>
                          <li className="c-burger-nav__copyright">{'© 2016 Trusted Labs, Inc.'}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const {
    navigation: {
      isOpen
    },
    session: {
      user,
      isAuthenticated
    },
    settings: {
      constants
    }
  } = state;
  return {
    isAuthenticated,
    isOpen,
    user,
    constants
  };
}

const mapDispatchToProps = {
  toggleNav: toggleNavAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
