import React from 'react';
import Geosuggest from 'react-geosuggest';

import FormFieldPartial from 'components/partials/FormField';

export default class AddressControl extends React.Component {
  getCity (lookup) {
    const sources = [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
      'sublocality_level_5',
      'neighborhood'
    ];
    let source;
    for (let i = 0; i < sources.length; i++) {
      source = sources[i];
      if (lookup[source] && lookup[source].long_name) {
        break;
      }
    }
    return lookup[source].long_name;
  }

  render () {
    const {
      input: {
        onChange,
        value
      },
      meta: {
        error
      }
    } = this.props;
    const initialValue = [value.city, value.state].reduce((p, c) => {
      return ((p !== undefined) && c) ? `${p}, ${c}` : undefined;
    }, value.streetAddress);
    return (
      <FormFieldPartial
          icon="Pin"
          {...{error}}
      >
        <Geosuggest
            className="c-address-field"
            country="us"
            getSuggestLabel={suggestion => formatLocationLabel(suggestion.description)}
            inputClassName="c-address-field__input"
            onChange={inputValue => {
              if (inputValue === '') {
                onChange({});
              }
            }}
            onSuggestSelect={suggestion => {
              if (!suggestion || !suggestion.gmaps) {
                onChange({});
                return;
              }
              let addressOutput = {};
              try {
                const lookup = buildLookup(suggestion.gmaps.address_components);
                const city = this.getCity(lookup);
                const state = lookup.administrative_area_level_1.short_name;
                const zip = lookup.postal_code.short_name;
                const streetNumber = lookup.street_number.short_name;
                const route = lookup.route.long_name;
                const streetAddress = `${streetNumber} ${route}`;
                const {lat, lng} = suggestion.location;
                addressOutput = {streetAddress, city, state, zip, lat, lng};
              } catch (e) {
                // refactor, handle this error?  a validation already handles feedback
              }
              onChange(addressOutput);
            }}
            placeholder="Where do you need care?"
            {...{initialValue}}
        />
      </FormFieldPartial>
    );
  }
}

export function formatLocationLabel (label) {
  return label.replace(', United States', '');
}

export function buildLookup (addressComponents) { /* eslint-disable camelcase */
  const lookup = {};
  addressComponents.forEach(({short_name, long_name, types}) => { // eslint-disable-line camelcase
    types.forEach(type => {
      lookup[type] = {
        long_name,
        short_name
      };
    });
  });
  return lookup;
}
