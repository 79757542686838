import {CALL_API} from 'middleware/api';
import {reviews as reviewsSchema} from 'schemas/review';
import {references as referencesSchema} from 'schemas/reference';
import {SECRET_KEY} from 'constants/settings';
import {
  sitter as sitterSchema,
  sitters as sittersSchema
} from 'schemas/sitter';
import {
  availability as availabilitySchema
} from 'schemas/availability';
import {
  PROVIDER_SCHEDULE_REQUEST,
  PROVIDER_SCHEDULE_SUCCESS,
  PROVIDER_SCHEDULE_FAILURE,
  LOAD_FAVORITES_FAILURE,
  LOAD_FAVORITES_REQUEST,
  LOAD_FAVORITES_SUCCESS,
  PROFILE_SITTER_FAILURE,
  PROFILE_SITTER_REQUEST,
  PROFILE_SITTER_SUCCESS,
  PROFILE_SITTER_REVIEWS_FAILURE,
  PROFILE_SITTER_REVIEWS_REQUEST,
  PROFILE_SITTER_REVIEWS_SUCCESS,
  PROFILE_SITTER_REFERENCES_FAILURE,
  PROFILE_SITTER_REFERENCES_REQUEST,
  PROFILE_SITTER_REFERENCES_SUCCESS,
  PROFILE_SITTER_FAVORITE_REQUEST,
  PROFILE_SITTER_FAVORITE_SUCCESS,
  PROFILE_SITTER_FAVORITE_FAILURE,
  PROFILE_SITTER_UNFAVORITE_REQUEST,
  PROFILE_SITTER_UNFAVORITE_SUCCESS,
  PROFILE_SITTER_UNFAVORITE_FAILURE
} from 'constants/parent/profile';

export function favoriteSitter (authToken, sitter_id) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      endpoint: `parent/sitters/${sitter_id}/relationship`, // eslint-disable-line camelcase
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY
      },
      data: {
        relationship_name: 'favorite' // eslint-disable-line camelcase
      },
      method: 'POST',
      schema: sitterSchema,
      types: [
        PROFILE_SITTER_FAVORITE_REQUEST,
        PROFILE_SITTER_FAVORITE_SUCCESS,
        PROFILE_SITTER_FAVORITE_FAILURE
      ]
    }
  };
}

export function unfavoriteSitter (authToken, sitter_id) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      endpoint: `parent/sitters/${sitter_id}/relationship?relationship_name=favorite`, // eslint-disable-line camelcase
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY
      },
      method: 'DELETE',
      schema: sitterSchema,
      types: [
        PROFILE_SITTER_UNFAVORITE_REQUEST,
        PROFILE_SITTER_UNFAVORITE_SUCCESS,
        PROFILE_SITTER_UNFAVORITE_FAILURE
      ]
    }
  };
}

export function loadSchedule (authToken, id) {
  return {
    [CALL_API]: {
      endpoint: `parent/sitters/${id}/availabilities`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY
      },
      method: 'GET',
      schema: availabilitySchema,
      types: [
        PROVIDER_SCHEDULE_REQUEST,
        PROVIDER_SCHEDULE_SUCCESS,
        PROVIDER_SCHEDULE_FAILURE
      ]
    }
  };
}

export function loadFavorites (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'parent/sitters?scope=favorite',
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY
      },
      method: 'GET',
      schema: sittersSchema,
      types: [
        LOAD_FAVORITES_REQUEST,
        LOAD_FAVORITES_SUCCESS,
        LOAD_FAVORITES_FAILURE
      ]
    }
  };
}

export function loadSitterProfile (authToken, id) {
  const headers = {
    'X-Identity-Key': SECRET_KEY
  };
  if (authToken) {
    headers['X-Identity-Token'] = authToken;
  }
  return {
    [CALL_API]: {
      endpoint: `parent/sitters/${id}`,
      headers,
      method: 'GET',
      schema: sitterSchema,
      types: [
        PROFILE_SITTER_REQUEST,
        PROFILE_SITTER_SUCCESS,
        PROFILE_SITTER_FAILURE
      ]
    }
  };
}

export function loadSitterReferences (authToken, id) {
  return {
    [CALL_API]: {
      endpoint: `parent/sitters/${id}/references`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY
      },
      method: 'GET',
      schema: referencesSchema,
      types: [
        PROFILE_SITTER_REFERENCES_REQUEST,
        PROFILE_SITTER_REFERENCES_SUCCESS,
        PROFILE_SITTER_REFERENCES_FAILURE
      ]
    }
  };
}

export function loadSitterReviews (authToken, id, perPage, pageIndex) {
  const scope = authToken ? 'all' : 'reviews'
  return {
    [CALL_API]: {
      endpoint: `parent/sitters/${id}/reviews?scope=${scope}&page=${pageIndex}&per_page=${perPage}`,
      headers: {
        'X-Identity-Token': authToken,
        'X-Identity-Key': SECRET_KEY
      },
      method: 'GET',
      schema: reviewsSchema,
      types: [
        PROFILE_SITTER_REVIEWS_REQUEST,
        PROFILE_SITTER_REVIEWS_SUCCESS,
        PROFILE_SITTER_REVIEWS_FAILURE
      ]
    }
  };
}
