import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  FormSection,
  Fields
} from 'redux-form';
import moment from 'moment';

import TimeRangeControl from 'forms/controls/TimeRange';
import FormButton from 'forms/controls/FormButton';

export default class TimePicker extends React.Component {
  static propTypes = {
    timeBlock: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired
  };

  handleSubmit = values => {
    this.props.resolve(values.providerSearch.schedule);
  };

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item">
          <TimePickerForm
              initialValues={{
                providerSearch: {
                  schedule: {
                    endTime: this.props.timeBlock.end_time,
                    startTime: this.props.timeBlock.start_time,
                    targetDate: moment(this.props.timeBlock.start_time).startOf('day').toISOString()
                  }
                }
              }}
              onFormChange={this.props.onFormChange}
              onSubmit={this.handleSubmit}
              timeBlock={this.props.timeBlock}
          />
        </div>
      </div>
    );
  }
}

export class TimePickerFields extends React.Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    timeBlock: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired
  };

  render () {
    const min = this.props.timeBlock.start_time;
    const max = this.props.timeBlock.end_time;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormSection name="providerSearch">
          <FormSection name="schedule">
            <Fields
                component={TimeRangeControl}
                names={['startTime', 'endTime', 'targetDate']}
                onFormChange={this.props.onFormChange}
                {...{min, max}}
            />
          </FormSection>
        </FormSection>
        <FormButton
            className="c-form-button--primary c-form-button--block"
            label="Select"
            type="submit"
        />
      </form>
    );
  }
}

export const TimePickerForm = reduxForm({
  form: 'search' // refactor, should there be a validation for this?
})(TimePickerFields);
