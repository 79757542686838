import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {
  change as changeFormAction,
  stopSubmit as stopSubmitAction
} from 'redux-form'

import PricingBreakdown from 'components/PricingBreakdown'
import BookingConfirmationForm from 'forms/BookingConfirmation'
import FormButton from 'forms/controls/FormButton'
import {DISCOUNT_CODE_FAILURE} from 'constants/parent/booking'
import DiscountCodeForm from 'forms/DiscountCode'
import {loadSitterProfile as loadSitterProfileAction} from 'actions/parent/profile'
import {track} from 'utils/track'
import {
  closeModal as closeModalAction,
  openModal as openModalAction
} from 'actions/modal'
import {
  createBooking as createBookingAction,
  applyDiscount as applyDiscountAction,
  requestPriceQuote as requestPriceQuoteAction,
} from 'actions/parent/booking'
import {
  healthCheck as healthCheckAction,
} from 'actions/healthCheck'
import {loadSitterSearch as loadSitterSearchAction} from 'actions/parent/search'

export class BookingConfirmation extends React.Component { // refactor, this is currently being used as a component, switch it to containerisms
  static propTypes = {
    loadSitterSearch: PropTypes.func.isRequired,
    copy: PropTypes.object,
    constants: PropTypes.object,
    childProfiles: PropTypes.array.isRequired,
    estimate: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    applyDiscount: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    bookingRequest: PropTypes.bool.isRequired,
    createBooking: PropTypes.func.isRequired,
    maxChildren: PropTypes.number.isRequired,
    priceEstimate: PropTypes.number,
    query: PropTypes.object.isRequired,
    requestPriceQuote: PropTypes.func.isRequired,
    healthCheck: PropTypes.func.isRequired,
    searchId: PropTypes.string.isRequired,
    sitter: PropTypes.object.isRequired,
    sitterId: PropTypes.string.isRequired,
    user: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.childProfiles = this.props.query.childProfiles.split(',').sort().join(',')
  }

  componentDidMount () {
    track('Booking Confirmation Modal')
    if (this.props.user) {
      this.updatePrice(this.props)
    }
    this.props.loadSitterProfile(this.props.authToken, this.props.sitterId)
  }

  handleChange () {
    return values => {
      const newKey = values.childProfiles.slice(0).sort().join(',')
      if (this.childProfiles === newKey) {
        return
      }
      this.childProfiles = newKey
      this.updatePrice({
        ...this.props,
        query: {
          ...this.props.query,
          childProfiles: this.childProfiles
        }
      })
    }
  }

  updatePrice (props) {
    const {
      authToken,
      requestPriceQuote,
      sitterId,
      searchId,
      user
    } = props
    const timeRange = {
      startTime: props.query.startTime,
      endTime: props.query.endTime,
      date: props.query.targetDate
    }
    if (props.query.childProfiles.length) {
      requestPriceQuote(authToken, searchId, sitterId, user.default_payment_method.id, props.query.childProfiles.split(',').map(i => +i), timeRange)
    }
  }

  async createBookingAction (values) {
    const {query} = this.props
    const searchParams = {
      location: {
        zip: query.zip,
        city: query.city,
        lat: query.lat,
        lng: query.lng,
        state: query.state,
        streetAddress: query.streetAddress
      },
      childProfiles: values.childProfiles,
      targetDate: query.targetDate,
      endTime: query.endTime,
      startTime: query.startTime
    }
    values.pets.map(k => searchParams[k] = true)
    const action = await this.props.loadSitterSearch(this.props.authToken, searchParams)
    const searchId = action.response.entities.search[action.response.result].id
    track('Booking Confirmation Modal - Create Booking')
    const {
      authToken,
      createBooking,
      sitter
    } = this.props
    const hasCats = searchParams && searchParams.has_cats ? 'yes' : 'no' // eslint-disable-line camelcase
    const hasDogs = searchParams && searchParams.has_dogs ? 'yes' : 'no' // eslint-disable-line camelcase
    const hasOther = searchParams && searchParams.has_other ? 'yes' : 'no' // eslint-disable-line camelcase
    const household_details = { // eslint-disable-line camelcase
      'has_cats': hasCats, // eslint-disable-line quote-props
      'has_dogs': hasDogs, // eslint-disable-line quote-props
      'has_other': hasOther, // eslint-disable-line quote-props
    }
    const sitterId = sitter.id
    const paymentMethodId = values.creditCards
    const childProfileIds = values.childProfiles
    createBooking(authToken, searchId, sitterId, paymentMethodId, childProfileIds, values.notes, household_details)
  }

  checkHealth (values) {
    this.props.healthCheck(() => { this.createBookingAction(values) })
  }

  handleSubmit () { // refactor, handle pets and notes!
    return async values => { // refactor, this might be an anti-pattern, it prevents comparison for pure render ...but this isn't a prop ¯\_(ツ)_/¯
      if (this.props.constants.features.health_check) {
        this.checkHealth(values)
      } else {
        this.createBookingAction(values)
      }
    }
  }

  render () {
    if (!this.props.user) {
      return (
        <h1>{'Loading...'}</h1>
      )
    }
    const {
      // applyDiscount,
      constants,
      childProfiles,
      bookingRequest,
      maxChildren,
      priceEstimate,
      estimate,
      query,
      sitter,
      user
    } = this.props
    const pets = (query.pets || '').split(',')
    const petTypes = 'has_cats has_dogs has_other'.split(' ')
    const initialValues = {
      childProfiles: this.childProfiles.split(','),
      creditCards: `${user.default_payment_method.id}`,
      notes: '',
      pets: petTypes.filter(petType => pets.indexOf(petType) > -1)
    }
    return (
      <div className="o-layout c-booking-confirmation">
        <div className="o-layout__item u-1/2@tablet u-push-1/4@tablet">
          <BookingConfirmationForm
              hasDiscountCode={() => {
                this.props.openModal(DiscountCodeForm, 'Redeem Discount Code', {
                  onSubmit: ({discountCode}) => {
                    this.props.applyDiscount(this.props.authToken, discountCode.code).then(action => { // refactor, extract saga
                      if (action.type === DISCOUNT_CODE_FAILURE) {
                        let message = 'Invalid code'
                        if (action.error && action.error.message && action.error.message.split(' ')[0] === 'is_redeemed') {
                          message = 'This code has already been redeemed'
                        }
                        this.props.stopSubmit('discountCode', {discountCode: {code: message}})
                      } else {
                        // this.props.closeModal()
                        this.props.changeForm('discountCode', 'discountCode.code', '')
                        this.updatePrice(this.props)
                        this.props.openModal(() => ( // refactor, extract this.  I got so lazy here...
                          <div>
                            <p className="u-center-text">{'Your estimated cost for this booking has been updated!'}</p>
                            <FormButton
                                className="c-form-button--primary c-form-button--block c-form-button--inverse"
                                label="Done"
                                onClick={() => this.props.closeModal()}
                                type="button"
                            />
                          </div>
                        ), 'Discount code applied', {
                        }, {
                          'c-discount-code-modal': true
                        })
                      }
                    })
                  }
                }, {
                  'c-discount-code-modal': true
                })
              }}
              showPricingBreakdown={bookingEstimate => {
                this.props.openModal(PricingBreakdown, 'Pricing Breakdown', {
                  closeModal: this.props.closeModal,
                  estimate: bookingEstimate,
                  copy: this.props.copy
                }, {
                })
              }}
              {...{
                constants,
                childProfiles,
                bookingRequest,
                initialValues,
                priceEstimate,
                estimate,
                maxChildren,
                query,
                sitter,
                user,
                onChange: this.handleChange(),
                onSubmit: this.handleSubmit()
              }}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  const {
    location: {
      query
    },
    params: {
      id
    }
  } = ownProps
  const {
    settings: {
      copy,
      constants
    },
    bookings: {
      priceEstimate,
      estimate
    },
    loading: {
      bookingRequest
    },
    session: {
      authToken,
      user
    },
    profile: {
      sitter
    }
  } = state
  const maxChildren = 3 // refactor, extract this
  return {
    childProfiles: user.child_profiles || [],
    copy,
    constants,
    estimate,
    sitterId: id,
    searchId: query.searchId,
    authToken,
    query,
    bookingRequest,
    priceEstimate,
    maxChildren,
    user,
    sitter
  }
}

const mapDispatchToProps = {
  loadSitterSearch: loadSitterSearchAction,
  createBooking: createBookingAction,
  applyDiscount: applyDiscountAction,
  requestPriceQuote: requestPriceQuoteAction,
  healthCheck: healthCheckAction,
  stopSubmit: stopSubmitAction,
  changeForm: changeFormAction,
  loadSitterProfile: loadSitterProfileAction,
  openModal: openModalAction,
  closeModal: closeModalAction
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingConfirmation)
