import React from 'react';
import $ from 'jquery';
import moment from 'moment';

import 'fullcalendar';
import 'fullcalendar/dist/fullcalendar.css';

export default class Calendar extends React.Component {
  static propTypes = {
  };

  componentDidMount () {
    this.initResizeHandler();
    const {calendar} = this;
    $(calendar).fullCalendar({
      columnFormat: 'dd\nM/D',
      timeFormat: ' ',
      allDaySlot: false,
      header: false,
      defaultView: 'agendaWeek',
      timezone: 'local',
      eventSources: [this.eventSource()],
      eventClick: ((event, jsEvent, view) => this.props.onEventClick(calendar, event, view))
    });
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler);
    const {calendar} = this;
    $(calendar).fullCalendar('destroy');
  }

  componentDidUpdate () {
    const {calendar} = this;
    $(calendar).fullCalendar('refetchEvents');
  }

  initResizeHandler () {
    this.resizeHandler = () => {
      const {calendar} = this;
      if (!calendar) {
        return;
      }
      const targetHeight = $(window).height() - 50; // refactor, magic number
      $(calendar).fullCalendar('option', 'height', targetHeight);
    };
  }

  eventSource () {
    return {
      events: (start, end, timezone, callback) => {
        const events = this.props.events || [];
        callback(events);
      }
    };
  }

  onDayClick (calendar, date, view) {
    if (view.name === 'month') {
      $(calendar).fullCalendar('changeView', 'agendaWeek');
      $(calendar).fullCalendar('gotoDate', date);
      return;
    }
    if (date) {
      this.props.onDateClick(date);
    }
  }

  onEventClick (calendar, e) {
    this.props.onEventClick(e);
  }

  isPast () {
    const c = $(this.calendar);
    const date = c.fullCalendar('getDate');
    return date.startOf('week').diff(moment().startOf('week')) <= 0;
  }

  prev () {
    const c = $(this.calendar);
    c.fullCalendar('prev');
  }

  next () {
    const c = $(this.calendar);
    c.fullCalendar('next');
  }

  render () {
    return (
      <div
          className="c-calendar"
          ref={c => this.calendar = c}
      />
    );
  }
}
