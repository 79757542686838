import {
  CREATE_CHILD_PROFILE_SUCCESS,
  PAYMENT_SUBMIT_SUCCESS,
  SET_HOME_SUCCESS,
} from 'constants/parent/signUpFlow';

export default function (baseReducer) {
  return (state, action) => {
    const newState = baseReducer(state, action);
    if (action.type === PAYMENT_SUBMIT_SUCCESS) {
      const paymentMethod = action.response.entities.braintree[action.response.result];
      return {
        ...newState,
        user: {
          ...newState.user,
          default_payment_method: paymentMethod, // eslint-disable-line camelcase
          status: {
            ...newState.user.status,
            has_payment_method: true // eslint-disable-line camelcase
          }
        }
      };
    }
    if (action.type === SET_HOME_SUCCESS) {
      return {
        ...newState,
        user: {
          ...newState.user,
          default_location: action.response.entities.location[action.response.result], // eslint-disable-line camelcase
          status: {
            ...newState.user.status,
            has_address: true // eslint-disable-line camelcase
          }
        }
      };
    }
    if (action.type === CREATE_CHILD_PROFILE_SUCCESS) {
      const {child_profiles} = action.response.entities.user[action.response.result]; // eslint-disable-line camelcase
      return {
        ...newState,
        user: {
          ...newState.user,
          child_profiles: child_profiles, // eslint-disable-line camelcase
          status: {
            ...newState.user.status,
            has_child_profile: true // eslint-disable-line camelcase
          }
        }
      };
    }

    return newState;
  };
}
