import {LOGIN_SUCCESS} from 'constants/login';
import {LOG_OUT} from 'constants/logOut';
import {PROFILE_SUCCESS} from 'constants/profile';
import {
  REMOVE_AUTH_TOKEN,
  SET_AUTH_TOKEN
} from 'constants/session';
import {
  PROVIDER_COVERAGE_ZONES_SUCCESS
} from 'constants/sitter/availability';
import {
  LOAD_REVIEWS_SUCCESS,
  LOAD_REFERENCES_SUCCESS
} from 'constants/sitter/reviews';
import {
  SUBMIT_PROFILE_SUCCESS
} from 'constants/parent/signUpFlow';

export function getDefaultState () {
  return {
    authToken: null,
    isAuthenticated: false,
    user: null,
    coverageZones: []
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === LOAD_REFERENCES_SUCCESS) {
    return {
      ...state,
      references: action.response.result.references.map(id => action.response.entities.references[id])
    };
  }
  if (action.type === LOAD_REVIEWS_SUCCESS) {
    return {
      ...state,
      reviews: action.response.result.reviews.map(id => action.response.entities.reviews[id])
    };
  }
  if (action.type === PROFILE_SUCCESS || action.type === SUBMIT_PROFILE_SUCCESS) {
    const user = action.response.entities.user[action.response.result];
    return {
      ...state,
      user
    };
  }
  if (action.type === SET_AUTH_TOKEN) {
    const {authToken} = action;
    return {
      ...state,
      authToken,
      isAuthenticated: true
    };
  }
  if (action.type === REMOVE_AUTH_TOKEN) {
    return {
      ...state,
      authToken: null,
      isAuthenticated: false
    };
  }
  if (action.type === LOG_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    };
  }
  if (action.type === LOGIN_SUCCESS) {
    return {
      ...state,
      isAuthenticated: true
    };
  }
  if (action.type === PROVIDER_COVERAGE_ZONES_SUCCESS) {
    return {
      ...state,
      coverageZones: action.response.result.coverage_zones // .map(id => action.response.entities.coverage_zone[id])
    };
  }
  return state;
}
