import React from 'react';
import PropTypes from 'prop-types';

export default class CalendarNav extends React.Component {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired
  };

  render () {
    const {
      onNext,
      onPrev
    } = this.props;
    return (
      <ul className="o-list-inline c-calendar-nav">
        <li className="o-list-inline__item">
          <button
              className="c-calendar-nav__control c-calendar-nav__prev c-link"
              onClick={onPrev}
              type="button"
          >{'Previous'}</button>
        </li>
        <li className="o-list-inline__item">
          <button
              className="c-calendar-nav__control c-calendar-nav__next c-link u-margin-left"
              onClick={onNext}
              type="button"
          >{'Next'}</button>
        </li>
      </ul>
    );
  }
}
