import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import FormButton from 'forms/controls/FormButton';
import SearchFields from 'forms/fields/Search';
import PaymentSelect from 'forms/fields/PaymentSelect';
import FormField from 'forms/controls/FormField';
import {
  validateFields,
  validateSearch
} from 'utils/formValidations';

export class FavoriteBookingComponent extends React.Component {
  static propTypes = {
    min: PropTypes.string,
    max: PropTypes.string,
    priceEstimate: PropTypes.number.isRequired,
    childProfiles: PropTypes.array.isRequired,
    constants: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  render () {
    const {
      childProfiles,
      constants,
      onFormChange,
      handleSubmit,
      min,
      priceEstimate,
      user,
      max,
      error
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <FormSection name="providerSearch">
            <SearchFields
                noRemove
                targetDateActive
                timeRangeActive
                {...{childProfiles, constants, onFormChange, error, min, max}}
            />
            <h4 className="c-h4 c-booking-confirmation__header">{'Payment'}</h4>
            <PaymentSelect creditCards={[user.default_payment_method]} />
            <FormField
                fields={[
                  {
                    name: 'notes',
                    placeholder: 'Anything else we should be aware of?',
                    type: 'text'
                  }
                ]}
                icon="Notes"
            />
          </FormSection>
          <div className="u-center-text u-margin-bottom">
            <button
                className="c-link"
                onClick={() => {
                  this.props.hasDiscountCode();
                }}
                type="button"
            >{'Have a discount code?'}</button>
          </div>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                label="Book"
                type="submit"
            />
          </div>
          <p className="u-margin-top u-center-text c-text-strong">{`Estimated cost ${Number(priceEstimate).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}`}</p>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'search',
  enableReinitialize: true,
  validate: validateFields({
    providerSearch: validateSearch // refactor, make sure this includes everything
  })
})(FavoriteBookingComponent);
