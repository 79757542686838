import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  FormSection
} from 'redux-form';

import NotificationSettings from 'forms/fields/NotificationSettings';
import ChildrenListFields from 'forms/fields/ChildrenList';
import ContactFields from 'forms/fields/Contact';
import FormButton from 'forms/controls/FormButton';
import HomeAddressField from 'forms/fields/HomeAddress';
import PasswordConfirmFields from 'forms/fields/PasswordConfirm';
import PaymentMethodFields from 'forms/fields/PaymentMethods';
import {
  validateFields,
  validateContact,
  validateChildren,
  validatePaymentMethod,
  validateHomeAddress,
  validatePasswordChange,
  validateFieldArray
} from 'utils/formValidations';

export class SettingsComponent extends React.Component {
  static propTypes = {
    settings: PropTypes.array.isRequired,
    onPaymentInitialize: PropTypes.func.isRequired
  };

  componentDidMount () {
    this.props.onPaymentInitialize();
  }

  render () {
    const {handleSubmit} = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="o-layout">
          <div className="o-layout__item u-1/1 u-4/10@desktop">
            <h4 className="c-h4">{'Name \u0026 Contact Details'}</h4>
            <FormSection name="contactDetails">
              <ContactFields />
            </FormSection>
            <h4 className="c-h4">{'Home Address'}</h4>
            <FormSection name="homeAddress">
              <HomeAddressField />
            </FormSection>
            <h4 className="c-h4">{'Change Password'}</h4>
            <FormSection name="passwordChange">
              <PasswordConfirmFields />
            </FormSection>
            <h4 className="c-h4">{'Notification Preferences'}</h4>
            <FormSection name="notificationPreferences">
              <NotificationSettings
                  settings={this.props.settings}
              />
            </FormSection>
          </div>
          <div className="o-layout__item u-1/1 u-5/10@desktop u-push-1/10@desktop">
            <h4 className="c-h4">{'Children'}</h4>
            <ChildrenListFields />
            <h4 className="c-h4">{'Payment'}</h4>
            <PaymentMethodFields
                handleDeletePayment={this.props.handleDeletePayment}
                onPaymentInitialize={this.props.onPaymentInitialize}
                payments={this.props.payments}
                readOnly
                tokenizeCard={this.props.tokenizeCard}
                updatePayments={this.props.updatePayments}
                openModal={this.props.openModal}
                closeModal={this.props.closeModal}
            />
            <div className="c-settings-save">
              <FormButton
                  className="c-form-button--primary c-form-button--block"
                  label="Save"
                  type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'settings',
  enableReinitialize: true,
  validate: validateFields({
    homeAddress: validateHomeAddress,
    contactDetails: validateContact,
    passwordChange: validatePasswordChange,
    children: validateFieldArray(validateChildren),
    paymentMethods: validateFieldArray(validatePaymentMethod)
  }, {
    paymentMethods: paymentMethods => {
      if (!paymentMethods || !paymentMethods.length) {
        return {_error: 'At least one payment method is required'};
      }
    },
    children: children => {
      if (!children || !children.length) {
        return {_error: 'At least one child is required'};
      }
    }
  })
})(SettingsComponent);
