import moment from 'moment';

import Location from 'models/Location';
import SitterProfile from 'models/sitter/Profile';
import ParentProfile from 'models/parent/Profile';
import ChildProfile from 'models/ChildProfile';

export default class Booking {
  constructor (json = {}) {
    this.id = json.id || 0;
    this.pets = {
      hasCats: (json.details && json.details.has_cats) || false,
      hasDogs: (json.details && json.details.has_dogs) || false,
      hasOther: (json.details && json.details.has_other) || false
    };
    this.location = new Location(json.location);
    this.start = moment(json.start_time);
    this.end = moment(json.end_time);
    this.status = json.status;
    this.note = json.note;
    this.videoConsent = json.video_consent || false;
    this.parent = json.parent ? (new ParentProfile(json.parent)) : undefined;
    this.sitter = json.sitter ? (new SitterProfile(json.sitter)) : undefined;
    const children = json.child_profiles || [];
    this.children = children.map(child => new ChildProfile(child));
    this.policy = json.policy;
  }

  get isInProgress () {
    if (!this.status) {
      return false;
    }
    return this.status === 'in_progress';
  }

  get isEnded () {
    if (!this.status) {
      return false;
    }
    return this.status === 'ended';
  }

  get isCancelled () {
    if (!this.status) {
      return false;
    }
    return this.status === 'cancelled';
  }
}
