import {createSelector} from 'reselect';

export const getQueryParams = (state, ownProps) => ownProps.location.query;
export const getProfile = state => state ? state.profile : {};

export const getStoredCurrentPage = createSelector(
  [getProfile],
  profile => profile.reviewPage
);

export const getReviews = createSelector(
  [getProfile],
  profile => profile.reviews
);

export const getReviewsCount = createSelector(
  [getProfile],
  profile => profile.reviewsCount
);

export const getReviewsPerPage = createSelector(
  [],
  () => 10
);

export const getReviewsPageCount = createSelector(
  [getReviewsPerPage, getReviewsCount],
  (perPage, reviewsCount) => Math.ceil(reviewsCount / perPage)
);

export const getPhoneNumber = createSelector(
  [getProfile],
  user => user.phone_number
);

export const getCurrentPage = createSelector(
  [getQueryParams],
  params => parseInt(params.reviewPage || '1', 10)
);

export const getFirstName = createSelector(
  [getProfile],
  user => user.first_name
);

export const getLastName = createSelector(
  [getProfile],
  user => user.last_name
);

export const getName = createSelector(
  [getProfile],
  user => `${user.first_name} ${user.last_name}`
);

export const getEmail = createSelector(
  [getProfile],
  user => user.email
);

export const getFavorites = createSelector(
  [getProfile],
  user => user.favorites
);

export const getAvailabilities = createSelector(
  [getProfile],
  user => user.availabilities
);

export const getLoading = createSelector(
  [getProfile],
  user => user.loading
);

export const getSitter = createSelector(
  [getProfile],
  user => user.sitter
);
