import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {logOut as logOutAction} from 'actions/logOut';

export class LogOut extends React.Component {
  static propTypes = {
    logOut: PropTypes.func.isRequired
  };

  componentWillMount () {
    const {logOut} = this.props;
    logOut();
  }

  render () {
    return null;
  }
}

function mapStateToProps () {
  return {
  };
}

const mapDispatchToProps = {
  logOut: logOutAction
};

export default connect(mapStateToProps, mapDispatchToProps)(LogOut);
