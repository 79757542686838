import React from 'react';
import PropTypes from 'prop-types';

import BookingRecord from 'components/BookingRecord';

export default class BookingsList extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    previousList: PropTypes.array.isRequired,
    onBookingDestroy: PropTypes.func
  };

  render () {
    const {
      list,
      previousList,
      onBookingDestroy
    } = this.props;
    const openBookings = list.filter(b => b.status !== 'cancelled');
    const cancelledBookings = [...list, ...previousList].filter(b => b.status === 'cancelled');
    const endedBookings = previousList.filter(b => b.status === 'ended');
    return (
      <div>
        {!!openBookings.length && (
          <h1 className="c-h1 u-center-text">{'Upcoming bookings'}</h1>
        )}
        {openBookings.map((booking, key) => (
          <BookingRecord {...{booking, key, onBookingDestroy}} />
        ))}
        {!!endedBookings.length && (
          <h1 className="c-h1 u-center-text">{'Past Bookings'}</h1>
        )}
        {endedBookings.map((booking, key) => (
          <BookingRecord {...{booking, key, onBookingDestroy}} />
        ))}
        {!!cancelledBookings.length && (
          <h1 className="c-h1 u-center-text">{'Cancelled Bookings'}</h1>
        )}
        {cancelledBookings.map((booking, key) => (
          <BookingRecord {...{booking, key, onBookingDestroy}} />
        ))}
      </div>
    );
  }
}
