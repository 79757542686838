import {CALL_API} from 'middleware/api';
import {SECRET_KEY} from 'constants/settings';
import {user as userSchema} from 'schemas/user';
import {
  AUTH_REQUEST,
  GET_LOGIN_STATUS,
  SIGNUP_REQUEST as FACEBOOK_SIGNUP_REQUEST,
  SIGNUP_SUCCESS as FACEBOOK_SIGNUP_SUCCESS,
  SIGNUP_FAILURE as FACEBOOK_SIGNUP_FAILURE,
} from 'constants/facebook';

export function auth () {
  return {type: AUTH_REQUEST};
}

export function getLoginStatus () {
  return {type: GET_LOGIN_STATUS};
}

export function facebookSignUp (accessToken, zip_code) { // eslint-disable-line camelcase
  const role = process.env.APP_ROLE === 'parent' ? 'parent' : 'sitter';

  return {
    [CALL_API]: {
      data: {
        facebook_access_token: accessToken, // eslint-disable-line camelcase
        zip_code // eslint-disable-line camelcase
      },
      endpoint: `${role}/registration`,
      headers: {
        role: 'parent',
        'X-Identity-Key': SECRET_KEY
      },
      method: 'POST',
      schema: userSchema,
      types: [
        FACEBOOK_SIGNUP_REQUEST,
        FACEBOOK_SIGNUP_SUCCESS,
        FACEBOOK_SIGNUP_FAILURE
      ]
    }
  };
}

export const facebookLogin = facebookSignUp;
