import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';

import {track} from 'utils/track';
import PortraitFrame from 'components/PortraitFrame';
import * as timeFormats from 'utils/timeFormats';
import ProviderReviewForm from 'forms/ProviderReview';
import {closeModal as closeModalAction} from 'actions/modal';
import {
  listUnreviewedBookings as listUnreviewedBookingsAction,
  submitProviderReview as submitProviderReviewAction
} from 'actions/parent/booking';
import {
  favoriteSitter as favoriteSitterAction,
  unfavoriteSitter as unfavoriteSitterAction
} from 'actions/parent/profile';
import {loadFavorites as loadFavoritesAction} from 'actions/parent/profile';

export class ProviderReview extends React.Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    submitProviderReview: PropTypes.func.isRequired,
    favoriteSitter: PropTypes.func.isRequired,
    unfavoriteSitter: PropTypes.func.isRequired,
    unreviewed: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
    listUnreviewedBookings: PropTypes.func.isRequired,
    favorites: PropTypes.array.isRequired,
    loadFavorites: PropTypes.func.isRequired,
  };

  constructor (props) {
    super(props);
    this.handleSubmit = this.onSubmit();
    this.handleFavorite = this.onFavorite();
    this.handleUnfavorite = this.onUnfavorite();
  }

  componentDidMount () {
    track('Provider Review');
  }

  onFavorite () {
    return () => {
      this.props.favoriteSitter(this.props.authToken, this.props.sitter.id);
    };
  }

  onUnfavorite () {
    return () => {
      this.props.unfavoriteSitter(this.props.authToken, this.props.sitter.id);
    };
  }

  // Check if sitter is favorited or not
  isFavoritedSitter (sitterId) {
    const favorites = this.props.favorites;
    return favorites.some(favorite => {
      return sitterId === favorite.id; // eslint-disable-line no-unused-expressions
    });
  }

  onSubmit () {
    return ({providerReview}) => {
      const {authToken} = this.props;
      const booking = this.props.unreviewed[0];
      const favorited = this.isFavoritedSitter(booking.sitter.id);
      Promise.all([
        new Promise((resolve) => {
          if (providerReview.favorite.indexOf(favorited.toString()) === -1) {
            if (favorited) {
              resolve(this.props.unfavoriteSitter(authToken, booking.sitter.id));
            } else {
              resolve(this.props.favoriteSitter(authToken, booking.sitter.id));
            }
          } else {
            resolve(true);
          }
        }),
        this.props.submitProviderReview(authToken,
          providerReview.bookingId, // eslint-disable-line camelcase
          providerReview.reviewContent,
          providerReview.rating,
          (providerReview.privacy && providerReview.privacy.indexOf('private') > -1)
        )
      ]).then(() => { // refactor, don't use promises, use sagas, easier to test
        this.props.closeModal();
        this.props.loadFavorites(this.props.authToken);
        if (this.props.unreviewed.length > 1) {
          this.props.listUnreviewedBookings(authToken);
        }
      });
    };
  }

  render () {
    const {unreviewed} = this.props;
    const booking = unreviewed[0];
    const favorited = this.isFavoritedSitter(booking.sitter.id);
    const initialValues = {
      providerReview: {
        rating: 0,
        bookingId: booking.id,
        favorite: [favorited.toString()]
      }
    };
    const {
      sitter,
      start_time // eslint-disable-line camelcase
    } = booking;
    return (
      <div className="o-layout c-provider-review">
        <div className="o-layout__item u-1/1">
          <h3 className="c-h3 u-center-text u-margin-bottom-tiny">{`Your booking with ${sitter.first_name}`}</h3>
          <div className="u-center-text">{timeFormats.weekDateAt(moment(start_time))}</div>
          <PortraitFrame
              classes={['c-portrait-frame--medium c-provider-review__portrait']}
              src={sitter.photo_url || ''}
          />
          <ProviderReviewForm
              onSubmit={this.handleSubmit}
              {...{initialValues}}
          />
        </div>
      </div>
    );
  }
}

export default connect(state => {
  const {
    bookings: {
      unreviewed
    },
    session: {
      authToken
    },
    profile: {
      favorites
    },
  } = state;
  return {
    unreviewed,
    authToken,
    favorites
  };
}, {
  submitProviderReview: submitProviderReviewAction,
  favoriteSitter: favoriteSitterAction,
  unfavoriteSitter: unfavoriteSitterAction,
  listUnreviewedBookings: listUnreviewedBookingsAction,
  closeModal: closeModalAction,
  loadFavorites: loadFavoritesAction
})(ProviderReview);
