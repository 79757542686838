import {
  BOOKING_LISTING_REQUEST,
  BOOKING_LISTING_SUCCESS,
  BOOKING_LISTING_FAILURE,
  PREVIOUS_BOOKING_LISTING_SUCCESS,
  BOOKING_PRICE_REQUEST,
  BOOKING_PRICE_SUCCESS,
  UNREVIEWED_BOOKING_LISTING_SUCCESS,
  BOOKING_DETAILS_SUCCESS,
  BOOKING_REFRESH
} from 'constants/parent/booking';

export function getDefaultState () {
  return {
    list: [],
    previousList: [],
    loading: false,
    priceEstimate: -1,
    estimate: null,
    unreviewed: [],
    inProgress: null,
    totalCount: 0,
    futurePage: 1,
    perPage: 10,
    pastPage: 1
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === BOOKING_DETAILS_SUCCESS) {
    const booking = action.response.entities.bookings[action.response.result];
    return {
      ...state,
      inProgress: booking
    };
  }
  if (action.type === UNREVIEWED_BOOKING_LISTING_SUCCESS) {
    return {
      ...state,
      unreviewed: action.response.result.bookings.map(id => action.response.entities.bookings[id])
    };
  }
  if (action.type === BOOKING_LISTING_REQUEST) {
    return {
      ...state,
      loading: true
    };
  }
  if (action.type === BOOKING_LISTING_FAILURE) {
    return {
      ...state,
      loading: false
    };
  }
  if (action.type === BOOKING_REFRESH) {
    return {
      ...state,
      loading: false
    };
  }
  if (action.type === PREVIOUS_BOOKING_LISTING_SUCCESS) {
    return {
      ...state,
      previousList: action.pushButton ?
        [...state.previousList, ...action.response.result.bookings.map(id => action.response.entities.bookings[id])] :
        action.response.result.bookings.map(id => action.response.entities.bookings[id]),
      totalCount: action.response.result.total_count,
      pastPage: action.pushButton ? state.pastPage + 1 : state.pastPage,
      list: [],
      futurePage: 1
    };
  }
  if (action.type === BOOKING_LISTING_SUCCESS) {
    return {
      ...state,
      loading: false,
      list: action.pushButton ?
        [...state.list, ...action.response.result.bookings.map(id => action.response.entities.bookings[id])] :
        action.response.result.bookings.map(id => action.response.entities.bookings[id]),
      totalCount: action.response.result.total_count,
      futurePage: action.pushButton ? state.futurePage + 1 : state.futurePage,
      previousList: [],
      pastPage: 1
    };
  }
  if (action.type === BOOKING_PRICE_SUCCESS) {
    const {response} = action;
    const estimate = response.entities.bookings[response.result];
    const total = estimate.parent_total;
    return {
      ...state,
      priceEstimate: total,
      estimate
    };
  }
  if (action.type === BOOKING_PRICE_REQUEST) {
    return {
      ...state,
      priceEstimate: null // refactor, extract constant, decouple presentation
    };
  }
  if (action.type === BOOKING_REFRESH) {
    return {
      state: getDefaultState()
    };
  }
  return state;
}
