import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import WeekPicker from 'components/WeekPicker';

export default class SchedulePicker extends React.Component {
  static propTypes = {
    availabilities: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    resolve: PropTypes.func.isRequired,
    reject: PropTypes.func.isRequired
  };

  handleClick = ({availability}) => {
    this.props.resolve(availability);
  }

  generateEvents (availabilities) {
    return availabilities.reduce((p, c) => {
      const start = moment(c.start_time);
      const end = moment(c.end_time);
      if (start.format('DD') == end.format('DD')) {
        return [
          ...p,
          {
            start: start.toDate(),
            end: end.toDate(),
            title: '',
            availability: c
          }
        ];
      } else {
        return [
          ...p,
          {
            start: start.toDate(),
            end: start.clone().endOf('day').toDate(),
            title: '',
            availability: c
          },
          {
            start: end.clone().startOf('day').toDate(),
            end: end.toDate(),
            title: '',
            availability: c
          }
        ];
      }
    }, []);
  }

  render () {
    const defaultDate = this.props.availabilities[0] ? moment(this.props.availabilities[0].start_time).toDate() : moment().toDate();
    const events = this.generateEvents(this.props.availabilities);
    const formats = {
      dayFormat: 'ddd DD'
    };
    return (
      <div className="o-layout">
        <div className="o-layout__item">
          <WeekPicker
              handleClick={this.handleClick}
              {...{defaultDate, events, formats}}
          />
        </div>
      </div>
    );
  }
}
