import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import FormButton from 'forms/controls/FormButton';
import {track} from 'utils/track';
import HomeAddressField from 'forms/fields/HomeAddress';
import {
  validateFields,
  validateHomeAddress
} from 'utils/formValidations';

export class HomeAddressComponent extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired
  };

  componentDidMount () {
    track('Complete Registration Page - Home Address');
  }

  render () {
    const {
      handleSubmit,
      isLoading
    } = this.props;
    return (
      <div>
        <h1 className="c-h1 u-center-text c-search-header">{'Home Address'}</h1>
        <p className="u-center-text">{'This allows us to locate child care providers in your area.'}</p>
        <form onSubmit={handleSubmit}>
          <FormSection name="homeAddress">
            <HomeAddressField />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                disabled={isLoading}
                label="Next"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'homeAddress',
  validate: validateFields({
    homeAddress: validateHomeAddress
  })
})(HomeAddressComponent);
