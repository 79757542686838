import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import accounting from 'accounting';

import FormButton from 'forms/controls/FormButton';

export default class PricingBreakdown extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    estimate: PropTypes.object.isRequired,
    copy: PropTypes.object.isRequired
  };

  interpolateValues (input, estimate, formatter) {
    let body = input;
    if (body) {
      const matches = body.match(/:\w+/g);
      if (matches) {
        matches.forEach(m => {
          const stringKey = m.split('').splice(1).join('');
          let newValue = '';
          if (stringKey == 'sitter_rate_description') {
            newValue = '';
          } else {
            newValue = estimate[stringKey];
          }
          return body = body.replace(m, formatter(newValue));
        });
      }
    }
    return body;
  }

  render () {
    const {
      en: {
        booking
      }
    } = this.props.copy;
    const bookingHours = Math.abs(moment(this.props.estimate.start_time).diff(moment(this.props.estimate.end_time), 'minutes')) / 60;
    const estimate = {
      ...this.props.estimate,
      booking_hours: bookingHours === 1 ? `${bookingHours} hour` : `${bookingHours} hours` // eslint-disable-line camelcase
    };
    return (
      <div>
        {booking.price_breakdown.items.map((i, key) => {
          const body = this.interpolateValues(i.body, estimate, b => {
            if (typeof b === 'number') {
              return accounting.formatNumber(b, 2);
            }
            return b;
          });
          const value = this.interpolateValues(i.value, estimate, q => accounting.formatNumber(q, 2));
          return (
            <div
                className="c-pricing-breakdown-row"
                {...{key}}
            >
              <div className="c-pricing-breakdown-row__title o-layout">
                <div className="o-layout__item u-3/4">
                  {i.title}
                </div>
                <div className="o-layout__item u-1/4 u-right-text">
                  {value}
                </div>
              </div>
              <div className="c-pricing-breakdown-row__body">
                <p className="u-3/4">{body}</p>
              </div>
            </div>
          );
        })}
        <div className="o-layout">
          <div className="o-layout__item u-push-1/3 u-1/3">
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="Ok, thanks"
                onClick={this.props.closeModal}
                type="button"
            />
          </div>
        </div>
      </div>
    );
  }
}
