import createSagaMiddleware from 'redux-saga';
import {browserHistory} from 'react-router';
import {compose, createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'react-router-redux';

import api from 'middleware/api';
import reducer from 'reducers/parent';
import sagas from 'sagas/parent';

const router = routerMiddleware(browserHistory);

const sagaMiddleware = createSagaMiddleware();

const enhancer = (process.env.NODE_ENV === 'production') ?
  compose(applyMiddleware(api, router, sagaMiddleware)) :
  compose(
    applyMiddleware(api, router, sagaMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

export function configureStore (initialState) {
  const store = createStore(
    reducer,
    initialState,
    enhancer
  );
  sagaMiddleware.run(sagas);
  return store;
}
