import {browserHistory} from 'react-router';
import {
  call,
  fork,
  put,
  takeEvery,
  take
} from 'redux-saga/effects';
import {change} from 'redux-form';
import {setPerson} from 'utils/track';

import WelcomeIntro from 'containers/parent/WelcomeIntro';
import {openModal} from 'actions/modal';
import {
  submitPayment as submitPaymentAction,
  requestPayments as requestPaymentsAction,
} from 'actions/parent/signUp';
import {SET_AUTH_TOKEN} from 'constants/session';
import {
  COMPLETE_REGISTRATION_PAGE,
  HOME_PAGE,
  LOGOUT_PAGE,
  SIGN_UP_PAGE,
  WAIT_LIST_SUCCESS_PAGE,
} from 'constants/parent/navigation';
import {
  CREATE_CHILD_PROFILE_SUCCESS,
  SET_HOME_SUCCESS,
  SET_PROFILE_FORM,
  SUBMIT_PROFILE_SUCCESS,
  WAIT_LIST
} from 'constants/parent/signUpFlow';
import {
  BRAINTREE_TOKEN_SUCCESS,
  CHILD_PROFILE_STEP,
  COMPLETE_PROFILE_STEP,
  LOCATION_STEP,
  PAYMENT_STEP,
  REGISTER_STEP,
  REGISTRATION_SUCCESS,
  TOKENIZE_CARD_REQUEST,
  VALIDATE_STATUS,
  WAIT_LIST_SIGNUP_SUCCESS,
  PAYMENT_DELETE_SUCCESS,
  PAYMENT_DELETE_REQUEST
} from 'constants/parent/signUpFlow';
import {
  PROFILE_SUCCESS
} from 'constants/profile';

import * as payment from 'utils/payment';
import * as localStore from 'utils/localStorage';

export function navigateToPage (pathname, targetLocation) {
  const newPathname = `/${COMPLETE_REGISTRATION_PAGE}/${targetLocation}`;
  if (pathname === newPathname) {
    return;
  }
  return call(browserHistory.push, newPathname);
}

export function *moveToWaitListSuccess () {
  yield takeEvery(WAIT_LIST_SIGNUP_SUCCESS, moveToWaitListSuccessEffect);
}

export function *moveToWaitListSuccessEffect () {
  yield call(browserHistory.push, `/${WAIT_LIST_SUCCESS_PAGE}`);
}

export function *signUp () {
  while (true) { // eslint-disable-line no-constant-condition
    const {response} = yield take(REGISTRATION_SUCCESS);
    const user = response.entities.user[response.result]; // eslint-disable-line camelcase
    yield fork(signUpEffect, user.authentication_token, user);
  }
}

export function *signUpEffect (authToken, user) {
  yield put({type: SET_AUTH_TOKEN, authToken});
  yield call(setPerson, {
    $email: user.email, // eslint-disable-line quote-props
    $first_name: user.first_name, // eslint-disable-line camelcase
    $last_name: user.last_name // eslint-disable-line camelcase
  });
}

export function * submitProfileSuccess () {
  while (true) { // eslint-disable-line no-constant-condition
    yield take(SUBMIT_PROFILE_SUCCESS);
    yield fork(submitProfileSuccessEffect);
  }
}

export function * submitProfileSuccessEffect () {
  yield call(browserHistory.push, `/${HOME_PAGE}`);
}

export function *moveToHomePage () {
  yield takeEvery(SET_HOME_SUCCESS, moveToHomePageEffect);
}

export function *moveToHomePageEffect () {
  yield call(browserHistory.push, `/${HOME_PAGE}`);
  yield put(openModal(WelcomeIntro, '', {}, {'c-welcome-intro': true}));
}

export function *moveToCompleteProfileStep () {
  yield takeEvery(COMPLETE_PROFILE_STEP, moveToCompleteProfileStepEffect);
}

export function *moveToCompleteProfileStepEffect () {
  yield call(browserHistory.push, `/${COMPLETE_REGISTRATION_PAGE}/${COMPLETE_PROFILE_STEP}`);
}

export function *moveToChildProfilesStep () {
  while (true) { // eslint-disable-line no-constant-condition
    const {response} = yield take(SUBMIT_PROFILE_SUCCESS);
    const profile = response.entities.user[response.result];
    yield fork(submitChildProfileSuccessEffect, profile);
    yield fork(moveToChildProfilesStepEffect);
  }
}

export function *submitChildProfileSuccessEffect (profile) {
  yield call(setPerson, {
    $email: profile.email, // eslint-disable-line quote-props
    $first_name: profile.first_name, // eslint-disable-line camelcase
    $last_name: profile.last_name // eslint-disable-line camelcase
  });
}

export function *moveToChildProfilesStepEffect () {
  yield call(browserHistory.push, `/${COMPLETE_REGISTRATION_PAGE}/${CHILD_PROFILE_STEP}`);
}

// export function *moveToPaymentStep () {
//   yield takeEvery(CREATE_CHILD_PROFILE_SUCCESS, moveToPaymentStepEffect);
// }
//
// export function *moveToPaymentStepEffect () {
//   yield call(browserHistory.push, `/${COMPLETE_REGISTRATION_PAGE}/${PAYMENT_STEP}`);
// }

export function *moveToLocationStep () {
  yield takeEvery(CREATE_CHILD_PROFILE_SUCCESS, moveToLocationStepEffect);
}

export function *moveToLocationStepEffect () {
  yield call(browserHistory.push, `/${COMPLETE_REGISTRATION_PAGE}/${LOCATION_STEP}`);
}

export function *moveToRegisterStep () {
  while (true) { // eslint-disable-line no-constant-condition
    const {zipCode} = yield take(REGISTER_STEP);
    yield fork(moveToRegisterStepEffect, zipCode);
  }
}

export function *moveToRegisterStepEffect (zipCode) {
  yield call(browserHistory.push, `/${SIGN_UP_PAGE}/${REGISTER_STEP}/${zipCode}`);
}

export function *moveToWaitList () {
  while (true) { // eslint-disable-line no-constant-condition
    const {zipCode} = yield take(WAIT_LIST);
    yield fork(moveToWaitListEffect, zipCode);
  }
}

export function *moveToWaitListEffect (zipCode) {
  yield call(browserHistory.push, `/${SIGN_UP_PAGE}/${WAIT_LIST}/${zipCode}`);
}

export function *validateStatus () {
  while (true) { // eslint-disable-line no-constant-condition
    const {
      pathname,
      status
    } = yield take(VALIDATE_STATUS);
    yield fork(validateStatusEffect, status, pathname);
  }
}

export function *validateStatusEffect (status, pathname) {
  if (pathname === `/${LOGOUT_PAGE}` || pathname === `/${WAIT_LIST_SUCCESS_PAGE}`) {
    return;
  } else if (!status.allow_access) { // eslint-disable-line no-constant-condition
    yield call(browserHistory.push, `/${WAIT_LIST_SUCCESS_PAGE}`);
  } else if (!status.has_phone_number) {
    yield navigateToPage(pathname, COMPLETE_PROFILE_STEP);
  } else if (!status.has_child_profile) {
    yield navigateToPage(pathname, CHILD_PROFILE_STEP);
  } else if (!status.is_verified && !status.has_payment_method) {
    yield navigateToPage(pathname, PAYMENT_STEP);
  } else if (!status.has_address) {
    yield navigateToPage(pathname, LOCATION_STEP);
  }
}

export function *setProfileForm () {
  while (true) { // eslint-disable-line no-constant-condition
    const {
      email,
      name,
      phoneNumber
    } = yield take(SET_PROFILE_FORM);
    yield fork(setProfileFormEffect, name, email, phoneNumber);
  }
}

export function *setProfileFormEffect (name, email, phoneNumber) {
  yield put(change('completeProfile', 'firstLastName', name));
  yield put(change('completeProfile', 'email', email));
  yield put(change('completeProfile', 'phoneNumber', phoneNumber));
}

export function *tokenizeCard () {
  while (true) { // eslint-disable-line no-constant-condition
    const {
      shouldRedirect,
      callback
    } = yield take(TOKENIZE_CARD_REQUEST);
    yield fork(tokenizeCardEffect, shouldRedirect, callback);
  }
}

export function *tokenizeCardEffect (shouldRedirect, callback) {
  const nonce = yield call(payment.tokenizeCard);
  if (nonce) {
    const authToken = yield call(localStore.get, 'TRUSTED_AUTH_TOKEN_' + process.env.APP_ROLE); // refactor, probably not right
    const result = yield put(submitPaymentAction(authToken, nonce.nonce, shouldRedirect));
    yield put(requestPaymentsAction(authToken));
    result.then(callback);
  }
}

export function *handleBraintreeToken () {
  while (true) { // eslint-disable-line no-constant-condition
    const {response} = yield take(BRAINTREE_TOKEN_SUCCESS);
    const {braintree_token} = response.entities.braintree[response.result]; // eslint-disable-line camelcase
    yield fork(handleBraintreeTokenEffect, braintree_token);
  }
}

export function *handleBraintreeTokenEffect (braintreeToken) {
  yield call(payment.initialize, braintreeToken);
}

export function *deletePayments () {
  while (true) {
    const {
      callback
    } = yield take(PAYMENT_DELETE_REQUEST);
    const {response} = yield take(PAYMENT_DELETE_SUCCESS);
    if (response) {
      const authToken = yield call(localStore.get, 'TRUSTED_AUTH_TOKEN_' + process.env.APP_ROLE);
      const result = yield put(requestPaymentsAction(authToken));
      result.then(callback);
    }
  }
}
