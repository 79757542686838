import {createSelector} from 'reselect';

export const getForm = state => state.form;

export const getSearch = createSelector(
  [getForm],
  form => form.search
);

export const getValuesExist = createSelector(
  [getSearch],
  search => !!search
);

export const getProviderSearch = createSelector(
  [getSearch],
  search => search.values.providerSearch
);

export const getSchedule = createSelector(
  [getProviderSearch],
  providerSearch => providerSearch.schedule
);

export const getTargetDateActive = createSelector(
  [getSchedule],
  schedule => schedule.targetDateActive || false
);

export const getTimeRangeActive = createSelector(
  [getSchedule],
  schedule => schedule.timeRangeActive || false
);

export const getChildProfiles = createSelector(
  [getProviderSearch],
  providerSearch => providerSearch.children
);

export const getAddress = createSelector(
  [getProviderSearch],
  providerSearch => providerSearch.address
);
