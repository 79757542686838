// import braintree from 'braintree-web';
import Promise from 'bluebird';
import dropin from 'braintree-web-drop-in';
/*
if (process.env.NODE_ENV !== 'test') { // refactor, i don't like this hack
  braintree = require('braintree-web');
}
*/

let client;

export function initialize (clientToken) {
  dropin.create({
    authorization: clientToken,
    selector: '#dropin-container',
    vaultManager: true,
  }, function (err, instance) {
    if (err) {
      return;
    }
    client = instance;
  });
}

export function tokenizeCard () {
  if (client !== undefined && client.isPaymentMethodRequestable()) {
    return new Promise((resolve, reject) => {
      client.requestPaymentMethod((error, payload) => {
        if (error) {
          reject(new Error(error));
        } else {
          resolve(payload);
        }
      });
    });
  }
}
