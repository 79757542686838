import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import {track} from 'utils/track';
import FormButton from 'forms/controls/FormButton';
import RegistrationFields from 'forms/fields/Registration';
import {
  validateFields,
  validateRegistration
} from 'utils/formValidations';

export class CompleteProfileComponent extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired
  };

  componentDidMount () {
    track('Complete Registration Page - Profile');
  }

  render () {
    const {handleSubmit} = this.props;
    return (
      <div>
        <h1 className="c-h1 u-center-text c-search-header">{'Complete Your Profile'}</h1>
        <p className="u-center-text">{'Please confirm that the following information is correct'}</p>
        <form onSubmit={handleSubmit}>
          <FormSection name="profile">
            <RegistrationFields requireTerms={false} />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                label="Next"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'completeProfile',
  validate: validateFields({
    profile: validateRegistration(false)
  })
})(CompleteProfileComponent);
