import {browserHistory} from 'react-router'
import {
  call,
  put,
  takeEvery,
  take,
  fork
} from 'redux-saga/effects'

// import BookingSuccess from 'components/BookingSuccess'
import ProviderReview from 'containers/parent/ProviderReview'
import {BOOKINGS_FUTURE_PAGE} from 'constants/parent/navigation'
import {
  BOOKING_SUCCESS,
  BOOKING_FAILURE,
  UNREVIEWED_BOOKING_LISTING_SUCCESS,
} from 'constants/parent/booking'
import {
  closeModal,
  openModal
} from 'actions/modal'
import ErrorModal from 'containers/ErrorModal';

export function *closeBookingConfirmation () {
  yield takeEvery(BOOKING_SUCCESS, handleBookingSuccess) // refactor, is this the right thing to do?
}

export function *handleBookingSuccess () {
  yield call(browserHistory.push, `/${BOOKINGS_FUTURE_PAGE}`)
  yield put(closeModal())
  // yield put(openModal(BookingSuccess, 'Booking Confirmed!', {})) // refactor, figure out how to dismiss modal when clicking 'done' button, it calls the closeModal action but doesn't update state
}

export function *unreviewedBookingList () {
  yield takeEvery(UNREVIEWED_BOOKING_LISTING_SUCCESS, unreviewedBookingListEffect)
}

export function *unreviewedBookingListEffect () {
  yield put(openModal(ProviderReview, '', {
  }, {
    'c-modal--untitled': true,
    'c-provider-review-modal': true
  }))
}

export function * bookingCreateFailure () {
  while (true) {
    const {
      error,
      resolve
    } = yield take(BOOKING_FAILURE);
    yield fork(bookingCreateFailureEffect, error, resolve);
  }
}

export function * bookingCreateFailureEffect (error, resolve) {
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
  yield call(resolve);
}
