import {createSelector} from 'reselect';
import BookingModel from 'models/Booking';

import {getForm} from 'selectors/form';
import {getParams} from 'selectors/session';

export const getBookingsPerPage = createSelector(
  [],
  () => 10
);
export const getBookings = state => state.bookings;

export const getPastBookingsPageCount = createSelector(
  [getBookingsPerPage, getBookings],
  (perPage, bookings) => Math.ceil(bookings.pastBookingsCount / perPage)
);

export const getFutureBookingsPageCount = createSelector(
  [getBookingsPerPage, getBookings],
  (perPage, bookings) => Math.ceil(bookings.futureBookingsCount / perPage)
);

export const getPastBookings = createSelector(
  [getBookings],
  bookings => bookings.pastBookings.map(a => new BookingModel(a))
);

export const getFutureBookings = createSelector(
  [getBookings],
  bookings => bookings.futureBookings.map(a => new BookingModel(a))
);

export const getParamsBookingId = createSelector(
  [getParams],
  params => params.bookingId
);

export const getBookingDetails = createSelector(
  [getBookings],
  bookings => bookings.bookingDetails
);

export const getBookingDetailsLoaded = createSelector(
  [getBookingDetails],
  booking => booking !== null
);

export const getSitterParentNotes = createSelector(
  [getBookings],
  bookings => bookings.sitterParentNotes
);

export const getPriceEstimate = createSelector(
  [getBookings],
  bookings => bookings.priceEstimate
);

export const getEstimate = createSelector(
  [getBookings],
  bookings => bookings.estimate
);

export const getBookingParent = createSelector(
  [getBookingDetails],
  booking => booking.parent
);

export const getBookingParentId = createSelector(
  [getBookingParent],
  parent => parent.id
);

export const getEndTime = createSelector(
  [getForm],
  form => form.endTime
);

export const getEndTimeExists = createSelector(
  [getEndTime],
  endTime => !!endTime
);

export const getEndTimeTime = createSelector(
  [getEndTime],
  endTime => endTime.values.endTime.time
);
