import {CALL_API} from 'middleware/api';
import {SECRET_KEY} from 'constants/settings';
import {
  review as reviewSchema
} from 'schemas/review';
import {
  credit as creditSchema
} from 'schemas/credit';
import {
  discountCode as discountCodeSchema
} from 'schemas/discountCode';
import {
  booking as bookingSchema,
  bookings as bookingsSchema
} from 'schemas/booking';
import {
  BOOKING_FAILURE,
  BOOKING_REQUEST,
  BOOKING_SUCCESS,
  BOOKING_DETAILS_FAILURE,
  BOOKING_DETAILS_REQUEST,
  BOOKING_DETAILS_SUCCESS,
  BOOKING_PRICE_FAILURE,
  BOOKING_PRICE_REQUEST,
  BOOKING_PRICE_SUCCESS,
  BOOKING_DESTROY_FAILURE,
  BOOKING_DESTROY_REQUEST,
  BOOKING_DESTROY_SUCCESS,
  BOOKING_LISTING_FAILURE,
  BOOKING_LISTING_REQUEST,
  BOOKING_LISTING_SUCCESS,
  PREVIOUS_BOOKING_LISTING_FAILURE,
  PREVIOUS_BOOKING_LISTING_REQUEST,
  PREVIOUS_BOOKING_LISTING_SUCCESS,
  REQUEST_BOOKING_PRICE,
  UNREVIEWED_BOOKING_LISTING_REQUEST,
  UNREVIEWED_BOOKING_LISTING_SUCCESS,
  UNREVIEWED_BOOKING_LISTING_FAILURE,
  PROVIDER_REVIEW_REQUEST,
  PROVIDER_REVIEW_SUCCESS,
  PROVIDER_REVIEW_FAILURE,
  DISCOUNT_CODE_REQUEST,
  DISCOUNT_CODE_SUCCESS,
  DISCOUNT_CODE_FAILURE,
  PURCHASE_CREDIT_REQUEST,
  PURCHASE_CREDIT_SUCCESS,
  PURCHASE_CREDIT_FAILURE,
  BOOKING_REFRESH
} from 'constants/parent/booking';

export function purchaseCredit (authToken, credit_option) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      data: {authToken, credit_option}, // eslint-disable-line camelcase
      endpoint: 'parent/credit_purchases',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: creditSchema,
      types: [
        PURCHASE_CREDIT_REQUEST,
        PURCHASE_CREDIT_SUCCESS,
        PURCHASE_CREDIT_FAILURE
      ]
    }
  };
}

export function submitProviderReview (authToken, booking_id, body, rating, hidden) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      data: {authToken, booking_id, body, rating, hidden}, // eslint-disable-line camelcase
      endpoint: `parent/bookings/${booking_id}/reviews`, // eslint-disable-line camelcase
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: reviewSchema,
      types: [
        PROVIDER_REVIEW_REQUEST,
        PROVIDER_REVIEW_SUCCESS,
        PROVIDER_REVIEW_FAILURE
      ]
    }
  };
}

export function listUnreviewedBookings (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'parent/bookings?scope=unreviewed',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: bookingsSchema,
      types: [
        UNREVIEWED_BOOKING_LISTING_REQUEST,
        UNREVIEWED_BOOKING_LISTING_SUCCESS,
        UNREVIEWED_BOOKING_LISTING_FAILURE
      ]
    }
  };
}

export function requestBooking (authToken, bookingId) {
  return {
    [CALL_API]: {
      endpoint: `parent/bookings/${bookingId}`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: bookingSchema,
      types: [
        BOOKING_DETAILS_REQUEST,
        BOOKING_DETAILS_SUCCESS,
        BOOKING_DETAILS_FAILURE
      ]
    }
  };
}

export function listPreviousBookings (authToken, page = 1, perPage = 10, pushButton) {
  return {
    [CALL_API]: {
      endpoint: `parent/bookings?scope=past&page=${page}&per_page=${perPage}`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: bookingsSchema,
      types: [
        PREVIOUS_BOOKING_LISTING_REQUEST,
        PREVIOUS_BOOKING_LISTING_SUCCESS,
        PREVIOUS_BOOKING_LISTING_FAILURE
      ]
    },
    pushButton
  };
}

export function listBookings (authToken, page = 1, perPage = 10, pushButton) {
  return {
    [CALL_API]: {
      endpoint: `parent/bookings?scope=future&page=${page}&per_page=${perPage}`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: bookingsSchema,
      types: [
        BOOKING_LISTING_REQUEST,
        BOOKING_LISTING_SUCCESS,
        BOOKING_LISTING_FAILURE
      ],
    },
    pushButton
  };
}

export function destroyBooking (authToken, bookingId, reason, refundMethod) {
  return {
    [CALL_API]: {
      endpoint: `parent/bookings/${bookingId}?reason=${encodeURIComponent(reason)}&refund_method=${refundMethod}`,
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'DELETE',
      schema: bookingsSchema,
      types: [
        BOOKING_DESTROY_REQUEST,
        BOOKING_DESTROY_SUCCESS,
        BOOKING_DESTROY_FAILURE
      ]
    }
  };
}

export function createBooking (authToken, search_id, sitter_id, payment_method_id, child_profile_ids, note, household_details) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      data: { /* eslint-disable camelcase */
        search_id,
        sitter_id,
        payment_method_id,
        child_profile_ids,
        note,
        household_details
      }, /* eslint-enable camelcase */
      endpoint: 'parent/bookings',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: bookingSchema,
      types: [
        BOOKING_REQUEST,
        BOOKING_SUCCESS,
        BOOKING_FAILURE
      ]
    }
  };
}

export function requestPriceQuote (authToken, searchId, sitterId, defaultPaymentMethod, childProfiles, timeRange) {
  return {
    timeRange,
    authToken,
    childProfiles,
    defaultPaymentMethod,
    searchId,
    sitterId,
    type: REQUEST_BOOKING_PRICE
  };
}

export function getPriceQuote (authToken, search_id, sitter_id, payment_method_id, child_profile_ids, timeRange) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      data: { /* eslint-disable camelcase */
        search_id,
        sitter_id,
        payment_method_id,
        child_profile_ids,
        start_time: timeRange.startTime,
        end_time: timeRange.endTime,
        date: timeRange.targetDate,
        note: 'estimation' // refactor, plug this in
      }, /* eslint-enable camelcase */
      endpoint: 'parent/booking_estimates',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: bookingSchema,
      types: [
        BOOKING_PRICE_REQUEST,
        BOOKING_PRICE_SUCCESS,
        BOOKING_PRICE_FAILURE
      ]
    }
  };
}

export function applyDiscount (authToken, code) {
  return {
    [CALL_API]: {
      data: { /* eslint-disable camelcase */
        code
      }, /* eslint-enable camelcase */
      endpoint: 'parent/credit_redemptions',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: discountCodeSchema,
      types: [
        DISCOUNT_CODE_REQUEST,
        DISCOUNT_CODE_SUCCESS,
        DISCOUNT_CODE_FAILURE
      ]
    }
  };
}

export function bookingRefresh () {
  return {
    type: BOOKING_REFRESH
  };
}
