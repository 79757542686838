import React from 'react';
import PropTypes from 'prop-types';

import * as SVGIcon from 'components/SVGIcon';
import FormButton from 'forms/controls/FormButton';
import FormFieldPartial from 'components/partials/FormField';

export default class DeletableField extends React.Component {
  static propTypes = {
    noRemove: PropTypes.bool.isRequired,
    onFormChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired
  };

  handleCloseClick = () => {
    this.props.onFormChange('search', `providerSearch.schedule.${this.props.name}`, false);
  };

  handleOpenClick = () => {
    this.props.onFormChange('search', `providerSearch.schedule.${this.props.name}`, true);
  };

  render () {
    return (
      <div>
        {this.props.enabled && (
          <div className="o-layout">
            {this.props.noRemove && (
              <div className="o-layout__item u-1/1">
                {this.props.children}
              </div>
            )}
            {!this.props.noRemove && (
              <div>
                <div className="o-layout__item u-7/8">
                  {this.props.children}
                </div>
                <div className="o-layout__item u-1/8">
                  <button
                      className="c-form-button c-form-button--primary c-form-button--alternate c-form-button--lean u-left-text"
                      onClick={this.handleCloseClick}
                      type="button"
                  >
                    <SVGIcon.Close active />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {!this.props.enabled && (
          <FormFieldPartial icon={this.props.icon}>
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--compact c-form-button--block u-left-text"
                label={this.props.label}
                onClick={this.handleOpenClick}
                type="button"
            />
          </FormFieldPartial>
        )}
      </div>
    );
  }
}
