import React from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import moment from 'moment';

import * as timeFormats from 'utils/timeFormats';

export default class TimeControl extends React.Component {
  timeItem (props) {
    const {text} = props;
    return (
      <span>{text}</span>
    );
  }

  render () {
    const {
      times,
      input: {
        value
      }
    } = this.props;
    let timeValue = value;
    if (!times.filter(t => t.value === value).length) {
      timeValue = timeFormats.basicTime(moment(value));
    }
    return ( // refactor, this should handle error messages
      <DropdownList
          data={times}
          defaultValue={timeFormats.basicTime(moment(times[0].value))}
          itemComponent={this.timeItem}
          onChange={event => {
            this.props.input.onChange(event.value);
          }}
          textField="label"
          value={timeValue}
          valueField="value"
      />
    );
  }
}
