import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Radio extends React.PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired
  };

  handleChange (value) {
    return () => {
      this.props.input.onChange(value);
    };
  }

  render () {
    const {
      input: {
        value,
        name
      },
      meta: {
        error,
        touched
      },
      options
    } = this.props;
    const message = touched && error;
    const className = classNames({
      'c-radio': true,
      'c-radio--error': (touched && !!error)
    });
    return (
      <div {...{className}}>
        <div className="o-layout">
          {options.map((field, key) => {
            const itemClassName = classNames({
              'c-radio__item': true,
              'c-radio__item--active': field.value === value,
              'u-margin-bottom': true
            });
            return (
              <div
                  className="o-layout__item"
                  {...{key}}
              >
                <div className={itemClassName}>
                  <div className="o-layout">
                    <div className="o-layout__item u-1/1">
                      <input
                          checked={value === field.value}
                          className="c-radio__field"
                          id={`${name}-${key}`}
                          onChange={this.handleChange(field.value)}
                          type="radio"
                          value={field.value}
                          {...{name}}
                      />
                      <label
                          className="c-radio__label"
                          htmlFor={`${name}-${key}`}
                      >
                        <div className="c-radio__content">{field.content()}</div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="o-layout__item u-1/1 c-radio__message">
            <div className="c-radio__hint">{message}</div>
          </div>
        </div>
      </div>
    );
  }
}
