import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import FormRadio from 'forms/controls/FormRadio';

export default class PaymentSelect extends React.Component {
  static propTypes = {
    creditCards: PropTypes.array.isRequired
  };

  render () {
    const {
      creditCards
    } = this.props;
    const options = creditCards.map(({id, last_4}) => ({ // eslint-disable-line camelcase
      classes: 'u-1/3',
      label: last_4, // eslint-disable-line camelcase
      value: '' + id
    }));
    return (
      <Field
          component={FormRadio}
          label="Select Payment"
          name="creditCards"
          {...{options}}
      />
    );
  }
}
