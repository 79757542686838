import {createSelector} from 'reselect';

export const getSession = state => state ? state.session : {};

export const getAuthToken = createSelector(
  [getSession],
  session => session.authToken
);

export const getCoverageZones = createSelector(
  [getSession],
  session => session.coverageZones
);

export const getCoverageZoneOptions = createSelector(
  [getCoverageZones],
  coverageZones => coverageZones.filter(z => z.depth === 2).map(z => z.id)
);

export const getParams = (state, ownProps) => ownProps.params;

export const getCurrentPage = createSelector(
  [getParams],
  params => parseInt(params.currentPage || '1', 10)
);

export const getStoredCurrentPage = createSelector(
  [getSession],
  session => session.currentPage
);

export const makeGetUser = () => {
  return createSelector(
    [getSession],
    session => session.user
  );
};

export const makeGetIsAuthenticated = () => {
  return createSelector(
    [getSession],
    session => session.isAuthenticated
  );
};

export const getParamsResetToken = createSelector(
  [getParams],
  params => params.resetToken
);

export const getIsAuthenticated = makeGetIsAuthenticated();

export const getUser = makeGetUser();

export const getId = createSelector(
  [getUser],
  user => user.id
);

export const getStatus = createSelector(
  [getUser],
  user => user.status
);

export const getDefaultLocation = createSelector(
  [getUser],
  user => user.default_location
);

export const getGeolocation = createSelector(
  [getDefaultLocation],
  defaultLocation => defaultLocation.geolocation
);

export const getRouting = state => state.routing;

export const getLocationBeforeTransitions = createSelector(
  [getRouting],
  routing => routing.locationBeforeTransitions
);

export const makeGetRoutingPathname = function () {
  return createSelector(
    [getLocationBeforeTransitions],
    locationBeforeTransitions => locationBeforeTransitions.pathname
  );
};

export const getReviews = createSelector(
  [getSession],
  session => session.reviews
);

export const getReferences = createSelector(
  [getSession],
  session => session.references
);

export const getReviewsCount = createSelector(
  [getUser],
  user => user.reviews_count
);

export const getReviewsPerPage = createSelector(
  [],
  () => 10
);

export const getReviewsPageCount = createSelector(
  [getReviewsPerPage, getReviewsCount],
  (perPage, reviewsCount) => Math.ceil(reviewsCount / perPage)
);

export const getExperienceCopy = createSelector(
  [getUser],
  user => {
    const skillList = user.skill_list;
    const first = skillList.sort()[0];
    let prefix = '';
    if (first === '0_mos_12_mos') {
      prefix = 'Newborns and children';
    } else if (first === '1_yrs_3_yrs') {
      prefix = 'Toddlers and children';
    } else if (first === '3_yrs_5_yrs') {
      prefix = 'Children from 3 years';
    } else {
      prefix = 'Children from 6 years';
    }
    return `${prefix} up to 12 years old`;
  }
);

export const getProfilePresentation = createSelector(
  [getUser, getExperienceCopy],
  (user, experienceCopy) => {
    return ({
      referencesCount: user.references_count,
      reviewsCount: user.reviews_count,
      experienceCopy,
      referencesSample: user.references_sample,
      reviewsSample: user.reviews_sample,
      state: user.default_location.state,
      videoUrl: user.video_url,
      photoUrl: user.photo_url,
      firstName: user.first_name,
      lastName: user.last_name,
      age: user.age,
      city: user.default_location.city,
      verificationList: user.verification_list,
      skillList: user.skill_list,
      summary: user.summary
    });
  }
);

export const getPhoneNumber = createSelector(
  [getUser],
  user => user.phone_number
);

export const getFirstName = createSelector(
  [getUser],
  user => user.first_name
);

export const getLastName = createSelector(
  [getUser],
  user => user.last_name
);

export const getName = createSelector(
  [getUser],
  user => `${user.first_name} ${user.last_name}`
);

export const getEmail = createSelector(
  [getUser],
  user => user.email
);

export const getBirthdate = createSelector(
  [getUser],
  user => user.date_of_birth
);

export const getPhotoUrl = createSelector(
  [getUser],
  user => user.photo_url || 'https://res.cloudinary.com/trusted/image/upload/default_parent_photo_url.png'
);

export const getZipCode = createSelector(
  [getUser],
  user => user.zip_code
);

export const getNavigation = state => state.navigation;

export const getMenuIsOpen = createSelector(
  [getNavigation],
  navigation => navigation.isOpen
);
