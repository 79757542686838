import {
  loadCoverageZones,
  loadAllCoverageZones
} from 'actions/sitter/availability';
import {getResultsPerPage} from 'selectors/sitter/search';
import {
  loadAllSearches as loadAllSearchesAction,
  loadOneTimeSearches as loadOneTimeSearchesAction,
  loadRecurringSearches as loadRecurringSearchesAction,
  loadInterestedSearches as loadInterestedSearchesAction
} from 'actions/sitter/search';
import {
  makeGetIsAuthenticated,
  makeGetRoutingPathname,
  getAuthToken,
  getCurrentPage,
  getUser,
  getStatus,
  getReviewsPerPage
} from 'selectors/session';
import {
  LOGIN_PAGE,
  HOME_PAGE,
  TYPEFORM_PAGE,
  APPROVED_PAGE,
  REJECTED_PAGE,
  COMPLETE_REGISTRATION_PAGE
} from 'constants/sitter/navigation';
import {
  loadReviews as loadReviewsAction,
  loadReferences as loadReferencesAction
} from 'actions/sitter/reviews';
import {
  loadFutureBookings,
  loadBookingDetails,
  loadParentSitterNotes,
  loadPastBookings
} from 'actions/sitter/bookings';
import {
  getBookingsPerPage,
  getParamsBookingId,
  getBookingParentId
} from 'selectors/bookings';

import {setCurrentPage} from 'actions/session';
const getRoutingPathname = makeGetRoutingPathname();
const getIsAuthenticated = makeGetIsAuthenticated();
import {setDestination} from 'utils/routes';

export function composeEnterHooksSeries (...hooks) {
  return (nextState, replace, executeTransition) => {
    (function executeHooksSynchronously (remainingHooks) {
      if (!remainingHooks.length) {
        return executeTransition();
      }
      let nextHook = remainingHooks[0];
      if (nextHook.length >= 3) {
        nextHook.call(this, nextState, replace, () => {
          executeHooksSynchronously(remainingHooks.slice(1));
        });
      } else {
        nextHook.call(this, nextState, replace);
        executeHooksSynchronously(remainingHooks.slice(1));
      }
    }(hooks));
  };
}

export function ensureAllCoverageZones (store) {
  return async (nextState, replace, next) => {
    const authToken = getAuthToken(store.getState(), nextState);
    await store.dispatch(loadAllCoverageZones(authToken));
    next();
  };
}

export function loadRecurringSearches (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const authToken = getAuthToken(state, nextState);
    const perPage = getResultsPerPage(state, nextState);
    const pageIndex = getCurrentPage(state, nextState);
    await store.dispatch(loadRecurringSearchesAction(authToken, perPage, pageIndex));
    next();
  };
}

export function loadInterestedSearches (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const authToken = getAuthToken(state, nextState);
    const perPage = getResultsPerPage(state, nextState);
    const pageIndex = getCurrentPage(state, nextState);
    await store.dispatch(loadInterestedSearchesAction(authToken, perPage, pageIndex));
    next();
  };
}

export function loadAllSearches (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const authToken = getAuthToken(state, nextState);
    const perPage = getResultsPerPage(state, nextState);
    const pageIndex = getCurrentPage(state, nextState);
    await store.dispatch(loadAllSearchesAction(authToken, perPage, pageIndex));
    next();
  };
}

export function loadOneTimeSearches (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const authToken = getAuthToken(state, nextState);
    const perPage = getResultsPerPage(state, nextState);
    const pageIndex = getCurrentPage(state, nextState);
    await store.dispatch(loadOneTimeSearchesAction(authToken, perPage, pageIndex));
    next();
  };
}

export function ensureCoverageZones (store) {
  return async (nextState, replace, next) => {
    const authToken = getAuthToken(store.getState(), nextState);
    await store.dispatch(loadCoverageZones(authToken));
    next();
  };
}

export function redirectToLoginView (store) {
  return (nextState, replace, next) => {
    if (!getIsAuthenticated(store.getState())) {
      replace({
        pathname: `/${LOGIN_PAGE}`,
        state: {nextPathname: nextState.location.pathname}
      });
      next(false);
    } else {
      next();
    }
  };
}

export function redirectToAuthenticatedView (store) { // refactor, these should be in the sagas, i think
  return (nextState, replace, next) => {
    const state = store.getState();
    const {session} = state;
    if (session.isAuthenticated) {
      replace(`/${HOME_PAGE}`);
    }
    next();
  };
}

export function validateStatus (store) {
  return (nextState, replace, next) => {
    const state = store.getState();
    if (getIsAuthenticated(state) && getUser(state)) {
      const pathname = getRoutingPathname(state);
      const status = getStatus(state);
      let destination = null;

      if (!status) { next(); return; }

      if (!status.has_onboarded && status.accepted) {
        destination = setDestination(`/${APPROVED_PAGE}`, pathname);
      } else if (status.rejected) { // eslint-disable-line no-constant-condition
        destination = setDestination(`/${REJECTED_PAGE}`, pathname);
      } else if (!status.has_profile) {
        destination = setDestination(`/${COMPLETE_REGISTRATION_PAGE}`, pathname);
      } else if (!status.has_onboarded && !(pathname === `/${TYPEFORM_PAGE}`)) {
        destination = setDestination(`/${TYPEFORM_PAGE}`, pathname);
      }

      if (destination) {
        replace({
          pathname: destination
        });
      }
    }
    next();
  };
}

export function storeCurrentPage (store) {
  return async (nextState, replace, next) => {
    const currentPage = getCurrentPage(store.getState(), nextState);
    store.dispatch(setCurrentPage(currentPage));
    next();
  };
}

export function ensurePastBookings (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const authToken = getAuthToken(state, nextState);
    const perPage = getBookingsPerPage(state, nextState);
    const pageIndex = getCurrentPage(state, nextState);
    await store.dispatch(loadPastBookings(authToken, perPage, pageIndex));
    next();
  };
}

export function ensureFutureBookings (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const authToken = getAuthToken(state, nextState);
    const perPage = getBookingsPerPage(state, nextState);
    const pageIndex = getCurrentPage(state, nextState);
    await store.dispatch(loadFutureBookings(authToken, perPage, pageIndex));
    next();
  };
}

export function ensureBookingDetails (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const bookingId = getParamsBookingId(state, nextState);
    const authToken = getAuthToken(state, nextState);
    await store.dispatch(loadBookingDetails(authToken, bookingId));
    next();
  };
}

export function ensureSitterParentNotes (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const parentId = getBookingParentId(state, nextState);
    const authToken = getAuthToken(state, nextState);
    await store.dispatch(loadParentSitterNotes(authToken, parentId));
    next();
  };
}

export function ensureReviews (store) {
  return async (nextState, replace, next) => {
    const state = store.getState();
    const authToken = getAuthToken(state, nextState);
    const perPage = getReviewsPerPage(state, nextState);
    const pageIndex = getCurrentPage(state, nextState);
    await store.dispatch(loadReviewsAction(authToken, perPage, pageIndex));
    next();
  };
}

export function ensureReferences (store) {
  return async (nextState, replace, next) => {
    const authToken = getAuthToken(store.getState(), nextState);
    await store.dispatch(loadReferencesAction(authToken));
    next();
  };
}
