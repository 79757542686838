import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import BookingCancelForm from 'forms/BookingCancel';
export const BOOKING_DESTROY_FAILURE = 'BOOKING_DESTROY_FAILURE';

export default class BookingCancel extends React.Component {
  static propTypes = {
    copy: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    authToken: PropTypes.string.isRequired,
    booking: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    destroyBooking: PropTypes.func.isRequired,
    listBookings: PropTypes.func.isRequired,
    showExtraChargesCopy: PropTypes.bool.isRequired,
    bookingRefresh: PropTypes.func
  };

  constructor (props) {
    super(props);
    this.handleSubmit = this.onSubmit();
  }

  onSubmit () {
    return ({reasonFeedback}) => {
      const isLate = moment(this.props.booking.refund_methods[0].expires_at).diff(moment()) <= 0;
      const refundMethod = isLate ? 'credit' : 'full';
      this.props.destroyBooking(this.props.authToken, this.props.booking.id, reasonFeedback.feedback, refundMethod).then(action => {
        this.props.closeModal(); // refactor, don't use this promise chaining pattern, it's not easy to test
        this.props.bookingRefresh();
        this.props.listBookings(this.props.authToken);
        if (action.type === BOOKING_DESTROY_FAILURE) {
          alert('Unable to cancel this booking.  Please contact customer support.'); // eslint-disable-line no-alert
        }
      });
    };
  }

  render () {
    const {
      closeModal,
      showExtraChargesCopy,
      copy: {
        en: {
          cancellations: {
            prompt
          }
        }
      }
    } = this.props;
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          {showExtraChargesCopy && (<p className="u-center-text">{prompt.late.body}</p>)}
          <p className="u-center-text">{prompt.default.title}</p>
          <BookingCancelForm
              onSubmit={this.handleSubmit}
              {...{closeModal}}
          />
        </div>
      </div>
    );
  }
}
