import moment from 'moment';

import * as timeFormats from 'utils/timeFormats';

const scheduleLogic = {
  calculateTimeRange: (settings, nowDateString, targetDateString, startTimeString, endTimeString, min, max) => {
    const now = moment(nowDateString);
    const targetDate = moment(targetDateString).startOf('day');
    const startTime = moment(startTimeString).startOf('minute');
    const endTime = moment(endTimeString).startOf('minute');
    const initialTime = min ? moment(min) : scheduleLogic.calculateFirstTime(settings, now, targetDate);
    const startTimes = scheduleLogic.calculateStartTimes(settings, initialTime, max);
    let newStartTime = targetDate.clone().hours(startTime.hours()).minutes(startTime.minutes());
    let findResult = startTimes.find(time => time.toISOString() === newStartTime.toISOString());
    if (!findResult) {
      newStartTime = startTimes[0];
    }
    const endTimes = scheduleLogic.calculateEndTimes(settings, initialTime, newStartTime, moment(max).format('HHmm'));
    let newEndTime = targetDate.clone().hours(endTime.hours()).minutes(endTime.minutes());
    if (newEndTime.isBefore(startTime)) { // refactor, probably not correct
      newEndTime.add(1, 'day');
    }
    findResult = endTimes.find(time => time.toISOString() === newEndTime.toISOString());
    if (!findResult) {
      newEndTime = endTimes[0];
    }
    return {
      endTime: newEndTime.toISOString(),
      endTimes,
      startTime: newStartTime.toISOString(),
      startTimes
    };
  },

  calculateFirstTime: (settings, now, targetDate) => {
    const {
      minLeadTime,
      timeIncrement
    } = settings;
    const startTime = timeFormats.parseHoursMinutes(settings.dayStartTime);
    const firstTime = targetDate.clone().set({
      hours: startTime.hours(),
      minutes: startTime.minutes()
    });
    if (firstTime.isBefore(now)) { // this handles the today case
      now.add(minLeadTime, 'minutes');
      const minutes = now.minutes();
      const minuteDelta = (Math.ceil(minutes / timeIncrement) * timeIncrement) - minutes;
      const todayFirstTime = now.add(minuteDelta, 'minutes').seconds(0).milliseconds(0);
      // refactor, handle cases after 9:30, it generates too many options and they're out of valid range
      return todayFirstTime;
    }
    return firstTime;
  },

  calculateStartTimes: (settings, initialTime, max) => {
    const {
      // endTime,
      maxDuration,
      minDuration,
      timeIncrement
    } = settings;
    const latestTime = (max ? moment(max).subtract(minDuration - timeIncrement, 'minutes') : timeFormats.parseHoursMinutes('0000')).subtract(timeIncrement, 'minutes');
    if ((maxDuration / timeIncrement) % 2) { // hopefully this prevents an infinite loops in thie `while` below
      throw new Error('Invalid settings: `maxDuration` must be evenly divisable by `timeIncrement`');
    }
    const startTimes = [
      initialTime.clone()
    ];
    while (true) { // eslint-disable-line no-constant-condition
      const time = startTimes[startTimes.length - 1].clone();
      startTimes.push(time.add(timeIncrement, 'minutes'));
      if (timeFormats.hoursMinutes(time) === timeFormats.hoursMinutes(latestTime)) {
        break;
      }
    }
    return startTimes;
  },

  calculateEndTimes: (settings, initialTime, startTime, max) => {
    const {
      endTime,
      minDuration,
      maxDuration,
      timeIncrement
    } = settings;
    const earliestTime = startTime.clone().add(minDuration, 'minutes');
    let remainingTime = maxDuration - minDuration;
    const endTimes = [
      earliestTime.clone()
    ];
    while (remainingTime) {
      remainingTime -= timeIncrement;
      const time = endTimes[endTimes.length - 1].clone();
      if (timeFormats.hoursMinutes(time) === (max || endTime)) {
        break;
      }
      endTimes.push(time.add(timeIncrement, 'minutes'));
    }
    return endTimes;
  }
};

export default scheduleLogic;
