import {LOCATION_CHANGE} from 'react-router-redux';
import {
  takeEvery,
  put
} from 'redux-saga/effects';

import {closeModal} from 'actions/modal';
import {PROFILE_SITTER_SUCCESS} from 'constants/parent/profile';
import {
  setTitle,
  toggleNav
} from 'actions/navigation';

export function *sitterProfile () {
  yield takeEvery(PROFILE_SITTER_SUCCESS, sitterProfileEffect);
}

export function *sitterProfileEffect ({response}) {
  const {
    first_name // eslint-disable-line camelcase
  } = response.entities.sitter[response.result];
  const title = first_name; // eslint-disable-line camelcase
  yield put(setTitle(title));
}

export function *locationChange () {
  yield takeEvery(LOCATION_CHANGE, locationChangeEffect);
}

export function *locationChangeEffect () {
  yield put(toggleNav(false));
  yield put(closeModal());
}
