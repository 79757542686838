import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import moment from 'moment';

import FormFieldPartial from 'components/partials/FormField';

export default class TargetDateControl extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    max: PropTypes.object,
    min: PropTypes.object
  };

  state = {
    isDateTimeOpen: false
  };

  constructor (props) {
    super(props);
    this.handleBlur = this.onBlur();
    this.handleFocus = this.onFocus();
    this.handleToggle = this.onToggle();
  }

  onBlur () {
    return () => {
      // this.props.input.onBlur(this.props.input.value || (new Date()));
      this.setState({isDateTimeOpen: false}); // eslint-disable-line react/no-set-state
    };
  }

  onFocus () {
    return () => {
      this.setState({isDateTimeOpen: 'calendar'}); // eslint-disable-line react/no-set-state
    };
  }

  onToggle () {
    return () => {
    };
  }

  render () {
    const {
      input,
      max,
      meta,
      min
    } = this.props;
    const {value} = input;
    const {isDateTimeOpen} = this.state;
    const error = (meta.touched && meta.error) || this.props.error;
    return (
      <div className="c-form-date-time c-form-field">
        <FormFieldPartial
            icon="Calendar"
            {...{error}}
        >
          <DateTimePicker
              footer={false}
              format="LL"
              onBlur={this.handleBlur}
              onChange={(newDate, label) => {
                input.onChange(newDate || (new Date()), label);
                setTimeout(() => {
                  this.setState({isDateTimeOpen: false}); // eslint-disable-line react/no-set-state
                }, 0);
              }}
              onFocus={this.handleFocus}
              onToggle={this.handleToggle}
              open={isDateTimeOpen}
              time={false}
              value={(value && moment(value).toDate()) || new Date()}
              {...{min, max}}
          />
        </FormFieldPartial>
      </div>
    );
  }
}
