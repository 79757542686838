import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import moment from 'moment';

import {track} from 'utils/track';
import * as SVGIcon from 'components/SVGIcon';
import * as timeFormats from 'utils/timeFormats';
import {PROFILE_PAGE} from 'constants/parent/navigation';
import {requestBooking as requestBookingAction} from 'actions/parent/booking';
import {setTitle as setTitleAction} from 'actions/navigation';
import PortraitFrame from 'components/PortraitFrame';
import {openModal as openModalAction} from 'actions/modal';
import VideoFeed from 'containers/VideoFeed';
import MapView from 'components/MapView';

export class InProgressBooking extends React.Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    bookingId: PropTypes.string.isRequired,
    inProgress: PropTypes.object,
    requestBooking: PropTypes.func.isRequired
  };

  componentDidMount () {
    track('In-Progress Booking Page');
    const {
      authToken,
      bookingId,
      requestBooking
    } = this.props;
    requestBooking(authToken, bookingId);
    this.props.setTitle('In-Progress Booking');
    this.timer = setInterval(() => {
      this.forceUpdate();
    }, 1000 * 1);
  }

  componentWillUnmount () {
    clearInterval(this.timer);
  }

  openVideoFeed () {
    const {sitter} = this.props.inProgress;
    this.props.openModal(VideoFeed, 'Video Feed', {sitter}, {});
  }

  render () {
    const {
      inProgress
    } = this.props;
    if (!inProgress) {
      return (
        <h1>{'Loading...'}</h1>
      );
    }
    const {
      sitter,
      // location
      payment_method, // eslint-disable-line camelcase
      start_time, // eslint-disable-line camelcase
      // sitter_geolocation, // eslint-disable-line camelcase
      end_time // eslint-disable-line camelcase
    } = inProgress;
    const {
      ChildInfant,
      Phone
    } = SVGIcon;
    const duration = moment.duration(moment(inProgress.end_time).diff(moment()));
    let timeLeft = `${duration.hours()}:${(`0${duration.minutes()}`).slice(-2)}:${(`0${duration.seconds()}`).slice(-2)}`;
    if (duration.asSeconds() < 0) {
      timeLeft = '0:00:00';
    }
    return ( /* eslint-disable camelcase */
      <div className="o-layout c-in-progress-booking">
        <div className="o-layout__item u-1/1 u-margin-bottom c-sitter-location-container">
          <MapView
              location={inProgress.location}
              openMapPage={() => {}}
          />
        </div>
        <div className="o-layout__item u-1/1 u-1/2@tablet u-push-1/4@tablet">
          <div className="o-layout">
            <div className="o-layout__item u-1/1 u-margin-bottom">
              <div className="o-media">
                <div className="o-media__img">
                  <Link to={`/${PROFILE_PAGE}/${sitter.id}`}>
                    <PortraitFrame src={sitter.photo_url} />
                  </Link>
                </div>
                <div className="o-media__body c-split-info">
                  <span className="c-split-info__left">
                    <h1 className="u-margin-top-small u-margin-top-tiny@tablet">
                      <Link
                          className="c-link"
                          to={`/${PROFILE_PAGE}/${sitter.id}`}
                      >{sitter.first_name}</Link>
                    </h1>
                  </span>{' '}<span className="c-split-info__right u-margin-top-small">
                    <a
                        className="c-h1"
                        href={`tel:${sitter.phone_number}`}
                    ><Phone active /></a>
                  </span>
                </div>
              </div>
            </div>
            { sitter.broadcast_url === true ? (
              <div className="o-layout__item u-1/1">
                <p className="c-split-info"><span className="c-split-info__left">{'Video feed'}</span> <span className="c-split-info__right">
                  <button
                      className="c-link"
                      onClick={() => this.openVideoFeed()}
                      type="button"
                  >{'View'}</button>
                </span></p>
              </div>
            ) : null }
            <div className="o-layout__item u-1/1 c-success-text">
              <p className="c-split-info"><span className="c-split-info__left">{'Time left'}</span> <span className="c-split-info__right">{timeLeft}</span></p>
            </div>
            <div className="o-layout__item u-1/1">
              <p className="c-split-info"><span className="c-split-info__left">{'Start time'}</span> <span className="c-split-info__right c-primary-text">{timeFormats.weekDateAt(moment(start_time))}</span></p>
            </div>
            <div className="o-layout__item u-1/1">
              <p className="c-split-info"><span className="c-split-info__left">{'End time'}</span> <span className="c-split-info__right c-primary-text">{timeFormats.weekDateAt(moment(end_time))}</span></p>
            </div>
            <div className="o-layout__item u-1/1">
              <p className="c-split-info"><span className="c-split-info__left">{'Children'}</span> <span className="c-split-info__right c-primary-text"><ChildInfant active /> {inProgress.child_profiles.map(({first_name}) => first_name).join(', ')}</span></p>
            </div>
            <div className="o-layout__item u-1/1">
              <p className="c-split-info"><span className="c-split-info__left">{'Payment method'}</span> <span className="c-split-info__right c-primary-text">{`**** ${payment_method.last_4}`}</span></p>
            </div>
          </div>
        </div>
      </div>
    ); /* eslint-enable camelcase */
  }
}

function mapStateToProps (state, ownProps) {
  const {
    params: {
      bookingId
    }
  } = ownProps;
  const {
    session: {
      authToken
    },
    bookings: {
      inProgress
    }
  } = state;
  return {
    authToken,
    bookingId,
    inProgress
  };
}

const mapDispatchToProps = {
  setTitle: setTitleAction,
  requestBooking: requestBookingAction,
  openModal: openModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(InProgressBooking);
