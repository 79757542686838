import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import {track} from 'utils/track';
import FormButton from 'forms/controls/FormButton';
import ChildrenListFields from 'forms/fields/ChildrenList';
import {
  validateChildren,
  validateFieldArray,
  validateFields
} from 'utils/formValidations';

export class ChildrenComponent extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired
  };

  componentDidMount () {
    track('Complete Registration Page - Children');
  }

  render () {
    const {
      handleSubmit,
      isLoading
    } = this.props;
    return (
      <div>
        <h1 className="c-h1 u-center-text c-search-header">{'Tell us about your children'}</h1>
        <p className="u-center-text">{'This allows us to find the best provider for you and your children'}</p>
        <form onSubmit={handleSubmit}>
          <ChildrenListFields />
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                disabled={isLoading}
                label="Next"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'children',
  validate: validateFields({
    children: validateFieldArray(validateChildren)
  }, {
    children: children => {
      if (!children || !children.length) {
        return {_error: 'At least one child is required'};
      }
    }
  })
})(ChildrenComponent);
