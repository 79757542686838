import React from 'react';
import moment from 'moment';
import {
  Field,
  FieldArray,
  FormSection
} from 'redux-form';

import * as SVGIcon from 'components/SVGIcon';
import {
  validateChildren,
  validateFieldArray
} from 'utils/formValidations';
import FormButton from 'forms/controls/FormButton';
import FormListItem from 'forms/controls/FormListItem';
import FormRadio from 'forms/controls/FormRadio';
import FormField from 'forms/controls/FormField';
import TargetDateControl from 'forms/controls/TargetDate';

export default class ChildrenList extends React.Component {
  constructor (props) {
    super(props);
    this.buildChildren = this.renderChildren();
    this.validateChildren = validateFieldArray(validateChildren);
  }

  renderChildren () {
    return ({fields, meta}) => {
      return (
        <div className="c-widget-list">
          {fields.map((member, key) => (
            <div key={member}>
              <ChildrenListItem
                  index={key}
                  {...{fields, member, meta}}
              />
            </div>
          ))}
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="+ Add a child"
                onClick={() => {
                  fields.push({
                    birthday: moment().subtract(1, 'days').format('YYYY-MM-DD')
                  });
                }}
                type="button"
            />
          </div>
        </div>
      );
    };
  }

  render () {
    return (
      <FieldArray
          component={this.buildChildren}
          name="children"
          warn={this.validateChildren}
      />
    );
  }
}

export class ChildrenListItem extends FormListItem { // eslint-disable-line react/no-multi-comp
  buildFormFields (member) {
    return (
      <FormSection name={member}>
        <Field
            component={FormRadio}
            label="Type"
            name="gender"
            options={[
              {
                classes: 'u-1/3',
                icon: 'GenderFemale',
                label: 'Girl',
                value: 'girl'
              }, {
                classes: 'u-1/3',
                icon: 'GenderMale',
                label: 'Boy',
                value: 'boy'
              }, {
                classes: 'u-1/3',
                icon: 'GenderUnspecified',
                label: 'Unspecified',
                value: 'unspecified'
              }
            ]}
        />
        <FormField
            fields={[
              {
                name: 'name',
                placeholder: 'First Name',
                type: 'text'
              }
            ]}
            icon="Person"
        />
        <Field
            component={TargetDateControl}
            format={value => moment(value, 'YYYY-MM-DD').toDate()}
            max={moment().subtract(1, 'days').toDate()}
            name="birthday"
            parse={value => moment(value).format('YYYY-MM-DD')}
        />
        <FormField
            fields={[
              {
                name: 'note',
                placeholder: 'Anything else we should be aware of?',
                type: 'text'
              }
            ]}
            icon="Notes"
        />
      </FormSection>
    );
  }

  renderUpdate (fields, member, key/* , meta */) {
    return (
      <div>
        {this.buildFormFields(member)}
        <div className="o-layout">
          <div className="o-layout__item u-1/2@tablet">
            <FormButton
                className="c-form-button--destructive c-form-button--block"
                label="Remove"
                onClick={() => fields.remove(key)}
                type="button"
            />
          </div>
          <div className="o-layout__item u-1/2@tablet">
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="Done"
                onClick={() => {
                  this.setState({isOpen: false}); // eslint-disable-line react/no-set-state
                }}
                type="button"
            />
          </div>
        </div>
      </div>
    );
  }

  renderRead (fields, member, key/* , meta */) {
    const child = fields.get(`${key}`);
    let Icon = SVGIcon.ChildInfant;
    const ageMonths = Math.abs(moment(child.birthday, 'YYYY-MM-DD').diff(moment(), 'months'));
    if (ageMonths > 12) {
      Icon = SVGIcon.ChildToddler;
    }
    if (ageMonths > 36) {
      Icon = SVGIcon.ChildPreschooler;
    }
    if (ageMonths > 60) {
      Icon = SVGIcon.ChildGradeschooler;
    }
    return (
      <div
          className="o-media c-widget-item__read-item"
          onClick={() => {
            this.setState({isOpen: true}); // eslint-disable-line react/no-set-state
          }}
      >
        <div className="o-media__img c-widget-item__img c-child-icon">
          <Icon active />
        </div>
        <div className="o-media__body">
          <h4 className="c-h4 u-margin-bottom-none">{child.name} <small className="c-child-item__gender">{child.gender}</small></h4>
          {child.note && (<p className="u-margin-bottom-none">{child.note}</p>)}
        </div>
      </div>
    );
  }

  renderCreate (fields, member, key, meta) {
    return (
      <div>
        {this.buildFormFields(member)}
        <div className="o-layout">
          <div className="o-layout__item u-1/2@tablet">
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="Cancel"
                onClick={() => fields.remove(key)}
                type="button"
            />
          </div>
          <div className="o-layout__item u-1/2@tablet">
            <FormButton
                className="c-form-button--primary c-form-button--block"
                disabled={meta.warning && !!meta.warning[key]}
                label="Done"
                onClick={() => {
                  this.setState({isNew: false, isOpen: false}); // eslint-disable-line react/no-set-state
                }}
                type="button"
            />
          </div>
        </div>
      </div>
    );
  }
}
