import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';

import ChildrenForm from 'forms/Children';
import CompleteProfileForm from 'forms/CompleteProfile';
import HomeAddress from 'forms/HomeAddress';
import PaymentForm from 'forms/Payment';
import {HOME_PAGE} from 'constants/parent/navigation';
import {setTitle as setTitleAction} from 'actions/navigation';
import {DEFAULT_PARENT_PHOTO_URL} from 'constants/settings';
import {
  CHILD_PROFILE_STEP,
  COMPLETE_PROFILE_STEP,
  LOCATION_STEP,
  PAYMENT_STEP,
  PAYMENT_SUBMIT_SUCCESS,
  ZIP_STEP
} from 'constants/parent/signUpFlow';
import {
  createChildrenProfiles as createChildrenProfilesAction,
  requestBraintreeToken as requestBraintreeTokenAction,
  setHomeAddress as setHomeAddressAction,
  setProfileForm as setProfileFormAction,
  submitProfile as submitProfileAction,
  tokenizeCard as tokenizeCardAction
} from 'actions/parent/signUp';

export class CompleteRegistration extends React.Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    routing: PropTypes.object.isRequired,
    createChildrenProfiles: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    setHomeAddress: PropTypes.func.isRequired,
    setProfileForm: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    submitProfile: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    tokenizeCard: PropTypes.func.isRequired
  };

  buildProfileStep ({user}, isLoading) {
    const {setProfileForm} = this.props;
    const initialValues = {
      profile: {
        firstLastName: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phoneNumber: user.phone_number,
        acceptedTerms: true
      }
    };
    return (
      <CompleteProfileForm
          onSubmit={({profile}) => {
            const {authToken} = this.props;
            const {
              email,
              firstLastName,
              password,
              phoneNumber
            } = profile;
            this.props.submitProfile(
              authToken,
              firstLastName,
              email,
              phoneNumber,
              password,
              user.photo_url || DEFAULT_PARENT_PHOTO_URL);
          }}
          {...{user, setProfileForm, isLoading, initialValues}}
      />
    );
  }

  buildChildProfileStep (isLoading) {
    const initialValues = {
      children: [
        {
          birthday: moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
      ]
    };
    return (
      <ChildrenForm
          {...{isLoading, initialValues}}
          onSubmit={values => {
            const formattedChildren = values.children.map(child => {
              const [last, ...rest] = child.name.split(' ').reverse();
              const [firstName, lastName] = rest.length ? [rest.reverse().join(' '), last] : [last];
              const formattedChild = {
                ...child,
                gender: child.gender === 'unspecified' ? null : child.gender,
                first_name: firstName, // eslint-disable-line camelcase
                last_name: lastName, // eslint-disable-line camelcase
                date_of_birth: child.birthday // eslint-disable-line camelcase
              };
              delete formattedChild.birthday;
              delete formattedChild.name;
              return formattedChild;
            });
            this.props.createChildrenProfiles(this.props.authToken, formattedChildren);
          }}
      />
    );
  }

  buildLocationStep (isLoading) {
    const initialValues = {
      homeAddress: {
        address: JSON.stringify({})
      }
    };
    return (
      <HomeAddress
          onSubmit={({homeAddress}) => {
            this.props.setHomeAddress(this.props.authToken, JSON.parse(homeAddress.address));
          }}
          {...{isLoading, initialValues}}
      />
    );
  }

  buildPaymentStep (isLoading) {
    const initialValues = {
      paymentMethods: [{}]
    };
    const {
      routing: {
        locationBeforeTransitions
      }
    } = this.props;
    return (
      <PaymentForm
          {...{isLoading, initialValues}}
          onPaymentInitialize={() => {
            this.props.requestBraintreeToken(this.props.authToken);
          }}
          onSubmit={() => {
            this.props.tokenizeCard(true, action => {
              if (action.type === PAYMENT_SUBMIT_SUCCESS) {
                if (locationBeforeTransitions && locationBeforeTransitions.state && locationBeforeTransitions.state.nextPathname) {
                  browserHistory.push(locationBeforeTransitions.state.nextPathname);
                } else {
                  browserHistory.push(`/${HOME_PAGE}`);
                }
              }
            });
          }}
      />
    );
  }

  currentFormStep (currentStep, session, isLoading) {
    switch (currentStep) {
      case COMPLETE_PROFILE_STEP:
        setTimeout(() => {
          this.props.setTitle('Complete Your Profile');
        }, 0);
        return this.buildProfileStep(session, isLoading);
      case CHILD_PROFILE_STEP:
        setTimeout(() => {
          this.props.setTitle('Your Children');
        }, 0);
        return this.buildChildProfileStep(isLoading);
      case LOCATION_STEP:
        setTimeout(() => {
          this.props.setTitle('Home Address');
        }, 0);
        return this.buildLocationStep(isLoading);
      case PAYMENT_STEP:
        setTimeout(() => {
          this.props.setTitle('Payment Method');
        }, 0);
        return this.buildPaymentStep(isLoading);
      default:
        return (
          <div>
            <h1>{'Missing Page!'}</h1>
            <p>{'If you\'re seeing this, consider it a special message just for you'}</p>
          </div>
        );
    }
  }

  render () {
    const {
      params,
      session
    } = this.props;
    const formStep = params.currentStep || ZIP_STEP;
    const isLoading = false;
    if (session.user) {
      return (
        <div className="o-layout">
          <div className="o-layout__item u-1/1 u-1/2@desktop u-push-1/4@desktop">
            {this.currentFormStep(formStep, session, isLoading)}
          </div>
        </div>
      );
    } else {
      return ( // refactor, not a good way to handle the laoding case
        <h1>{'Loading...'}</h1>
      );
    }
  }
}

function mapStateToProps (state /* , ownProps */) {
  const {
    session,
    settings,
    form,
    routing
  } = state;
  const {authToken} = session;
  return {
    routing,
    authToken,
    form,
    session,
    settings
  };
}

const mapDispatchToProps = {
  createChildrenProfiles: createChildrenProfilesAction,
  requestBraintreeToken: requestBraintreeTokenAction,
  setHomeAddress: setHomeAddressAction,
  setProfileForm: setProfileFormAction,
  submitProfile: submitProfileAction,
  tokenizeCard: tokenizeCardAction,
  setTitle: setTitleAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteRegistration);
