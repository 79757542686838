import {
  put,
  takeEvery
} from 'redux-saga/effects';
import {loadProfile} from 'actions/profile';
import {loadConstants} from 'actions/settings';
import {
  HYDRO,
  SET_AUTH_TOKEN
} from 'constants/session';

export function *hydrate () {
  yield takeEvery(HYDRO, hydrationEffect);
}

export function *hydrationEffect (action) {
  const {
    authToken
  } = action;

  if (authToken) {
    yield put({type: SET_AUTH_TOKEN, authToken});
    yield put(loadConstants(authToken));
    yield put(loadProfile(authToken));
  } else {
    yield put(loadConstants());
  }

}
