import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm
} from 'redux-form';
import moment from 'moment';

import FormCheckbox from 'forms/controls/FormCheckbox';
import PortraitFrame from 'components/PortraitFrame';
import * as timeFormats from 'utils/timeFormats';
import FormField from 'forms/controls/FormField';
import FormFieldPartial from 'components/partials/FormField';
import PaymentSelect from 'forms/fields/PaymentSelect';

export class BookingConfirmationComponent extends React.Component {
  static propTypes = {
    childProfiles: PropTypes.array.isRequired,
    constants: PropTypes.object.isRequired,
    showPricingBreakdown: PropTypes.func.isRequired,
    estimate: PropTypes.object,
    hasDiscountCode: PropTypes.func.isRequired,
    bookingRequest: PropTypes.bool.isRequired,
    priceEstimate: PropTypes.number,
    maxChildren: PropTypes.number.isRequired,
    query: PropTypes.object.isRequired,
    sitter: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  state = {
    isDiscountOpen: false
  };

  buildBookingDetails (query) {
    return (
      <div>
        <FormFieldPartial icon="Pin">{query.streetAddress}</FormFieldPartial>
        <FormFieldPartial icon="Calendar">{timeFormats.fullMonthDate(moment(query.startTime))}</FormFieldPartial>
        <FormFieldPartial icon="Clock">{timeFormats.basicTime(moment(query.startTime))} {'\u2192'} {timeFormats.basicTime(moment(query.endTime))}</FormFieldPartial>
      </div>
    );
  }

  buildPets ({input}) {
    const petLabels = {
      has_cats: 'Cats', // eslint-disable-line camelcase
      has_dogs: 'Dogs', // eslint-disable-line camelcase
      has_other: 'Other' // eslint-disable-line camelcase
    };
    return (
      <div className="o-layout">
        {input.value.map((petType, key) => (
          <div
              className="o-layout__item u-1/3 u-truncate-text"
              {...{key}}
          >{petLabels[petType]}</div>
        ))}
        {input.value.length === 0 && (<div className="o-layout__item">{'No pets'}</div>)}
      </div>
    );
  }

  buildChildren (childProfiles) {
    return props => (
      <div className="o-layout">
        {childProfiles.filter(({id}) => props.input.value.indexOf(`${id}`) > -1).map((child, key) => {
          return (
            <div
                className="o-layout__item u-1/3 u-truncate-text"
                {...{key}}
            >{child.first_name}</div>
          );
        })}
      </div>
    );
  }

  showPricingBreakdown = () => {
    this.props.showPricingBreakdown(this.props.estimate);
  }

  render () {
    const {
      constants,
      childProfiles,
      bookingRequest,
      priceEstimate,
      query,
      sitter,
      user
    } = this.props;
    const childrenOptions = childProfiles.map(({id, first_name}) => ({ // eslint-disable-line camelcase
      classes: 'u-1/3',
      label: first_name, // eslint-disable-line camelcase
      value: '' + id
    }));
    const petOptions = [ // refactor, extract pet control/field
      {
        classes: 'u-1/3',
        icon: 'PetDog',
        label: 'Dogs',
        value: 'has_dogs',
      }, {
        classes: 'u-1/3',
        icon: 'PetCat',
        label: 'Cats',
        value: 'has_cats'
      }, {
        classes: 'u-1/3',
        icon: 'PetBird',
        label: 'Other',
        value: 'has_other'
      }
    ];
    return ( /* eslint-disable camelcase */
      <div className="o-layout">
        <div className="o-layout__item">
          <div className="o-media u-margin-bottom">
            <div className="o-media__img">
              <PortraitFrame
                  classes={['c-portrait-frame--medium c-provider-review__portrait']}
                  src={sitter.photo_url || ''}
              />
            </div>
            <div className="o-media__body">
              <h1 className="c-h1 c-portrait-title">{sitter.first_name}</h1>
            </div>
          </div>
          <form onSubmit={this.props.handleSubmit}>
            {this.buildBookingDetails(query)}
            <h4 className="c-h4 c-booking-confirmation__header">{'Children'}</h4>
            <div className="u-clearfix">
              <div className="c-form-section">
                <Field
                    bordered
                    component={FormCheckbox}
                    label="Select Children"
                    limit={constants.booking.max_children}
                    name="childProfiles"
                    options={childrenOptions}
                />
              </div>
            </div>
            <h4 className="c-h4 c-booking-confirmation__header">{'Pets'}</h4>
            <Field
                bordered
                component={FormCheckbox}
                label="Select Pets"
                name="pets"
                options={petOptions}
            />
            <h4 className="c-h4 c-booking-confirmation__header">{'Payment'}</h4>
            <PaymentSelect creditCards={[user.default_payment_method]} />
            <FormField
                fields={[
                  {
                    name: 'notes',
                    placeholder: 'Anything else we should be aware of?',
                    type: 'text'
                  }
                ]}
                icon="Notes"
            />
            <p
                className="u-margin-top u-center-text c-text-strong c-pricing-breakdown"
                onClick={this.showPricingBreakdown}
            >{`Estimated price ${Number(priceEstimate).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}`} <span className="c-icon-help">{'?'}</span></p>
            <div className="u-margin-top u-margin-bottom">
              <button
                  className="c-form-button c-form-button--block c-form-button--primary"
                  disabled={bookingRequest}
                  type="submit"
              >{bookingRequest ? 'Booking...' : `Book ${sitter.first_name}`}</button>
            </div>
            <div className="u-center-text u-margin-bottom">
              <button
                  className="c-link"
                  onClick={() => {
                    this.props.hasDiscountCode();
                  }}
                  type="button"
              >{'Have a discount code?'}</button>
            </div>
          </form>
        </div>
      </div>
    ); /* eslint-enable camelcase */
  }
}

function validate (values) {
  const errors = {};
  if (!values.childProfiles || !values.childProfiles.length) {
    errors.childProfiles = 'Required';
  }
  return errors;
}

export default reduxForm({
  enableReinitialize: true,
  form: 'bookingConfirmation',
  validate
})(BookingConfirmationComponent);
