import {
  CONSTANTS_SUCCESS,
  LOAD_NOTIFICATION_SETTINGS_SUCCESS
} from 'constants/settings';

import {
  PAYMENT_SUCCESS,
} from 'constants/parent/signUpFlow';

export function getDefaultState () {
  return {
    notificationSettings: {},
    copy: {
      en: {
        badges: []
      }
    },
    copyLoaded: false,
    constants: {},
    constantsLoaded: false,
    payments: []
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === LOAD_NOTIFICATION_SETTINGS_SUCCESS) {
    return {
      ...state,
      notificationSettings: action.response.result
    };
  }
  if (action.type === CONSTANTS_SUCCESS) {
    return {
      ...state,
      constants: action.response.entities.settings[action.response.entities.result].constants,
      copy: action.response.entities.settings[action.response.entities.result].copy,
      constantsLoaded: true
    };
  }
  if (action.type === PAYMENT_SUCCESS) {
    return {
      ...state,
      payments: action.response.result.payment_methods
    };
  }
  return state;
}
