import React from 'react';
import {Field} from 'redux-form';

import AddressControl from 'forms/controls/Address';
import {
  formatters,
  parsers
} from 'forms';

export default class HomeAddressField extends React.Component {
  render () {
    return (
      <div>
        <Field
            component={AddressControl}
            format={formatters.JSON}
            name="address"
            parse={parsers.JSON}
        />
      </div>
    );
  }
}
