import {TOGGLE_SEARCH_COLLAPSE} from 'constants/parent/ui';

export function getDefaultState () {
  return {
    searchCollapsed: false
  };
}

export default function (state = getDefaultState(), action) {
  if (action.type === TOGGLE_SEARCH_COLLAPSE) {
    const {flag} = action;
    return {
      ...state,
      searchCollapsed: flag === undefined ? !state.searchCollapsed : flag
    };
  }
  return state;
}
