import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Field,
  Fields
} from 'redux-form';

import TargetDateControl from 'forms/controls/TargetDate';
import TimeRangeControl from 'forms/controls/TimeRange';
import DeletableField from 'components/DeletableField';

export default class ScheduleFields extends React.Component {
  static propTypes = {
    noRemove: PropTypes.bool.isRequired,
    timeRangeActive: PropTypes.bool.isRequired,
    targetDateActive: PropTypes.bool.isRequired,
    constants: PropTypes.object.isRequired,
    error: PropTypes.string,
    onFormChange: PropTypes.func.isRequired,
    min: PropTypes.string,
    max: PropTypes.string
  };

  render () {
    const {
      constants,
      min,
      max,
      error,
      onFormChange
    } = this.props;
    return (
      <div>
        <DeletableField
            enabled={this.props.targetDateActive}
            icon="Calendar"
            label="Any Date"
            name="targetDateActive"
            noRemove={this.props.noRemove}
            {...{onFormChange}}
        >
          <Field
              component={TargetDateControl}
              error={error && ' '}
              format={value => moment(value).toDate()}
              max={max ? moment(max).toDate() : (moment(new Date()).add(constants.booking.max_days_ahead, 'days').toDate())}
              min={min ? moment(min).toDate() : new Date()}
              name="targetDate"
              parse={value => moment(value).toISOString()}
          />
        </DeletableField>
        <DeletableField
            enabled={this.props.timeRangeActive}
            icon="Clock"
            label="Any Time"
            name="timeRangeActive"
            noRemove={this.props.noRemove}
            {...{onFormChange}}
        >
          <Fields
              component={TimeRangeControl}
              names={['startTime', 'endTime', 'targetDate']}
              {...{onFormChange, error, min, max}}
          />
        </DeletableField>
      </div>
    );
  }
}
