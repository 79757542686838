import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import FormButton from 'forms/controls/FormButton';
import LoginFields from 'forms/fields/Login';
import {
  validateFields,
  validateLogin
} from 'utils/formValidations';

export class LoginComponent extends React.Component {
  static propTypes = {
    loginLoading: PropTypes.bool.isRequired,
    onFacebookClick: PropTypes.func
  };

  render () {
    const {
      onFacebookClick,
      handleSubmit,
      loginLoading
    } = this.props;
    return (
      <div>
        {!!onFacebookClick && (
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block c-form-button--facebook"
                disabled={loginLoading}
                icon="Facebook"
                label="Log in with Facebook"
                onClick={onFacebookClick}
                type="button"
            />
            <h2 className="c-text-small c-text-small--muted c-line-behind">{'Or log in with email'}</h2>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <FormSection name="login">
            <LoginFields />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                disabled={loginLoading}
                label={loginLoading ? 'Logging in...' : 'Log in'}
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'login',
  validate: validateFields({
    login: validateLogin
  })
})(LoginComponent);
