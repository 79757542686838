import {REQUEST_BOOKING_PRICE} from 'constants/parent/booking';
import {browserHistory} from 'react-router';
import {
  takeEvery,
  call,
  put
} from 'redux-saga/effects';

import {HOME_PAGE} from 'constants/parent/navigation';
import {SEARCH_SUBMIT} from 'constants/parent/search';
import {getPriceQuote} from 'actions/parent/booking';

export function *searchSubmit () {
  yield takeEvery(SEARCH_SUBMIT, searchSubmitEffect);
}

export function *searchSubmitEffect ({query}) {
  yield call(browserHistory.push, `/${HOME_PAGE}?${query}`);
}

export function *priceEstimate () {
  yield takeEvery(REQUEST_BOOKING_PRICE, priceEstimateEffect);
}

export function *priceEstimateEffect (action) {
  const {
    timeRange,
    authToken,
    childProfiles,
    defaultPaymentMethod,
    searchId,
    sitterId
  } = action;
  yield put(getPriceQuote(authToken, searchId, sitterId, defaultPaymentMethod, childProfiles, timeRange));
}
