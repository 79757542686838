import {CALL_API} from 'middleware/api';
import {user as userSchema} from 'schemas/user';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
} from 'constants/login';

const scope = process.env.APP_ROLE === 'sitter' ? 'sitter' : 'parent';

export function login (email, password) {
  return {
    [CALL_API]: {
      endpoint: `${scope}/registration`,
      headers: {
        'X-Identity-Email': email,
        'X-Identity-Password': password
      },
      method: 'GET',
      schema: userSchema,
      types: [
        LOGIN_REQUEST,
        LOGIN_SUCCESS,
        LOGIN_FAILURE
      ]
    }
  };
}
