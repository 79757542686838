import React from 'react';
import {
  IndexRedirect,
  Redirect,
  Route
} from 'react-router';

import Messages from 'pages/Messages';
import Conversation from 'pages/Conversation';
import PasswordChange from 'containers/PasswordChange';
import PasswordReset from 'containers/PasswordReset';
import App from 'containers/parent/App';
import BookingConfirmation from 'containers/parent/BookingConfirmation';
import Bookings from 'containers/parent/Bookings';
import UpcomingBookings from 'containers/parent/UpcomingBookings';
import CompleteRegistration from 'containers/parent/CompleteRegistration';
import Favorites from 'containers/parent/Favorites';
import FavoritesBooking from 'containers/parent/FavoritesBooking';
import ParentHome from 'containers/parent/Home';
import InProgressBookings from 'containers/parent/InProgressBookings';
import LogOut from 'containers/LogOut';
import Login from 'containers/parent/Login';
import OpenSearches from 'containers/parent/OpenSearches';
import Profile from 'pages/parent/Profile';
import ProviderSchedule from 'containers/parent/ProviderSchedule';
import Referral from 'containers/parent/Referral';
import Settings from 'containers/parent/Settings';
import PurchaseCredits from 'containers/parent/PurchaseCredits';
import SignUp from 'containers/parent/SignUp';
import WaitListSuccess from 'containers/parent/WaitListSuccess';
import {
  PAYMENT_STEP,
  ZIP_STEP
} from 'constants/parent/signUpFlow';
import {
  SUGGESTION_BOOKING_PAGE,
  BOOKINGS_FUTURE_PAGE,
  BOOKINGS_PAST_PAGE,
  BOOKING_CONFIRMATION_PAGE,
  PURCHASE_CREDITS_PAGE,
  COMPLETE_REGISTRATION_PAGE,
  FAVORITES_PAGE,
  FAVORITE_BOOKING_PAGE,
  HOME_PAGE,
  IN_PROGRESS_BOOKING_PAGE,
  LOGIN_PAGE,
  LOGOUT_PAGE,
  OPEN_SEARCHES_PAGE,
  PROFILE_PAGE,
  PROVIDER_PAGE,
  PROFILE_SCHEDULE_PAGE,
  REFERRAL_PAGE,
  SETTINGS_PAGE,
  SIGN_UP_PAGE,
  WAIT_LIST_SUCCESS_PAGE,
  PASSWORD_RESET_PAGE,
  PASSWORD_CHANGE_PAGE,
  MESSAGES_PAGE,
  MESSAGE_PAGE,
  REDEEM_PAGE
} from 'constants/parent/navigation';

import {
  composeEnterHooksSeries,
  redirectToAuthenticatedView,
  redirectToLoginView,
  ensureBookingDetails
} from 'hooks';

export default function routes (store) { // eslint-disable-line react/no-multi-comp
  return (
    <Route
        component={App}
        path={'/'}
    >
      <Route
          component={LogOut}
          path={LOGOUT_PAGE}
      />
      <Route
          component={WaitListSuccess}
          path={WAIT_LIST_SUCCESS_PAGE}
      />
      <Route
          component={PasswordReset}
          path={`${PASSWORD_RESET_PAGE}`}
      />
      <Route
          component={PasswordChange}
          path={`${PASSWORD_CHANGE_PAGE}/:resetToken`}
      />
      <Route
          component={SignUp}
          onEnter={redirectToAuthenticatedView(store)}
          path={`${SIGN_UP_PAGE}/:currentStep/:zipCode`}
      />
      <Route
          component={SignUp}
          onEnter={redirectToAuthenticatedView(store)}
          path={`${SIGN_UP_PAGE}/:currentStep`}
      />
      <Redirect
          from={SIGN_UP_PAGE}
          to={`${SIGN_UP_PAGE}/${ZIP_STEP}`}
      />
      <Route
          component={Login}
          onEnter={redirectToAuthenticatedView(store)}
          path={LOGIN_PAGE}
      />
      <Route
          component={CompleteRegistration}
          onEnter={redirectToLoginView(store)}
          path={`${COMPLETE_REGISTRATION_PAGE}/:currentStep`}
      />
      <Route
          component={UpcomingBookings}
          onEnter={redirectToLoginView(store)}
          path={`${BOOKINGS_FUTURE_PAGE}`}
      />
      <Route
          component={Bookings}
          onEnter={redirectToLoginView(store)}
          path={`${BOOKINGS_PAST_PAGE}`}
      />
      <Route
          component={InProgressBookings}
          onEnter={composeEnterHooksSeries(redirectToLoginView(store), ensureBookingDetails(store))}
          path={`${IN_PROGRESS_BOOKING_PAGE}/:bookingId`}
      />
      <Route
          component={Favorites}
          onEnter={redirectToLoginView(store)}
          path={`${FAVORITES_PAGE}`}
      />
      <Route
          component={Conversation}
          onEnter={redirectToLoginView(store)}
          path={`${MESSAGE_PAGE}/:otherId`}
      />
      <Route
          component={Messages}
          onEnter={redirectToLoginView(store)}
          path={`${MESSAGES_PAGE}`}
      />
      <Route
          component={FavoritesBooking}
          onEnter={redirectToLoginView(store)}
          path={`${SUGGESTION_BOOKING_PAGE}`}
      />
      <Route
          component={FavoritesBooking}
          onEnter={redirectToLoginView(store)}
          path={`${FAVORITE_BOOKING_PAGE}`}
      />
      <Route
          component={Referral}
          onEnter={redirectToLoginView(store)}
          path={`${REFERRAL_PAGE}`}
      />
      <Redirect
          from={`${REDEEM_PAGE}/:code`}
          to={`${SETTINGS_PAGE}/:code`}
      />
      <Redirect
          from={`${REDEEM_PAGE}/:code/:apply`}
          to={`${SETTINGS_PAGE}/:code/:apply`}
      />
      <Route
          component={Settings}
          onEnter={redirectToLoginView(store)}
          path={`${SETTINGS_PAGE}`}
      />
      <Route
          component={Settings}
          onEnter={redirectToLoginView(store)}
          path={`${SETTINGS_PAGE}/:code`}
      />
      <Route
          component={Settings}
          onEnter={redirectToLoginView(store)}
          path={`${SETTINGS_PAGE}/:code/:apply`}
      />
      <Route
          component={PurchaseCredits}
          onEnter={redirectToLoginView(store)}
          path={`${PURCHASE_CREDITS_PAGE}`}
      />
      <Route
          component={BookingConfirmation}
          onEnter={composeEnterHooksSeries(redirectToLoginView(store), redirectToPaymentView(store))}
          path={`${BOOKING_CONFIRMATION_PAGE}/:id`}
      />
      <Route
          component={ProviderSchedule}
          onEnter={redirectToLoginView(store)}
          path={`${PROVIDER_PAGE}/:id/${PROFILE_SCHEDULE_PAGE}`}
      />
      <Route
          component={Profile}
          path={`${PROVIDER_PAGE}/:id`}
      />
      <Route
          component={ProviderSchedule}
          onEnter={redirectToLoginView(store)}
          path={`${PROFILE_PAGE}/:id/${PROFILE_SCHEDULE_PAGE}`}
      />
      <Route
          component={Profile}
          path={`${PROFILE_PAGE}/:id`}
      />
      <Route
          component={OpenSearches}
          onEnter={redirectToLoginView(store)}
          path={OPEN_SEARCHES_PAGE}
      />
      <Redirect
          from={PROFILE_PAGE}
          to={HOME_PAGE}
      />
      <Redirect
          from={PROVIDER_PAGE}
          to={HOME_PAGE}
      />
      <Route
          component={ParentHome}
          onEnter={redirectToLoginView(store)}
          path={HOME_PAGE}
      />
      <IndexRedirect to={`${SIGN_UP_PAGE}/${ZIP_STEP}`} />
      <Redirect
          from="*"
          to={HOME_PAGE}
      />
    </Route>
  );
}

export function redirectToPaymentView (store) {
  return (nextState, replace, next) => {
    const state = store.getState();
    const {
      session: {
        user,
        isAuthenticated
      }
    } = state;
    if (isAuthenticated) {
      if (!user || !user.status.has_payment_method) { // eslint-disable-line camelcase
        replace({
          pathname: `/${COMPLETE_REGISTRATION_PAGE}/${PAYMENT_STEP}`,
          state: {nextPathname: `${nextState.location.pathname}${nextState.location.search}`}
        });
      }
    }
    next();
  };
}
