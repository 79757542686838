import React from 'react';
import PropTypes from 'prop-types';

import StarRatingComponent from 'components/StarRating';

export default class StarRating extends React.Component {
  static propTypes = {
    size: PropTypes.string
  };

  render () {
    const {
      size,
      input: {
        value,
        onChange
      }
    } = this.props;
    return (
      <StarRatingComponent
          count={5}
          width={(value / 5) * 100}
          {...{size, onChange}}
      />
    );
  }
}
