import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import accounting from 'accounting';
import {
  stopSubmit as stopSubmitAction,
  change as changeFormAction,
  change as formChangeAction // wat?
} from 'redux-form';

import {
  applyDiscount as applyDiscountAction,
} from 'actions/parent/booking';
import DiscountCodeForm from 'forms/DiscountCode';
import {DISCOUNT_CODE_FAILURE} from 'constants/parent/booking';
import FormButton from 'forms/controls/FormButton';
import {
  getAuthToken,
  getSession,
} from 'selectors/session';
import {getNotificationSettings} from 'selectors/settings';
import {track} from 'utils/track';
import SettingsForm from 'forms/parent/Settings';
import {setTitle as setTitleAction} from 'actions/navigation';
import {
  updateProfile as updateProfileAction,
} from 'actions/profile';
import {
  loadProfile as loadProfileAction
} from 'actions/profile';
import {
  addChildrenProfiles as addChildrenProfilesAction,
  updateHomeAddress as updateHomeAddressAction,
  requestBraintreeToken as requestBraintreeTokenAction,
  tokenizeCard as tokenizeCardAction,
  requestPayments as requestPaymentsAction,
  deletePayments as deletePaymentsAction,
  updatePayments as updatePaymentsAction
} from 'actions/parent/signUp';
import {
  loadNotificationSettings as loadNotificationSettingsAction,
  saveNotificationSettings as saveNotificationSettingsAction
} from 'actions/settings';
import {
  closeModal as closeModalAction,
  openModal as openModalAction
} from 'actions/modal';

export class Settings extends React.Component {
  static propTypes = {
    notificationSettings: PropTypes.object.isRequired,
    loadNotificationSettings: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    saveNotificationSettings: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    tokenizeCard: PropTypes.func.isRequired,
    addChildrenProfiles: PropTypes.func.isRequired,
    formChange: PropTypes.func.isRequired,
    session: PropTypes.object.isRequired,
    setTitle: PropTypes.func.isRequired,
    updateHomeAddress: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.handleSubmit = this.onSubmit();
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleDeletePayment = this.handleDeletePayment.bind(this);
    this.handleUpdatePayment = this.handleUpdatePayment.bind(this);
  }

  notificationSettings = [
    {
      title: 'SMS Notifications',
      key: 'sms'
    },
    {
      title: 'Email Notification',
      key: 'email'
    },
    {
      title: 'Favorite Provider Notifications',
      key: 'availabilityPush'
    }
  ];

  componentDidMount () {
    track('Parent Settings Page');
    this.props.setTitle('Settings');
    if (this.props.authToken) {
      this.props.loadNotificationSettings(this.props.authToken);
      this.props.requestPayments(this.props.authToken);
    }
    if (this.props.params && this.props.params.code) {
      setTimeout(this.handleOpenModal, 1000);
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.authToken && newProps.authToken !== this.props.authToken) {
      this.props.loadNotificationSettings(newProps.authToken);
      this.props.requestPayments(newProps.authToken);
    }
  }

  handleDeletePayment (id, callback) {
    this.props.deletePayments(this.props.authToken, id, callback);
  }

  handleUpdatePayment (id) {
    this.props.updatePayments(this.props.authToken, id);
    this.props.requestPayments(this.props.authToken);
  }

  handleContactChange (contactDetails, passwordChange) {
    const {
      session: {
        user
      }
    } = this.props;
    const {
      firstLastName,
      email,
      phoneNumber
    } = contactDetails;
    const [firstName, ...nameRest] = firstLastName.split(' ');
    const lastName = nameRest.join(' ');
    if (firstName !== user.first_name || lastName !== user.last_name || user.email !== email || phoneNumber !== user.phoneNumber || (passwordChange && passwordChange.password)) {
      this.props.updateProfile(this.props.authToken, {firstName, lastName, email, phoneNumber, password: (passwordChange && passwordChange.password)}); // refactor, get phone number too
      this.props.formChange('settings', 'passwordChange.password', '');
      this.props.formChange('settings', 'passwordChange.passwordConfirm', '');
    }
  }

  handleAddressChange ({address}) {
    const {
      session: {
        user: {
          default_location // eslint-disable-line camelcase
        }
      }
    } = this.props;
    const location = JSON.parse(address);
    if (
      location.city !== default_location.city || // eslint-disable-line camelcase
      location.lat !== default_location.geolocation.lat || // eslint-disable-line camelcase
      location.lng !== default_location.geolocation.lng || // eslint-disable-line camelcase
      location.state !== default_location.state || // eslint-disable-line camelcase
      location.zip !== default_location.zip_code // eslint-disable-line camelcase
    ) {
      this.props.updateHomeAddress(this.props.authToken, location);
    }
  }

  handleChildrenChange (children) {
    const formattedChildren = children.map(child => {
      const [last, ...rest] = child.name.split(' ').reverse();
      const [firstName, lastName] = rest.length ? [rest.reverse().join(' '), last] : [last];
      const formattedChild = {
        ...child,
        gender: child.gender === 'unspecified' ? null : child.gender,
        first_name: firstName, // eslint-disable-line camelcase
        last_name: lastName, // eslint-disable-line camelcase
        date_of_birth: child.birthday // eslint-disable-line camelcase
      };
      delete formattedChild.birthday;
      delete formattedChild.name;
      return formattedChild;
    });
    this.props.addChildrenProfiles(this.props.authToken, formattedChildren);
  }

  handlePaymentMethodsChange (paymentMethods) {
    if (this.props.session.user.default_payment_method && paymentMethods[0].cardNumber === this.props.session.user.default_payment_method.last_4) {
      return;
    }
  }

  handleNotificationPreferencesChange (notificationPreferences) {
    const invertedOptions = Object.keys(notificationPreferences).reduce((p, c) => ({...p, [c]: !notificationPreferences[c] ? 'yes' : 'no'}), {});
    const options = {
      opt_out_email: invertedOptions.email, // eslint-disable-line camelcase
      opt_out_push: invertedOptions.push, // eslint-disable-line camelcase
      opt_out_sms: invertedOptions.sms, // eslint-disable-line camelcase
      opt_out_availability_push: invertedOptions.availabilityPush // eslint-disable-line camelcase
    };
    this.props.saveNotificationSettings(this.props.authToken, options);
  }

  onSubmit () {
    return ({notificationPreferences, contactDetails, homeAddress, passwordChange, children}) => { // refactor, make sure to split the form values into their domains for API endpoint hits
      // this.handlePaymentMethodsChange(paymentMethods);
      this.handleChildrenChange(children);
      this.handleContactChange(contactDetails, passwordChange);
      this.handleAddressChange(homeAddress);
      this.handleNotificationPreferencesChange(notificationPreferences);
    };
  }

  handleOpenModal () {
    if (this.props.params.code) {
      this.props.changeForm('discountCode', 'discountCode.code', this.props.params.code);
    }
    this.props.openModal(DiscountCodeForm, 'Redeem Discount Code', {
      onSubmit: ({discountCode}) => {
        this.props.applyDiscount(this.props.authToken, discountCode.code).then(action => { // refactor, extract saga
          if (action.type === DISCOUNT_CODE_FAILURE) {
            let message = 'Invalid code';
            if (action.error && action.error.message && action.error.message.split(' ')[0] === 'is_redeemed') {
              message = 'This code has already been redeemed';
            }
            this.props.stopSubmit('discountCode', {discountCode: {code: message}});
          } else {
            // this.props.closeModal();
            this.props.changeForm('discountCode', 'discountCode.code', '');
            this.props.loadProfile(this.props.authToken);
            this.props.openModal(() => ( // refactor, extract this.  I got so lazy here...
              <div>
                <p className="u-center-text">{'Your credits have been applied!'}</p>
                <FormButton
                    className="c-form-button--primary c-form-button--block c-form-button--inverse"
                    label="Done"
                    onClick={() => this.props.closeModal()}
                    type="button"
                />
              </div>
            ), 'Discount code applied', {
            }, {
              'c-discount-code-modal': true
            });
          }
        });
      }
    }, {
      'c-discount-code-modal': true
    });
  }

  render () { // refactor, this was quick scaffolding
    const {user} = this.props.session;
    if (!user || !this.props.authToken) { // refactor, not the right way to handle loading
      return null;
    }
    const {
      location,
      email,
      firstName,
      lastName,
      childProfiles,
      phoneNumber,
    } = profileSelect(user);
    const payments = this.props.payments ? this.props.payments : [];
    const initialValues = {
      notificationPreferences: this.props.notificationSettings,
      homeAddress: {
        address: JSON.stringify({
          city: location.city,
          lat: location.latitude,
          lng: location.longitude,
          state: location.state,
          streetAddress: location.street,
          zip: location.zipCode
        })
      },
      children: childProfiles.map(({id, date_of_birth, first_name, gender, note}) => ({ // eslint-disable-line camelcase
        birthday: date_of_birth, // eslint-disable-line camelcase
        name: first_name, // eslint-disable-line camelcase
        gender: gender || 'unspecified', // refactor, will production data have `null` for child gender?
        note,
        id
      })),
      contactDetails: {
        email,
        firstLastName: `${firstName} ${lastName}`,
        phoneNumber: phoneNumber
      },
      paymentMethods: payments.map(({card_type, last_4, is_default, id}) => ({ // eslint-disable-line camelcase
        cardNumber: last_4, // eslint-disable-line camelcase
        cardType: card_type, // eslint-disable-line camelcase
        isDefault: is_default, // eslint-disable-line camelcase
        id
      }))
    };
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <SettingsForm
              closeModal={this.props.closeModal}
              handleDeletePayment={this.handleDeletePayment}
              onPaymentInitialize={() => {
                this.props.requestBraintreeToken(this.props.authToken);
              }}
              onSubmit={this.handleSubmit}
              openModal={this.props.openModal}
              payments={this.props.payments}
              settings={this.notificationSettings}
              tokenizeCard={this.props.tokenizeCard}
              updatePayments={this.handleUpdatePayment}
              {...{initialValues}}
          />
        </div>
        <div className="o-layout__item u-1/2">
          <h4 className="c-h4 u-center-text u-margin-top">{`Credit Balance: $${accounting.formatNumber(user.credits.credit_balance, 2)}`}</h4>
          <p className="u-center-text">
            <button
                className="c-link"
                onClick={this.handleOpenModal}
                type="button"
            >{'Have a discount code?'}</button>
          </p>
        </div>
      </div>
    );
  }
}

export function profileSelect (props) { // refactor, extract selector
  const { /* eslint-disable camelcase */
    child_profiles,
    default_payment_method,
    email,
    first_name,
    last_name,
    phone_number,
    default_location: {
      city,
      geolocation: {
        lat,
        lng
      },
      state,
      street,
      zip_code
    }
  } = props;
  return {
    childProfiles: child_profiles,
    payment: default_payment_method,
    email,
    firstName: first_name,
    lastName: last_name,
    phoneNumber: phone_number,
    location: {
      city,
      latitude: lat,
      longitude: lng,
      state,
      street,
      zipCode: zip_code
    },
  }; /* eslint-enable camelcase */
}

export function makeMapStateToProps () {
  return (state, ownProps) => {
    return {
      session: getSession(state, ownProps),
      authToken: getAuthToken(state, ownProps),
      notificationSettings: getNotificationSettings(state, ownProps),
      payments: state.settings.payments,
    };
  };
}

const mapDispatchToProps = {
  updatePayments: updatePaymentsAction,
  requestPayments: requestPaymentsAction,
  deletePayments: deletePaymentsAction,
  requestBraintreeToken: requestBraintreeTokenAction,
  tokenizeCard: tokenizeCardAction,
  addChildrenProfiles: addChildrenProfilesAction,
  applyDiscount: applyDiscountAction,
  updateProfile: updateProfileAction,
  updateHomeAddress: updateHomeAddressAction,
  setTitle: setTitleAction,
  loadNotificationSettings: loadNotificationSettingsAction,
  saveNotificationSettings: saveNotificationSettingsAction,
  openModal: openModalAction,
  stopSubmit: stopSubmitAction,
  closeModal: closeModalAction,
  formChange: formChangeAction,
  loadProfile: loadProfileAction,
  changeForm: changeFormAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Settings);
