import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as SVGIcon from 'components/SVGIcon';

export default class FormCheckbox extends React.PureComponent {
  static propTypes = {
    plain: PropTypes.bool,
    bordered: PropTypes.bool,
    limit: PropTypes.number,
    doFirstValidation: PropTypes.bool,
    error: PropTypes.string,
    hint: PropTypes.string,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.any.isRequired
    }).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      classes: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired
  };

  state = {
    limitReached: false
  };

  handleChange (changedValue, limit) {
    return () => {
      const output = [...this.props.input.value];
      const index = output.indexOf(changedValue);
      if (index > -1) {
        output.splice(index, 1);
      } else {
        output.push(changedValue);
      }
      this.setState({limitReached: output.length >= limit}); // eslint-disable-line react/no-set-state
      this.props.input.onChange(output);
      if (this.props.onChange) {
        this.props.onChange(output);
      }
    };
  }

  render () {
    const {limitReached} = this.state;
    const {
      bordered,
      doFirstValidation,
      error,
      hint,
      limit,
      input: {
        value,
        name
      },
      meta,
      options
    } = this.props;
    const message = ((meta.touched || doFirstValidation) && meta.error) || error || hint;
    const className = classNames({
      'c-form-checkbox': true,
      'c-form-checkbox--error': !!(((meta.touched || doFirstValidation) && meta.error) || error),
      'c-form-checkbox-bordered': bordered
    });
    return ( // refactor, this component is trying to look completely differently based on a single flag, this should be split
      <div {...{className}}>
        <div className={classNames({'c-form-checkbox--border': bordered})}>
          <div className="o-layout">
            {options.map((field, key) => {
              const Icon = SVGIcon[field.icon];
              const fieldClasses = field.classes || '';
              const valueFound = value.indexOf(field.value) > -1;
              const inputClassName = classNames({
                'c-form-checkbox__field': true,
                'u-hidden-visually': !this.props.plain
              });
              return (
                <div
                    className={`c-form-checkbox__item o-layout__item ${fieldClasses}`}
                    {...{key}}
                >
                  <input
                      checked={valueFound}
                      className={inputClassName}
                      disabled={limitReached && !valueFound}
                      id={`${name}-${key}`}
                      onChange={this.handleChange(field.value, limit)}
                      type="checkbox"
                      value={field.value}
                      {...{name}}
                  />
                  <label
                      className="c-form-checkbox__label"
                      htmlFor={`${name}-${key}`}
                  >
                    {field.icon && (<Icon active={value.indexOf(field.value) > -1} />)}
                    {field.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="o-layout">
          <div className="o-layout__item u-1/1 c-form-checkbox__message">
            <div className={classNames({'c-form-checkbox__hint': true, 'u-margin-left': bordered})}>{message}</div>
          </div>
        </div>
      </div>
    );
  }
}
