import React from 'react';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import FormField from 'forms/controls/FormField';
import FormButton from 'forms/controls/FormButton';
import {
  validateFields,
  validateDiscountCode
} from 'utils/formValidations';

export class DiscountCodeComponent extends React.Component {
  render () {
    const {
      handleSubmit,
      invalid
    } = this.props;
    return (
      <div className="u-margin-top">
        <form onSubmit={handleSubmit}>
          <FormSection name="discountCode">
            <FormField
                fields={[
                  {
                    name: 'code',
                    placeholder: 'Discount code',
                    type: 'text',
                    format: i => (i && ('' + i).toUpperCase()) || ''
                  }
                ]}
                icon="Notes"
            />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                disabled={invalid}
                label="Apply"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'discountCode',
  validate: validateFields({
    discountCode: validateDiscountCode
  })
})(DiscountCodeComponent);
