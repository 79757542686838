import {
  closeBookingConfirmation,
  bookingCreateFailure,
  unreviewedBookingList,
} from 'sagas/parent/booking'
import {logOut} from 'sagas/parent/logOut'
import {hudComplete} from 'sagas/hud'
import {
  sitterProfile,
  locationChange
} from 'sagas/parent/navigation'
import {
  priceEstimate,
  searchSubmit
} from 'sagas/parent/search'
import {
  profileSuccess,
  removeAuthToken,
  setAuthToken,
  passwordChangeSuccess,
  passwordChangeFailure,
  passwordResetSuccess,
  passwordResetFailure
} from 'sagas/session'
import {
  hydrate
} from 'sagas/hydrate'
import {
  paymentChangeFail,
  paymentFail,
  searchFail,
  allFail
} from 'sagas/parent/errors'
import {
  loginSuccess
} from 'sagas/parent/login'
import {
  auth as facebookAuth,
  getLoginStatus,
  login as facebookLogin,
  signUp as facebookSignUp,
  signUpSuccess as facebookSignUpSuccess
} from 'sagas/facebook'
import {
  handleBraintreeToken,
  moveToChildProfilesStep,
  moveToCompleteProfileStep,
  moveToHomePage,
  moveToLocationStep,
  // moveToPaymentStep,
  moveToRegisterStep,
  moveToWaitList,
  setProfileForm,
  signUp,
  tokenizeCard,
  validateStatus,
  moveToWaitListSuccess,
  deletePayments
} from 'sagas/parent/signUp'
import {
  healthCheck
} from 'sagas/healthCheck'

export default function *rootSaga () {
  yield [
    hydrate(),
    ...[
      closeBookingConfirmation(),
      bookingCreateFailure(),
      unreviewedBookingList(),
    ],
    hudComplete(),
    logOut(),
    ...[
      searchSubmit(),
      priceEstimate()
    ],
    ...[
      loginSuccess()
    ],
    ...[
      profileSuccess(),
      removeAuthToken(),
      setAuthToken(),
      passwordChangeSuccess(),
      passwordChangeFailure(),
      passwordResetSuccess(),
      passwordResetFailure()
    ],
    ...[
      facebookAuth(),
      facebookLogin(),
      facebookSignUp(),
      facebookSignUpSuccess(),
      getLoginStatus()
    ],
    ...[
      handleBraintreeToken(),
      moveToChildProfilesStep(),
      moveToCompleteProfileStep(),
      moveToHomePage(),
      moveToLocationStep(),
      // moveToPaymentStep(),
      moveToRegisterStep(),
      moveToWaitList(),
      setProfileForm(),
      signUp(),
      tokenizeCard(),
      validateStatus(),
      moveToWaitListSuccess(),
      deletePayments()
    ],
    ...[
      sitterProfile(),
      locationChange()
    ],
    ...[
      paymentFail(),
      paymentChangeFail(),
      searchFail(),
      allFail(),
      logOut()
    ],
    ...[
      healthCheck()
    ]
  ]
}
