import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  reduxForm
} from 'redux-form';

import {track} from 'utils/track';
import FormButton from 'forms/controls/FormButton';
import RegistrationFields from 'forms/fields/Registration';
import {
  validateFields,
  validateRegistration
} from 'utils/formValidations';

export class RegisterComponent extends React.Component {
  static propTypes = {
    facebookDisabled: PropTypes.bool.isRequired,
    getLoginStatus: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onFacebookClick: PropTypes.func.isRequired
  };

  componentDidMount () {
    track('Sign Up Page - Register');
  }

  render () {
    const {
      facebookDisabled,
      onFacebookClick,
      handleSubmit,
      isLoading
    } = this.props;
    return (
      <div>
        <FormButton
            className="c-form-button--primary c-form-button--inverse c-form-button--block c-form-button--facebook"
            disabled={isLoading || facebookDisabled}
            icon="Facebook"
            label="Sign Up with Facebook"
            onClick={onFacebookClick}
            type="button"
        />
        <h2 className="c-text-small c-text-small--muted c-line-behind">{'Or sign up with email'}</h2>
        <form onSubmit={handleSubmit}>
          <FormSection name="registration">
            <RegistrationFields requireTerms />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                disabled={isLoading || facebookDisabled}
                label="Next"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'signUp',
  validate: validateFields({
    registration: validateRegistration(true)
  })
})(RegisterComponent);
