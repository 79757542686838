import React from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import FormButton from 'forms/controls/FormButton';

export default class WeekPicker extends React.Component {
  static propTypes = {
    defaultDate: PropTypes.object.isRequired,
    events: PropTypes.array.isRequired,
    formats: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.state = {
      currentDate: moment(this.props.defaultDate)
    };
  }

  onPrevClick = () => {
    this.setState({ // eslint-disable-line react/no-set-state
      currentDate: this.state.currentDate.clone().subtract(1, 'week')
    });
  };

  onNextClick = () => {
    this.setState({ // eslint-disable-line react/no-set-state
      currentDate: this.state.currentDate.clone().add(1, 'week')
    });
  };

  render () {
    const localizer = momentLocalizer(moment);
    return (
      <div className="c-week-picker o-layout">
        <div className="o-layout__item u-1/3">
          <FormButton
              className="c-form-button--primary c-form-button--inverse"
              label="Previous"
              onClick={this.onPrevClick}
              type="button"
          />
        </div>
        <div className="o-layout__item u-1/3">
          <h2 className="c-h2 u-center-text c-week-picker__title">{this.state.currentDate.format('MMM')}</h2>
        </div>
        <div className="o-layout__item u-1/3 u-right-text">
          <FormButton
              className="c-form-button--primary c-form-button--inverse"
              label="Next"
              onClick={this.onNextClick}
              type="button"
          />
        </div>
        <div className="o-layout__item u-1/1">
          <Calendar
              date={this.state.currentDate.toDate()}
              defaultView="week"
              events={this.props.events}
              formats={this.props.formats}
              localizer={localizer}
              onNavigate={() => {}}
              onSelectEvent={this.props.handleClick}
              selectable
              step={60}
              timeslots={1}
              toolbar={false}
          />
        </div>
      </div>
    );
  }
}
