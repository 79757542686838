import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Field} from 'redux-form';

import scheduleLogic from 'services/bookingSchedule';
import FormFieldPartial from 'components/partials/FormField';
import TimeControl from 'forms/controls/Time';
import * as timeFormats from 'utils/timeFormats';
import {
  defaultBookingSettings,
  normalizeScheduleSettings
} from 'reducers/parent/search';

export default class TimeRangeControl extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    max: PropTypes.string,
    min: PropTypes.string,
    onFormChange: PropTypes.func.isRequired
  };

  render () {
    const {
      endTime,
      error,
      max,
      min,
      onFormChange,
      startTime,
      targetDate
    } = this.props;
    const scheduleSettings = normalizeScheduleSettings(defaultBookingSettings);
    const schedule = scheduleLogic.calculateTimeRange(scheduleSettings, moment().toISOString(), moment(targetDate.input.value).toISOString(), moment(startTime.input.value).toISOString(), moment(endTime.input.value).toISOString(), min, max);
    setTimeout(() => {
      if (startTime.input.value !== schedule.startTime) {
        onFormChange('search', 'providerSearch.schedule.startTime', schedule.startTime); // refactor, `searchForm` shouldn't be hardcoded here
      }
      if (endTime.input.value !== schedule.endTime) {
        onFormChange('search', 'providerSearch.schedule.endTime', schedule.endTime); // refactor, `searchForm` shouldn't be hardcoded here
      }
    }, 0);
    return (
      <div className="c-form-date-time">
        <FormFieldPartial
            {...{error}}
            icon="Clock"
            message=""
        >
          <div className="o-layout">
            <div className="o-layout__item u-3/9">
              <div className="c-form-field__input">
                <Field
                    component={TimeControl}
                    label="Start Time"
                    name="startTime"
                    times={schedule.startTimes.map(time => ({
                      label: timeFormats.basicTime(time),
                      value: time.toISOString()
                    }))}
                />
              </div>
            </div>
            <div className="o-layout__item u-1/9 u-center-text">
              {'\u2192'}
            </div>
            <div className="o-layout__item u-5/9">
              <div className="c-form-field__input">
                <Field
                    component={TimeControl}
                    label="End Time"
                    name="endTime"
                    times={schedule.endTimes.map(time => ({
                      label: timeFormats.basicTime(time),
                      value: time.toISOString()
                    }))}
                />
              </div>
            </div>
          </div>
        </FormFieldPartial>
      </div>
    );
  }
}
