import {browserHistory} from 'react-router';
import {
  takeEvery,
  call,
  fork,
  put,
  select,
  take
} from 'redux-saga/effects';

import {identify} from 'utils/track';
import * as fb from 'utils/facebook';
import {HOME_PAGE} from 'constants/parent/navigation';
import {SET_AUTH_TOKEN} from 'constants/session';
import {
  facebookLogin,
  facebookSignUp
} from 'actions/facebook';
import {
  AUTH_FAILURE as FACEBOOK_AUTH_FAILURE,
  AUTH_REQUEST as FACEBOOK_AUTH_REQUEST,
  AUTH_SUCCESS as FACEBOOK_AUTH_SUCCESS,
  GET_LOGIN_STATUS as FACEBOOK_GET_LOGIN_STATUS,
  LOGIN_FAILURE as FACEBOOK_LOGIN_FAILURE,
  SIGNUP_SUCCESS as FACEBOOK_SIGNUP_SUCCESS
} from 'constants/facebook';

let fbAccessToken = 'fbAccessToken'; // refactor, should this be kept in the redux state or here, internally?

export function *auth () {
  yield takeEvery(FACEBOOK_AUTH_REQUEST, authEffect);
}

export function *authEffect () {
  try {
    const {
      accessToken,
      error
    } = yield call(fb.login);
    if (accessToken) {
      yield put({type: FACEBOOK_AUTH_SUCCESS, accessToken});
    } else {
      yield put({type: FACEBOOK_AUTH_FAILURE, error});
    }
  } catch (e) {
    // refactor, handle facebook login fail
  }
}

export function *getLoginStatus () {
  yield takeEvery(FACEBOOK_GET_LOGIN_STATUS, getLoginStatusEffect);
}

export function *getLoginStatusEffect () {
  const {
    accessToken,
    error
  } = yield call(fb.getLoginStatus);
  if (accessToken) {
    fbAccessToken = accessToken;
    yield put({type: FACEBOOK_AUTH_SUCCESS, accessToken});
  } else {
    yield put({type: FACEBOOK_AUTH_FAILURE, error});
  }
}

export function *login () {
  yield takeEvery(FACEBOOK_AUTH_SUCCESS, loginEffect);
}

export function *loginEffect ({accessToken}) {
  fbAccessToken = accessToken;
  const {
    session: {
      zip
    }
  } = yield select();
  yield put(facebookLogin(accessToken, zip));
}

export function *signUp () {
  yield takeEvery(FACEBOOK_LOGIN_FAILURE, signUpEffect);
}

export function *signUpEffect () {
  const {
    session: {
      zip
    }
  } = yield select();
  yield put(facebookSignUp(fbAccessToken, zip));
}

export function *signUpSuccess () {
  while (true) { // eslint-disable-line no-constant-condition
    const {response} = yield take(FACEBOOK_SIGNUP_SUCCESS);
    const user = response.entities.user[response.result]; // eslint-disable-line camelcase
    yield fork(signUpSuccessEffect, user.authentication_token, user);
  }
}

export function *signUpSuccessEffect (authToken, user) {
  yield call(identify, user.email);
  yield put({type: SET_AUTH_TOKEN, authToken});
  yield call(browserHistory.push, `/${HOME_PAGE}`); // refactor, extract
}
