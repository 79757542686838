import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {track} from 'utils/track';
import {setTitle} from 'actions/navigation';
import {loadOtherProfile} from 'actions/profile';
import {PROFILE_OTHER_SUCCESS} from 'constants/profile';

import { Chat, Channel, ChannelHeader, Thread, Window } from 'stream-chat-react';
import { MessageList, MessageInput } from 'stream-chat-react';

import * as streamUtils from 'utils/stream';

export class Conversation extends React.Component {
  static propTypes = {
    otherId: PropTypes.string.isRequired,
    session: PropTypes.object.isRequired
  };

  state = {
    otherUser: null,
    streamClient: null,
    channel: null
  };

  componentWillMount () {
    if (this.props.session.user) {
      // TODO: Use redux/sagas properly
      this.props.dispatch(loadOtherProfile(this.props.session.authToken, this.props.otherId)).then(action => {
        if (action.type === PROFILE_OTHER_SUCCESS) {
          const other = action.response.entities.user[action.response.result];
          this.setupClient(this.props.session.authToken, other);
        }
      });
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.session.user && !this.props.session.user) {
      // TODO: Use redux/sagas properly
      this.props.dispatch(loadOtherProfile(newProps.session.authToken, newProps.otherId)).then(action => {
        if (action.type === PROFILE_OTHER_SUCCESS) {
          const other = action.response.entities.user[action.response.result];
          this.setupClient(newProps.session.authToken, other);
        }
      });
    }
  }

  async setupClient (authToken, otherUser) {
    const currentUser = this.props.session.user;

    if (currentUser && otherUser) {
      const streamClient = await streamUtils.makeClient(authToken, currentUser, this.props.dispatch);
      const channel = streamUtils.getChannel(streamClient, currentUser, otherUser);
      this.setState({streamClient, channel, otherUser}); // eslint-disable-line react/no-set-state
    }
  }

  componentDidMount () {
    this.props.dispatch(setTitle('Conversation'));
    track('Conversation Page');
  }

  displayConversation () {
    const streamClient = this.state.streamClient;
    const otherUser = this.state.otherUser;
    const channel = this.state.channel;

    return (
      <Chat
          client={streamClient}
          theme={'messaging light'}
      >
        <Channel channel={channel}>
          <Window>
            <ChannelHeader title={otherUser.first_name}/>
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    );
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item">
          {(this.state.streamClient) && (
            this.displayConversation()
          )}
          {(!this.state.streamClient) && (
            <h1 className="c-h1">{'Loading...'}</h1>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  const {
    session
  } = state;
  const {
    otherId
  } = ownProps.params;
  return {
    otherId,
    session
  };
})(Conversation);
