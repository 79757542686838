import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import {track} from 'utils/track';
import FormButton from 'forms/controls/FormButton';
import {
  validatePaymentMethod,
  validateFieldArray,
  validateFields
} from 'utils/formValidations';

export class PaymentComponent extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    onPaymentInitialize: PropTypes.func.isRequired
  };

  componentDidMount () {
    track('Complete Registration Page - Payment Methods');
    this.props.onPaymentInitialize();
  }

  render () {
    const {
      handleSubmit,
      isLoading
    } = this.props;
    return (
      <div>
        <h1 className="c-h1 u-center-text c-search-header">{'Please add a payment method'}</h1>
        <p className="u-center-text">{'Please add a payment method. We will not bill you until after you complete a booking.'}</p>
        <form onSubmit={handleSubmit}>
          <div id="dropin-container"></div>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                disabled={isLoading}
                label="Next"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'paymentMethods',
  validate: validateFields({
    paymentMethods: validateFieldArray(validatePaymentMethod)
  }, {
    paymentMethods: paymentMethods => {
      if (!paymentMethods || !paymentMethods.length) {
        return {_error: 'At least one payment method is require'};
      }
    }
  })
})(PaymentComponent);
