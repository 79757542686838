import React from 'react';
import {
  Field,
  FormSection,
  reduxForm
} from 'redux-form';

import FormButton from 'forms/controls/FormButton';
import {
  validateFields,
  validateProviderReview
} from 'utils/formValidations';
import StarRatingControl from 'forms/controls/StarRating';
import FormCheckbox from 'forms/controls/FormCheckbox';

export class ProviderReviewComponent extends React.Component {
  render () {
    const {
      handleSubmit,
      invalid // refactor, i did this because i was too lazy to add error messages to the text field
    } = this.props;
    const privacyOptions = [
      {
        classes: 'u-1/1 c-form-checkbox__item--plain u-margin-bottom-none',
        label: 'Make my review private',
        value: 'private'
      }
    ];
    const favoriteOptions = [
      {
        classes: 'u-1/1 c-form-checkbox__item--plain u-margin-bottom-none',
        label: 'Save to Favorites',
        value: 'true'
      }
    ];
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <FormSection name="providerReview">
            <Field
                component={StarRatingControl}
                name="rating"
                size="large"
            />
            <Field
                className="c-textarea"
                component="textarea"
                name="reviewContent"
                placeholder="Write a review"
            />
            <Field
                component={FormCheckbox}
                label="Make private"
                name="privacy"
                options={privacyOptions}
                plain
            />
            <Field
                component={FormCheckbox}
                label="Save favorite"
                name="favorite"
                options={favoriteOptions}
                plain
            />
          </FormSection>
          <div>
            <FormButton
                className="c-form-button--primary c-form-button--block"
                disabled={invalid}
                label="Submit"
                type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'providerReview',
  validate: validateFields({
    providerReview: validateProviderReview
  }, {
  })
})(ProviderReviewComponent);
