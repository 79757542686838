import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';

import {track} from 'utils/track';
import LoginForm from 'forms/Login';
import {setTitle as setTitleAction} from 'actions/navigation';
import {
  SIGN_UP_PAGE,
  PASSWORD_RESET_PAGE
} from 'constants/parent/navigation';
import {auth as facebookAuthAction} from 'actions/facebook';
import {login as doLogin} from 'actions/login';

export class Login extends React.Component {
  static propTypes = {
    loginAction: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    setTitle: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.handleSubmit = this.onSubmit();
    this.handleFacebookClick = this.onFacebookClick();
  }

  componentDidMount () {
    this.props.setTitle('Log in');
    track('Login Page');
  }

  onSubmit () {
    return ({login: {email, password}}) => {
      return this.props.loginAction(email, password).then(action => {
        if (action.error) {
          throw new SubmissionError({login: {password: 'Invalid email or password!'}});
        }
      });
    };
  }

  onFacebookClick () {
    return () => {
      this.props.facebookAuth();
    };
  }

  render () {
    const {
      loading
    } = this.props;
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/3@desktop u-push-1/3@desktop">
          <h1 className="c-h1 u-center-text c-search-header">{'Log in'}</h1>
          <LoginForm
              loginLoading={loading.login}
              onFacebookClick={this.handleFacebookClick}
              onSubmit={this.handleSubmit}
          />
          <div className="u-center-text u-margin-bottom">
            <Link to={`/${SIGN_UP_PAGE}`}>{'Don\'t have an account? Sign Up'}</Link>
          </div>
          <div className="u-center-text">
            <Link to={`/${PASSWORD_RESET_PAGE}`}>{'Forgot your password?'}</Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const {
    loading
  } = state;
  return {
    loading
  };
}

const mapDispatchToProps = {
  facebookAuth: facebookAuthAction,
  setTitle: setTitleAction,
  loginAction: doLogin
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
