export const AUTH_REQUEST = 'FACEBOOK_AUTH_REQUEST';
export const AUTH_SUCCESS = 'FACEBOOK_AUTH_SUCCESS';
export const AUTH_FAILURE = 'FACEBOOK_AUTH_FAILURE';
export const LOGIN_REQUEST = 'FACEBOOK_LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'FACEBOOK_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'FACEBOOK_LOGIN_FAILURE';
export const SIGNUP_REQUEST = 'FACEBOOK_SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'FACEBOOK_SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'FACEBOOK_SIGNUP_FAILURE';
export const GET_LOGIN_STATUS = 'FACEBOOK_GET_LOGIN_STATUS';
