import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import {Link} from 'react-router';

import Calendar from 'components/Calendar';
import {PROFILE_PAGE} from 'constants/parent/navigation';
import PortraitFrame from 'components/PortraitFrame';
import CalendarNav from 'components/CalendarNav';
import {setTitle as setTitleAction} from 'actions/navigation';
import {track} from 'utils/track';
import {FAVORITE_BOOKING_PAGE} from 'constants/parent/navigation';
import {
  loadSchedule as loadScheduleAction,
  loadSitterProfile as loadSitterProfileAction
} from 'actions/parent/profile';

export class ProviderSchedule extends React.Component {
  static propTypes = {
    constants: PropTypes.object.isRequired,
    authToken: PropTypes.string.isRequired,
    availabilities: PropTypes.array.isRequired,
    sitter: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    loadSchedule: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired
  };

  componentDidMount () {
    const {
      authToken,
      id
    } = this.props;
    this.props.loadSitterProfile(authToken, id);
    this.props.loadSchedule(authToken, id);
    this.props.setTitle('Provider schedule');
    track('Provider Schedule Page');
  }

  handleNext () {
    return () => {
      this.calendar.next();
    };
  }

  handlePrev () {
    return () => {
      if (!this.calendar.isPast()) {
        this.calendar.prev();
      }
    };
  }

  handleEventClick (event, id) {
    const targetDate = moment(event.start).startOf('day').toISOString();
    const startTime = moment(event.start).toISOString();
    const endTime = moment(event.end).toISOString();
    browserHistory.push(`/${FAVORITE_BOOKING_PAGE}?startTime=${startTime}&endTime=${endTime}&targetDate=${targetDate}&id=${id}`); // refactor, extract action and saga
  }

  render () {
    const events = this.props.availabilities.map(CalendarEvent.fromAvailability);
    if (!this.props.constants.booking || !this.props.sitter) {
      return (
        <h1 className="c-h1 u-center-text">{'Loading...'}</h1>
      );
    }
    const {sitter} = this.props;
    const {booking} = this.props.constants;
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <h1 className="c-h1 u-center-text c-search-header">{'Provider schedule'}</h1>
          <div className="o-media">
            <div className="o-media__img">
              <Link to={`/${PROFILE_PAGE}/${sitter.id}`}>
                <PortraitFrame
                    classes={['c-portrait-frame--medium c-provider-review__portrait']}
                    src={sitter.photo_url}
                />
              </Link>
            </div>
            <div className="o-media__body c-split-info">
              <span className="c-split-info__left">
                <h1 className="c-h1 c-portrait-title">
                  <Link
                      className="c-link"
                      to={`/${PROFILE_PAGE}/${sitter.id}`}
                  >{`${sitter.first_name}`}</Link>
                </h1>
              </span>
            </div>
          </div>
          <CalendarNav
              onNext={this.handleNext()}
              onPrev={this.handlePrev()}
          />
          <Calendar
              dayEnd={booking.end_time}
              dayStart={booking.start_time}
              onEventClick={(calendar, event) => this.handleEventClick(event, this.props.id)}
              ref={c => this.calendar = c}
              slotDuration={3600}
              {...{events}}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  const {
    session: {
      authToken
    },
    settings: {
      constants
    },
    profile: {
      availabilities,
      sitter
    }
  } = state;
  const {
    params: {
      id
    }
  } = ownProps;
  return ({
    sitter,
    constants,
    authToken,
    availabilities,
    id
  });
}

const mapDispatchToProps = {
  loadSchedule: loadScheduleAction,
  loadSitterProfile: loadSitterProfileAction,
  setTitle: setTitleAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSchedule);

function formatTime (time) {
  return moment(time).format('h:mmp').slice(0, -1);
}

export class CalendarEvent {
  static fromAvailability (availability) {
    const start = moment(availability.start_time);
    const end = moment(availability.end_time);
    return new CalendarEvent(
      formatTime(start) + '\n−\n' + formatTime(end),
      start,
      end,
      classNames({
        'c-schedule-event__availability': true,
        'c-schedule-event__availability-booked': availability.booked
      }),
      availability
    );
  }

  constructor (title, start, end, className, availability) {
    this.title = title;
    this.start = start;
    this.end = end;
    this.className = className;
    this.availability = availability;
  }
}
