/* global FB */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {track} from 'utils/track';
import {setTitle as setTitleAction} from 'actions/navigation';
import * as SVGIcon from 'components/SVGIcon';

export class Referral extends React.Component {
  static propTypes = {
    copy: PropTypes.object.isRequired,
    setTitle: PropTypes.func.isRequired
  };

  componentDidMount () {
    track('Referral Code Page');
    this.props.setTitle('Give $15, Get $15');
  }

  render () {
    const {
      copy,
      user
    } = this.props;
    if (!user || !copy.en) {
      return (
        <h1>{'Loading...'}</h1>
      );
    }
    const {
      en: {
        referrals: {
          email,
          facebook,
          twitter
        }
      }
    } = copy;
    const {
      default_referral_code: {
        code,
        link,
        amount
      }
    } = user;
    const Email = SVGIcon.Email;
    const Facebook = SVGIcon.Facebook;
    const Twitter = SVGIcon.Twitter;
    // const messageText = `Sign up with my @usetrusted referral code ${code} for a $${amount} credit on your first booking.`;
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/2@tablet u-push-1/4@tablet u-center-text">
          <h1 className="c-h1 c-search-header">{`Give $${user.default_referral_code.amount}, get $${user.default_referral_code.amount}`}</h1>
          <p>{`Invite your friends to get $${user.default_referral_code.amount} off their first booking by using your promo code and you'll get $${user.default_referral_code.amount}.`}</p>
          <h1 className="c-h1">{code}</h1>
          <p className="u-margin-bottom">{'Share your referral code:'}</p>
          <div className="o-layout">
            <div className="o-layout__item u-1/3 u-right-text c-icon-button">
              <button
                  className="c-form-button c-form-button--inverse c-form-button--primary"
                  onClick={() => {
                    FB.ui({
                      method: 'share',
                      href: link,
                      quote: facebook.split(':code').join(code).split(':amount').join(`$${amount}`)
                    });
                  }}
                  type="button"
              ><Facebook active /></button>
            </div>
            <div className="o-layout__item u-1/3 c-icon-button">
              <a
                  className="c-form-button c-form-button--inverse c-form-button--primary"
                  href={`https://twitter.com/intent/tweet?text=${twitter.split(':code').join(code).split(':amount').join(`$${amount}`)}`}
                  target="_blank"
              ><Twitter active /></a>
            </div>
            <div className="o-layout__item u-1/3 u-left-text c-icon-button">
              <a
                  className="c-form-button c-form-button--inverse c-form-button--primary"
                  href={`mailto:?to=&subject=${encodeURIComponent(email.subject.split(':amount').join(`$${amount}`))}&body=${encodeURIComponent(email.body.split(':code').join(code).split(':amount').join(`$${amount}`))}`}
                  target="_blank"
              ><Email active /></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  const {
    settings: {
      copy
    },
    session: {
      user
    }
  } = state;
  return {
    user,
    copy
  };
}, {
  setTitle: setTitleAction
})(Referral);
