import React from 'react';
import PropTypes from 'prop-types';
import {
  FormSection,
  reduxForm
} from 'redux-form';
import classNames from 'classnames';

import ProviderSearch from 'components/ProviderSearch';
import FormButton from 'forms/controls/FormButton';
import SearchFields from 'forms/fields/Search';
import {
  validateFields,
  validateSearch
} from 'utils/formValidations';

export class SearchComponent extends React.Component {
  static propTypes = {
    targetDateActive: PropTypes.bool.isRequired,
    timeRangeActive: PropTypes.bool.isRequired,
    toggleSearchCollapse: PropTypes.func.isRequired,
    condensed: PropTypes.bool.isRequired,
    min: PropTypes.string,
    providerSearch: PropTypes.object.isRequired,
    max: PropTypes.string,
    childProfiles: PropTypes.array.isRequired,
    constants: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired
  };

  render () {
    const {
      toggleSearchCollapse,
      condensed,
      providerSearch,
      childProfiles,
      timeRangeActive,
      targetDateActive,
      constants,
      onFormChange,
      handleSubmit,
      min,
      max,
      error
    } = this.props;
    const fullSearchClassName = classNames({
      'u-hide': condensed,
      'u-display-inline-block@tablet': true,
      'u-1/1': true,
      'u-margin-bottom': true,
      'u-margin-bottom-large@desktop': true
    });
    const condensedSearchClassName = classNames({
      'u-hide': !condensed,
      'u-hide@tablet': true,
      'u-1/1': true
    });
    return (
      <div className="c-search-form">
        <div className={condensedSearchClassName}>
          {Object.keys(providerSearch).length && (
            <ProviderSearch
                values={providerSearch}
                {...{toggleSearchCollapse}}
            />
          )}
        </div>
        <div className={fullSearchClassName}>
          <form onSubmit={handleSubmit}>
            <FormSection name="providerSearch">
              <SearchFields
                  {...{childProfiles, constants, onFormChange, error, min, max, targetDateActive, timeRangeActive}}
              />
            </FormSection>
            <div>
              <FormButton
                  className="c-form-button--primary c-form-button--block"
                  label="Search"
                  type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'search',
  enableReinitialize: true,
  validate: validateFields({
    providerSearch: validateSearch,
  })
})(SearchComponent);
