import moment from 'moment';

export function weekDateAt (momentTime) {
  return `${momentTime.format('ddd, MMM D, YYYY')} at ${momentTime.format('h:mma')}`;
}

export function completeDateTime (momentTime) {
  return momentTime.format('YYYY-MM-DD HH:mm:ss ZZ');
}

export function fullMonthDate (momentTime) {
  return momentTime.format('MMMM D, YYYY');
}

export function basicTime (momentTime) {
  return momentTime.format('h:mma');
}

export function yearMonthDay (momentTime) {
  return momentTime.format('YYYY-MM-DD');
}

export function fullHour (momentTime) {
  return momentTime.format('HH:mm');
}

export function localDateTime (momentTime) {
  return momentTime.format('YYYY-MM-DD h:mma');
}

export function fullMonth (momentTime) {
  return momentTime.format('MMMM');
}

export function parseDateTime (dateTimeString) {
  return moment(dateTimeString, 'YYYY-MM-DD HH:mm');
}

export function parseHoursMinutes (timeString) {
  return moment(timeString, 'HHmm');
}

export function hoursMinutes (momentTime) {
  return momentTime.format('HHmm');
}

export function monthsDays (momentTime) {
  return momentTime.format('MM/DD');
}
