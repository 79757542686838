import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import Truncate from 'react-truncate';

import {loadSitterSearch as loadSitterSearchAction} from 'actions/parent/search';
import PortraitFrame from 'components/PortraitFrame';
import {loadFavorites as loadFavoritesAction} from 'actions/parent/profile';
import {setTitle as setTitleAction} from 'actions/navigation';
import {track} from 'utils/track';
import {
  PROFILE_PAGE
} from 'constants/parent/navigation';

export class Favorites extends React.Component {
  static propTypes = {
    result: PropTypes.object,
    authToken: PropTypes.string.isRequired,
    favorites: PropTypes.array.isRequired,
    loadFavorites: PropTypes.func.isRequired,
    loadSitterSearch: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  componentDidMount () {
    this.props.loadFavorites(this.props.authToken);
    this.props.setTitle('Favorites');
    track('Favorites Page');
    if (this.props.user) {
      this.doSearch(this.props.user);
    }
  }

  componentWillReceiveProps (newProps) {
    if (!this.props.user && newProps.user) {
      const {user} = newProps;
      this.doSearch(user);
    }
  }

  buildSearch (user) {
    return {
      location: {
        zip: user.default_location.zip_code,
        city: user.default_location.city,
        lat: user.default_location.geolocation.lat,
        lng: user.default_location.geolocation.lng,
        state: user.default_location.state,
        streetAddress: user.default_location.street
      },
      childProfiles: user.child_profiles.map(i => i.id).slice(-3)
    };
  }

  doSearch (user) {
    const searchParams = this.buildSearch(user);
    this.props.loadSitterSearch(this.props.authToken, searchParams);
  }

  buildEmpty () {
    return (
      <div>
        <h1 className="c-h1 u-center-text c-search-header">{'Favorite providers'}</h1>
        <p>{'Your favorite providers will appear here once you\'ve given a 5-star rating to a provider after a booking.'}</p>
      </div>
    );
  }

  buildSitterList (sitters, queryParams) { /* eslint-disable camelcase */
    return (
      <div>
        <h1 className="c-h1 u-center-text c-search-header">{'Favorite providers'}</h1>
        {sitters.map(({photo_url, first_name, reviews_count, id, default_location}) => (
          <Link
              className="c-sitter-result"
              key={id}
              to={`/${PROFILE_PAGE}/${id}?${queryParams}`}
          >
            <div className="o-media c-sitter-result__body">
              <div className="o-media__img">
                <PortraitFrame
                    classes={['c-portrait-frame--medium']}
                    src={photo_url || ''}
                />
              </div>
              <div className="o-media__body">
                <h3 className="c-sitter-result__title">{`${first_name}`}</h3>
                {default_location && (
                  <div className="c-sitter-result__location">{`${default_location.city}, ${default_location.state}`}</div>
                )}
                <p className="c-sitter-result__summary">
                  <Truncate
                      ellipsis={<span>{'...'}</span>}
                      lines={3}
                  >{`${reviews_count} Review${(reviews_count !== 1) && 's' }`}</Truncate>
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  } /* eslint-enable camelcase */

  buildQueryParams (user, result) {
    const searchParams = this.buildSearch(user);
    return [
      ['searchId', result.id],
      ['childProfiles', searchParams.childProfiles],
      ['city', searchParams.location.city],
      ['lat', searchParams.location.lat],
      ['lng', searchParams.location.lng],
      ['state', searchParams.location.state],
      ['streetAddress', searchParams.location.streetAddress],
      ['zip', searchParams.location.zip]
    ].map(o => `${o[0]}=${encodeURIComponent(o[1])}`).join('&');
  }

  render () {
    const {
      result,
      user,
      favorites
    } = this.props;
    if (!user || !result) {
      return (
        <h1 className="c-h1">{'Loading...'}</h1>
      );
    }
    const queryParams = this.buildQueryParams(user, result);
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/2@desktop u-push-1/4@desktop">
          {favorites.length ? this.buildSitterList(favorites, queryParams) : this.buildEmpty()}
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const {
    session: {
      authToken,
      user
    },
    profile: {
      favorites
    },
    search: {
      result
    }
  } = state;
  return ({
    result,
    authToken,
    favorites,
    user
  });
}

const mapDispatchToProps = {
  loadFavorites: loadFavoritesAction,
  setTitle: setTitleAction,
  loadSitterSearch: loadSitterSearchAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
