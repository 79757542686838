import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';

import * as timeFormats from 'utils/timeFormats';
import PortraitFrame from 'components/PortraitFrame';
import ReadFieldPartial from 'components/partials/ReadField';
import moment from 'moment';
import {
  PROFILE_PAGE,
  MESSAGE_PAGE
} from 'constants/parent/navigation';

export default class BookingRecord extends React.Component {
  static propTypes = { /* eslint-disable camelcase */
    onBookingDestroy: PropTypes.func,
    booking: PropTypes.shape({
      child_profiles: PropTypes.arrayOf(PropTypes.shape({
        first_name: PropTypes.string.isRequired
      })).isRequired,
      end_time: PropTypes.string.isRequired,
      location: PropTypes.shape({
        street: PropTypes.string.isRequired
      }).isRequired,
      note: PropTypes.string,
      sitter: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        photo_url: PropTypes.string.isRequired
      }).isRequired,
      start_time: PropTypes.string.isRequired
    }).isRequired
  }; /* eslint-enable camelcase */

  constructor (props) {
    super(props);
    this.handleClick = this.onClick();
  }

  onClick () {
    return () => {
      this.props.onBookingDestroy(this.props.booking);
    };
  }

  render () {
    const {
      child_profiles, // eslint-disable-line camelcase
      end_time, // eslint-disable-line camelcase
      location: {
        street
      },
      note,
      sitter: {
        first_name, // eslint-disable-line camelcase
        last_name, // eslint-disable-line camelcase
        photo_url // eslint-disable-line camelcase
      },
      start_time // eslint-disable-line camelcase
    } = this.props.booking;
    return ( /* eslint-disable camelcase */
      <div className="o-media c-booking-record">
        <div className="o-media__img">
          <Link to={`/${PROFILE_PAGE}/${this.props.booking.sitter.id}`}>
            <PortraitFrame src={photo_url} />
          </Link>
        </div>
        <div className="o-media__body">
          <h3 className="c-booking-record__title"><Link to={`/${PROFILE_PAGE}/${this.props.booking.sitter.id}`}>{`${first_name} ${last_name}.`}</Link></h3>
          <ReadFieldPartial icon="Pin">{street}</ReadFieldPartial>
          <ReadFieldPartial icon="Calendar">{timeFormats.fullMonthDate(moment(start_time))}</ReadFieldPartial>
          <ReadFieldPartial icon="Clock">{timeFormats.basicTime(moment(start_time))} {'\u2192'} {timeFormats.basicTime(moment(end_time))}</ReadFieldPartial>
          <ReadFieldPartial icon="Notes">{note}</ReadFieldPartial>
          <ReadFieldPartial icon="ChildInfant">{child_profiles.map(child => child.first_name).join(', ')}</ReadFieldPartial>
          <div>
            {this.props.booking.sitter.chat_enabled ? (
              <Link
                className="c-form-button c-form-button--primary c-form-button--inverse"
                to={`/${MESSAGE_PAGE}/${this.props.booking.sitter.id}`}
              >{'Message'}</Link>
            ) : (
              <a href={`sms:+1${this.props.booking.sitter.phone_number}`} className="c-form-button c-form-button--primary c-form-button--inverse" >Message</a>
            )}
          </div>
          {('created confirmed'.split(' ').indexOf(this.props.booking.status) > -1) && (
            <div>
              <button
                  className="c-form-button c-form-button--primary c-form-button--inverse c-form-button--block c-form-button--destructive"
                  onClick={this.handleClick}
                  type="button"
              >{'Cancel Booking'}</button>
            </div>
          )}
        </div>
      </div>
    ); /* eslint-enable camelcase */
  }
}
