import moment from 'moment';

import * as timeFormats from 'utils/timeFormats';
import {CALL_API} from 'middleware/api';
import {SECRET_KEY} from 'constants/settings';
import {
  search as searchSchema,
  searches as searchesSchema
} from 'schemas/search';
import {
  CLOSE_SEARCH_FAILURE,
  CLOSE_SEARCH_REQUEST,
  CLOSE_SEARCH_SUCCESS,
  LIST_OPEN_SEARCHES_FAILURE,
  LIST_OPEN_SEARCHES_REQUEST,
  LIST_OPEN_SEARCHES_SUCCESS,
  LOAD_SEARCH_STATE,
  OPEN_SEARCH_FAILURE,
  OPEN_SEARCH_REQUEST,
  OPEN_SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_REQUEST,
  SEARCH_SUBMIT,
  SEARCH_SUCCESS,
  SET_CHILDREN_COUNT,
  SET_CONVERSATION_ID,
  SET_END_TIME,
  SET_LOCATION,
  SET_START_TIME,
  SET_TARGET_DATE
} from 'constants/parent/search';

export function loadSearchState (query) {
  return {
    type: LOAD_SEARCH_STATE,
    query
  };
}

export function setConversationId (conversationId) {
  return {
    type: SET_CONVERSATION_ID,
    conversationId
  };
}

export function listOpenSearches (authToken) {
  return {
    [CALL_API]: {
      endpoint: 'parent/searches?scope=open',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'GET',
      schema: searchesSchema,
      types: [
        LIST_OPEN_SEARCHES_REQUEST,
        LIST_OPEN_SEARCHES_SUCCESS,
        LIST_OPEN_SEARCHES_FAILURE
      ]
    }
  };
}

export function closeSearch (authToken, searchId) {
  return {
    [CALL_API]: {
      endpoint: `parent/searches/${searchId}`,
      data: {
        status: 'closed',
        reason: 'parent'
      },
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'PUT',
      schema: searchSchema,
      types: [
        CLOSE_SEARCH_REQUEST,
        CLOSE_SEARCH_SUCCESS,
        CLOSE_SEARCH_FAILURE
      ]
    }
  };
}

export function openSearch (authToken, searchId, book_first_available = false) { // eslint-disable-line camelcase
  return {
    [CALL_API]: {
      endpoint: `parent/searches/${searchId}`,
      data: {
        status: 'open',
        reason: 'parent',
        book_first_available // eslint-disable-line camelcase
      },
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'PUT',
      schema: searchSchema,
      types: [
        OPEN_SEARCH_REQUEST,
        OPEN_SEARCH_SUCCESS,
        OPEN_SEARCH_FAILURE
      ]
    }
  };
}

export function setSearch (searchOptions) {
  const query = Object.keys(searchOptions).map(k => k + '=' + encodeURIComponent(searchOptions[k])).join('&'); // refactor, maybe this should be a util
  return {
    type: SEARCH_SUBMIT,
    query
  };
}

export function setEndTime (endTime) {
  return {
    type: SET_END_TIME,
    endTime
  };
}

export function setLocation (location) {
  return {
    type: SET_LOCATION,
    location
  };
}

export function setStartTime (startTime) {
  return {
    type: SET_START_TIME,
    startTime
  };
}

export function setChildrenCount (childrenCount) {
  return {
    type: SET_CHILDREN_COUNT,
    childrenCount
  };
}

export function setTargetDate (targetDate) {
  return {
    type: SET_TARGET_DATE,
    targetDate
  };
}

export function loadSitterSearch (authToken, searchOptions) {
  const { /* eslint-disable camelcase */
    childProfiles,
    targetDate,
    endTime,
    pets,
    location,
    startTime,
    has_cats,
    has_dogs,
    has_other
  } = searchOptions;
  const hasCats = pets && pets.indexOf('has_cats') > -1 || has_cats ? 'yes' : 'no'; // eslint-disable-line camelcase
  const hasDogs = pets && pets.indexOf('has_dogs') > -1 || has_dogs ? 'yes' : 'no'; // eslint-disable-line camelcase
  const hasOther = pets && pets.indexOf('has_other') > -1 || has_other ? 'yes' : 'no'; // eslint-disable-line camelcase
  const data = {
    household_details: {
      'has_cats': hasCats, // eslint-disable-line quote-props
      'has_dogs': hasDogs, // eslint-disable-line quote-props
      'has_other': hasOther, // eslint-disable-line quote-props
    }, /* eslint-enable camelcase */
    address: {
      'zip_code': location.zip, // eslint-disable-line quote-props
      apartment: '', // refactor, plug this in
      city: location.city,
      lat: location.lat,
      lng: location.lng,
      state: location.state,
      street: location.streetAddress
    },
    child_profiles: childProfiles // eslint-disable-line camelcase
  };
  if (startTime) {
    data['start_time'] = timeFormats.completeDateTime(moment(startTime));
  }
  if (endTime) {
    data['end_time'] = timeFormats.completeDateTime(moment(endTime));
  }
  if (targetDate) {
    data['date'] = timeFormats.completeDateTime(moment(targetDate));
  }
  return {
    [CALL_API]: { // refactor, this is gnarly
      data,
      endpoint: 'parent/searches',
      headers: {
        'X-Identity-Key': SECRET_KEY,
        'X-Identity-Token': authToken
      },
      method: 'POST',
      schema: searchSchema,
      types: [
        SEARCH_REQUEST,
        SEARCH_SUCCESS,
        SEARCH_FAILURE
      ]
    }
  };
}
