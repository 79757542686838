import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  change as changeFormAction,
  stopSubmit as stopSubmitAction
} from 'redux-form';
import moment from 'moment';

import DiscountCodeForm from 'forms/DiscountCode';
import {setTitle as setTitleAction} from 'actions/navigation';
import {DISCOUNT_CODE_FAILURE} from 'constants/parent/booking';
import {SEARCH_SUCCESS} from 'constants/parent/search';
import PortraitFrame from 'components/PortraitFrame';
import {track} from 'utils/track';
import FavoriteBookingForm from 'forms/FavoriteBooking';
import {loadSitterProfile as loadSitterProfileAction} from 'actions/parent/profile';
import {loadSitterSearch as loadSitterSearchAction} from 'actions/parent/search';
import {
  closeModal as closeModalAction,
  openModal as openModalAction
} from 'actions/modal';
import {
  createBooking as createBookingAction,
  applyDiscount as applyDiscountAction,
  requestPriceQuote as requestPriceQuoteAction
} from 'actions/parent/booking';

export class FavoritesBooking extends React.Component {
  static propTypes = {
    query: PropTypes.object.isRequired,
    search: PropTypes.object,
    searchId: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    applyDiscount: PropTypes.func.isRequired,
    createBooking: PropTypes.func.isRequired,
    priceEstimate: PropTypes.number.isRequired,
    loadSitterSearch: PropTypes.func.isRequired,
    requestPriceQuote: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    sitter: PropTypes.object.isRequired,
    endTime: PropTypes.string.isRequired,
    targetDate: PropTypes.string.isRequired,
    constants: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  componentDidMount () {
    this.props.setTitle('Booking');
    const {
      authToken,
      id,
      loadSitterProfile,
      // loadSitterSearch
    } = this.props;
    if (authToken) {
      loadSitterProfile(authToken, id);
    }
    track('Favorites Booking Page');
  }

  componentWillReceiveProps (newProps) {
    if (newProps.search) {
      if (!this.props.search || (this.hashSearch(newProps.search) !== this.hashSearch(this.props.search))) {
        const {
          authToken,
          loadSitterSearch
        } = this.props;
        const {
          values: {
            providerSearch: {
              pets,
              address,
              children,
              creditCards,
              schedule
            }
          }
        } = newProps.search;
        const searchRequest = {
          childrenCount: children.length,
          childProfiles: children,
          endTime: moment(schedule.endTime).toISOString(),
          has_cats: pets.indexOf('has_cats') > -1, // eslint-disable-line camelcase
          has_dogs: pets.indexOf('has_dogs') > -1, // eslint-disable-line camelcase
          has_other: pets.indexOf('has_other') > -1, // eslint-disable-line camelcase
          location: address,
          startTime: moment(schedule.startTime).toISOString()
        };
        loadSitterSearch(authToken, searchRequest).then(({response, type}) => {
          if (type === SEARCH_SUCCESS) {
            const search = response.entities.search[response.result];
            this.updatePrice(this.props.id, search.id, creditCards, children);
          }
        });
      }
    }
  }

  hashSearch (searchRequest) {
    const {
      values: {
        providerSearch: {
          children,
          schedule,
          pets
        }
      }
    } = searchRequest;
    return JSON.stringify({children, schedule, pets});
  }

  handleSubmit () {
    return ({providerSearch}) => {
      const {
        // address,
        children,
        creditCards,
        notes,
        // pets,
        // schedule
      } = providerSearch;
      const {
        authToken,
        createBooking,
        searchId,
        sitter
      } = this.props;
      const sitterId = sitter.id;
      const paymentMethodId = creditCards;
      const childProfileIds = children;
      createBooking(authToken, searchId, sitterId, paymentMethodId, childProfileIds, notes);
    };
  }

  updatePrice (sitterId, searchId, paymentId, childProfiles) {
    const {
      authToken,
      requestPriceQuote,
    } = this.props;
    requestPriceQuote(authToken, searchId, sitterId, paymentId, childProfiles);
  }

  hasDiscountCode () {
    return () => {
      this.props.openModal(DiscountCodeForm, 'Redeem Discount Code', {
        onSubmit: ({discountCode}) => {
          this.props.applyDiscount(this.props.authToken, discountCode.code).then(action => { // refactor, extract saga
            if (action.type === DISCOUNT_CODE_FAILURE) {
              let message = 'Invalid code';
              if (action.error.split(' ')[0] === 'is_redeemed') {
                message = 'This code has already been redeemed';
              }
              this.props.stopSubmit('discountCode', {discountCode: {code: message}});
            } else {
              this.props.closeModal();
              this.props.changeForm('discountCode', 'discountCode.code', '');
              this.updatePrice(this.props);
            }
          });
        }
      }, {
        'c-discount-code-modal': true
      });
    };
  }

  render () {
    const {
      constants,
      priceEstimate,
      onFormChange,
      user,
      startTime,
      sitter,
      query,
      endTime,
      targetDate
    } = this.props;
    if (!user || !constants.booking || !sitter) {
      return (
        <h1 className="c-h1 u-center-text">{'Loading...'}</h1>
      );
    }
    const address = {
      city: user.default_location.city,
      lat: user.default_location.geolocation.lat,
      lng: user.default_location.geolocation.lng,
      state: user.default_location.state,
      streetAddress: user.default_location.street,
      zip: user.default_location.zip_code
    };
    const initialValues = {
      providerSearch: {
        address,
        schedule: {
          endTime: moment(endTime).toDate(),
          startTime: moment(startTime).toDate(),
          targetDate: moment(targetDate).toDate(),
          targetDateActive: true,
          timeRangeActive: true
        },
        creditCards: `${user.default_payment_method.id}`,
        notes: '',
        children: user.child_profiles.map(({id}) => `${id}`).slice(-3),
        pets: []
      }
    };
    if (query.childProfiles) {
      initialValues.providerSearch.children = query.childProfiles.split(',');
    }
    if (query.pets) {
      initialValues.providerSearch.pets = query.pets.split(',');
    }
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/2@desktop u-push-1/4@desktop">
          <div className="o-layout">
            <div className="o-layout__item">
              <div className="o-media">
                <div className="o-media__img">
                  <PortraitFrame
                      classes={['c-portrait-frame--medium c-provider-review__portrait']}
                      src={sitter.photo_url}
                  />
                </div>
                <div className="o-media__body c-split-info">
                  <span className="c-split-info__left">
                    <h1 className="c-h1 c-portrait-title">{`${sitter.first_name}`}</h1>
                  </span>
                </div>
              </div>
            </div>
            <div className="o-layout__item">
              <FavoriteBookingForm
                  childProfiles={user.child_profiles}
                  hasDiscountCode={this.hasDiscountCode()}
                  max={endTime}
                  min={startTime}
                  onSubmit={this.handleSubmit()}
                  {...{initialValues, constants, onFormChange, user, priceEstimate}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  const {
    session: {
      authToken,
      user
    },
    bookings: {
      priceEstimate
    },
    settings: {
      constants
    },
    form: {
      search
    },
    profile: {
      favorites,
      sitter
    }
  } = state;
  const {
    location: {
      query
    }
  } = ownProps;
  const {
    endTime,
    startTime,
    targetDate,
    id
  } = query;
  return ({
    searchId: state.search.result ? `${state.search.result.id}` : '',
    search,
    id,
    priceEstimate,
    query,
    authToken,
    constants,
    favorites,
    sitter,
    user,
    endTime,
    startTime,
    targetDate
  });
}

const mapDispatchToProps = {
  onFormChange: changeFormAction,
  loadSitterProfile: loadSitterProfileAction,
  createBooking: createBookingAction,
  loadSitterSearch: loadSitterSearchAction,
  applyDiscount: applyDiscountAction,
  requestPriceQuote: requestPriceQuoteAction,
  stopSubmit: stopSubmitAction,
  openModal: openModalAction,
  changeForm: changeFormAction,
  closeModal: closeModalAction,
  setTitle: setTitleAction
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesBooking);
