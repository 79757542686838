import {stopSubmit} from 'redux-form';
import {
  takeEvery,
  put,
} from 'redux-saga/effects';

import {
  PAYMENT_CHANGE_FAILURE,
  PAYMENT_CHANGE_REQUEST
} from 'constants/parent/signUpFlow';
import {
  PAYMENT_SUBMIT_FAILURE,
  PAYMENT_SUBMIT_REQUEST
} from 'constants/parent/signUpFlow';
import {CONSTANTS_FAILURE, COPY_FAILURE} from '../../constants/settings';
import {LOG_OUT} from 'constants/logOut';
import {
  CLOSE_SEARCH_FAILURE,
  LIST_OPEN_SEARCHES_FAILURE,
  OPEN_SEARCH_FAILURE,
  SEARCH_FAILURE,
} from 'constants/parent/search';
import {
  PROVIDER_SCHEDULE_FAILURE,
  LOAD_FAVORITES_FAILURE,
  PROFILE_SITTER_FAILURE,
  PROFILE_UPDATE_FAILURE,
  PROFILE_SITTER_REVIEWS_FAILURE,
  PROFILE_SITTER_REFERENCES_FAILURE,
  PROFILE_SITTER_FAVORITE_FAILURE,
  PROFILE_SITTER_UNFAVORITE_FAILURE
} from 'constants/parent/profile';
import {
  PROFILE_FAILURE
} from 'constants/profile';

export function *paymentRequest () {
  yield takeEvery(PAYMENT_SUBMIT_REQUEST, paymentRequestEffect);
}

export function *paymentRequestEffect () {
  yield put(stopSubmit('paymentMethods', {paymentMethods: {_error: undefined}}));
}

export function *paymentChangeRequest () {
  yield takeEvery(PAYMENT_CHANGE_REQUEST, paymentChangeRequestEffect);
}

export function *paymentChangeRequestEffect () {
  yield put(stopSubmit('settings', {paymentMethods: {_error: undefined}}));
}

export function *paymentFail () {
  yield takeEvery(PAYMENT_SUBMIT_FAILURE, paymentFailEffect);
}

export function *paymentFailEffect () {
  yield put(stopSubmit('paymentMethods', {paymentMethods: {_error: 'Invalid payment method'}}));
}

export function *paymentChangeFail () {
  yield takeEvery(PAYMENT_CHANGE_FAILURE, paymentChangeFailEffect);
}

export function *paymentChangeFailEffect () {
  yield put(stopSubmit('settings', {paymentMethods: {_error: 'Invalid payment method'}}));
}

export function *searchFail () {
  yield takeEvery(SEARCH_FAILURE, searchFailEffect);
}

export function *allFail () {
  yield takeEvery([
    COPY_FAILURE,
    CONSTANTS_FAILURE,
    CLOSE_SEARCH_FAILURE,
    LIST_OPEN_SEARCHES_FAILURE,
    OPEN_SEARCH_FAILURE,
    SEARCH_FAILURE,
    PROVIDER_SCHEDULE_FAILURE,
    LOAD_FAVORITES_FAILURE,
    PROFILE_FAILURE,
    PROFILE_SITTER_FAILURE,
    PROFILE_UPDATE_FAILURE,
    PROFILE_SITTER_REVIEWS_FAILURE,
    PROFILE_SITTER_REFERENCES_FAILURE,
    PROFILE_SITTER_FAVORITE_FAILURE,
    PROFILE_SITTER_UNFAVORITE_FAILURE
  ], unautorizedFailEffect);
}

export function *unautorizedFailEffect (action) {
  if (action && action.error && action.error.status === 401) {
    yield put({type: LOG_OUT});
  }
}

export function *searchFailEffect (action) {
  const errors = {
    _error: null
  };
  switch (action.error) {
    case 'start_time must be in the future, end_time must be in the future':
    case 'end_time must be in the future':
    case 'start_time must be in the future': // refactor, very fickle
      errors._error = 'Booking times must be in the future';
      break;
  }
  yield put(stopSubmit('search', errors));
}
