import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router';

import BookingsList from 'components/BookingsList';
import {HOME_PAGE} from 'constants/parent/navigation';
import {setTitle as setTitleAction} from 'actions/navigation';
import {track} from 'utils/track';
import {
  destroyBooking as destroyBookingAction,
  listBookings as listBookingsAction,
  listPreviousBookings as listPreviousBookingsAction
} from 'actions/parent/booking';
import {
  closeModal as closeModalAction,
  openModal as openModalAction
} from 'actions/modal';

export class Bookings extends React.Component {
  static propTypes = {
    constants: PropTypes.object.isRequired,
    copy: PropTypes.object.isRequired,
    authToken: PropTypes.string.isRequired,
    bookings: PropTypes.object.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    listPreviousBookings: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
  }

  componentDidMount () {
    this.props.listPreviousBookings(this.props.authToken);
    this.props.setTitle('Bookings');
    track('Bookings Page');
  }

  buildBookingsList () {
    const {
      bookings: {
        previousList
      },
      constants,
      copy,
      authToken
    } = this.props;
    const list = [];
    return (
      <div>
        <BookingsList
            {...{list, previousList, authToken, constants, copy}}
        />
      </div>
    );
  }

  buildEmptyBookings () {
    return (
      <div className="c-empty-bookings">
        <h1>{'Your bookings will appear here'}</h1>
        <p>{'Once you create a booking, all of your future and past bookings will appear here.'}</p>
        <Link
            className="c-form-button c-form-button--primary c-form-button--inverse"
            to={`/${HOME_PAGE}`}
        >{'Create a booking'}</Link>
      </div>
    );
  }

  render () { /* eslint-disable camelcase */
    const {
      bookings: {
        previousList,
        totalCount,
        pastPage,
        perPage
      }
    } = this.props;
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-1/2@desktop u-push-1/4@desktop">
          {previousList.length ? this.buildBookingsList() : this.buildEmptyBookings()}
        </div>
        {totalCount / perPage > pastPage ? (
          <div className="o-layout__item c-booking__button--container">
            <div
                className="c-form-button c-form-button--primary c-form-button--inverse"
                onClick={() => this.props.listPreviousBookings(this.props.authToken, pastPage + 1, perPage, true)}
            >
              {'Load more bookings'}
            </div>
          </div>) : null}
      </div>
    );
  } /* eslint-enable camelcase */
}

function mapStateToProps (state) {
  const {
    bookings,
    session: {
      authToken
    },
    settings: {
      copy,
      constants
    }
  } = state;
  return ({
    constants,
    copy,
    authToken,
    bookings
  });
}

const mapDispatchToProps = {
  destroyBooking: destroyBookingAction,
  listBookings: listBookingsAction,
  listPreviousBookings: listPreviousBookingsAction,
  setTitle: setTitleAction,
  openModal: openModalAction,
  closeModal: closeModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
