import React from 'react';

import * as SVGIcon from 'components/SVGIcon';

export default class ReadField extends React.Component {
  render () {
    const {icon} = this.props;
    const Icon = SVGIcon[icon];
    return (
      <div className="c-read-field">
        <div className="o-media">
          <div className="o-media__img c-read-field__img">
            {icon && (<Icon />)}
          </div>
          <div className="o-media__body">
            <div className="c-read-field__control">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
