import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router';
import {OverlayTrigger} from 'pivotal-ui/react/overlay-trigger';
import FormButton from 'forms/controls/FormButton';
import {Tooltip} from 'pivotal-ui/react/tooltip';
import {
  BOOKING_CONFIRMATION_PAGE,
  SUGGESTION_BOOKING_PAGE,
  LOGIN_PAGE,
} from 'constants/parent/navigation';
import accounting from 'accounting';

export default class ProfileMainAction extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    priceEstimate: PropTypes.object,
    searchId: PropTypes.string,
    search: PropTypes.string.isRequired,
    sitter: PropTypes.object.isRequired,
    user: PropTypes.object,
    scheduleSelected: PropTypes.bool.isRequired,
    priceEstimateLoading: PropTypes.bool.isRequired,
    bookingLocation: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    handleTimeBlockClick: PropTypes.func.isRequired,
    handleViewScheduleClick: PropTypes.func.isRequired,
  };

  render () {
    let bookingNavigationURL = `/${BOOKING_CONFIRMATION_PAGE}/${this.props.sitter.id}${this.props.search}`;
    if (this.props.query.suggestedProvider === 'true') {
      const newQuery = {
        ...this.props.query,
        targetDate: moment(this.props.query.startTime).startOf('day').toISOString(),
        id: this.props.sitter.id
      };
      const searchParams = Object.keys(newQuery).map(k => k + '=' + encodeURIComponent(newQuery[k])).join('&'); // refactor, maybe this should be a util
      bookingNavigationURL = `/${SUGGESTION_BOOKING_PAGE}?${searchParams}`;
    }

    let price = '';
    if (this.props.priceEstimate) {
      price = accounting.formatNumber(this.props.priceEstimate.sitter_hourly_rate, 2);
    }

    if (this.props.scheduleSelected) {
      return (
        <div className="o-layout">
          <div className="o-layout__item u-1/2 u-display-inline-block u-display-none@desktop">
            <div className="c-price-estimate">
              {!!this.props.priceEstimateLoading && (
                '...'
              )}
              {!this.props.priceEstimateLoading && (
                <strong>{`$${price} `}</strong>
              )}
              {!this.props.priceEstimateLoading && (
                <span className="c-price-estimate__interval">{'per hour'}</span>
              )}
            </div>
          </div>
          <div className="o-layout__item u-1/2 u-1/1@desktop">
            <Link
                className="c-form-button c-form-button--block c-form-button--primary"
                to={this.props.bookingLocation}
            >{'Book'}</Link>
          </div>
          <div className="o-layout__item u-1/1 u-display-none u-display-block@desktop">
            <div className="c-price-estimate">
              {!!this.props.priceEstimateLoading && (
                '...'
              )}
              {!this.props.priceEstimateLoading && (
                <strong>{`$${price} `}</strong>
              )}
              {!this.props.priceEstimateLoading && (
                <span className="c-price-estimate__interval">{'per hour'}</span>
              )}
            </div>
          </div>
        </div>
      );
    } else if (this.props.query.timeBlocks) {
      const timeBlocks = decodeURIComponent(this.props.query.timeBlocks).split(',').map(i => decodeURIComponent(i)).map(j => {const k = j.split(';'); return {start_time: k[0], end_time: k[1]};}); // eslint-disable-line camelcase
      return (
        <OverlayTrigger
            display
            overlay={<Tooltip>{'Select a time block then you can customize the start and end time'}</Tooltip>}
            placement="top"
        >
          <div className="o-layout">
            {timeBlocks.map((timeBlock, key) => (
              <div
                  className="o-layout__item u-1/1"
                  {...{key}}
              >
                <FormButton
                    className="c-form-button--primary c-time-block c-form-button--block"
                    label={moment(timeBlock.start_time).format('MMM D h:mma')}
                    onClick={this.props.handleTimeBlockClick(timeBlock, bookingNavigationURL)}
                    type="button"
                />
              </div>
            ))}
          </div>
        </OverlayTrigger>
      );
    } else if (this.props.query.exactMatch) {
      return (
        <Link
            className="c-form-button c-form-button--block c-form-button--primary"
            to={bookingNavigationURL}
        >{`Book ${this.props.sitter.first_name}`}</Link>
      );
    } else if (this.props.searchId) {
      return (
        <OverlayTrigger
            display
            overlay={<Tooltip>{'Select a time block then you can customize the start and end time'}</Tooltip>}
            placement="top"
        >
          <div
              className="c-form-button c-form-button--block c-form-button--primary"
              onClick={this.props.handleViewScheduleClick(bookingNavigationURL)}
          >{'View Schedule'}</div>
        </OverlayTrigger>
      );
    } else if (!this.props.isAuthenticated) {
      return (
        <Link
            className="c-form-button c-form-button--block c-form-button--primary"
            to={`/${LOGIN_PAGE}`}
        >{'View Schedule'}</Link>
      );
    }
  }
}
