import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as SVGIcon from 'components/SVGIcon';

export default class FormField extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    hint: PropTypes.string,
    icon: PropTypes.string.isRequired
  };

  render () {
    const {
      error,
      hint,
      icon
    } = this.props;
    const Icon = SVGIcon[icon];
    const message = error || hint;
    const className = classNames({
      'c-form-field': true,
      'c-form-field--error': !!error
    });
    return (
      <div {...{className}}>
        <div className="o-media">
          <div className="o-media__img c-form-field__img">
            {icon && (<Icon />)}
          </div>
          <div className="o-media__body">
            <div className="c-form-field__control">
              {this.props.children}
            </div>
            <div className="c-form-field__hint">{message}</div>
          </div>
        </div>
      </div>
    );
  }
}
