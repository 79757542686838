import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  change as reduxFormChange,
  formValueSelector
} from 'redux-form';
import classNames from 'classnames';
import moment from 'moment';

import {getCondensed} from 'selectors/ui';
import {
  getAddress,
  getChildProfiles,
  getValuesExist,
  getTargetDateActive,
  getTimeRangeActive
} from 'selectors/search';
import {toggleSearchCollapse as toggleSearchCollapseAction} from 'actions/parent/ui';
import {track} from 'utils/track';
import WelcomeIntro from 'containers/parent/WelcomeIntro';
import {openModal as openModalAction} from 'actions/modal';
import SearchForm from 'forms/Search';
import ProviderSearchResults from 'components/ProviderSearchResults';
import {setTitle as setTitleAction} from 'actions/navigation';
import {
  loadSearchState as loadSearchStateAction,
  loadSitterSearch as loadSitterSearchAction,
  setChildrenCount as setChildrenCountAction,
  setEndTime as setEndTimeAction,
  setLocation as setLocationAction,
  setSearch as setSearchAction,
  setStartTime as setStartTimeAction,
  setTargetDate as setTargetDateAction,
  openSearch as openSearchAction,
  closeSearch as closeSearchAction
} from 'actions/parent/search';
/*
import scheduleLogic from 'services/bookingSchedule';
import {
  defaultBookingSettings,
  normalizeScheduleSettings
} from 'reducers/search';
*/

export class Home extends React.Component {
  static propTypes = {
    condensed: PropTypes.bool.isRequired,
    providerSearch: PropTypes.object.isRequired,
    authToken: PropTypes.string.isRequired,
    openSearch: PropTypes.func.isRequired,
    closeSearch: PropTypes.func.isRequired,
    loadSearchState: PropTypes.func.isRequired,
    loadSitterSearch: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    toggleSearchCollapse: PropTypes.func.isRequired,
    query: PropTypes.object,
    search: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    setChildrenCount: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    setEndTime: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
    targetDateActive: PropTypes.bool.isRequired,
    timeRangeActive: PropTypes.bool.isRequired,
    setStartTime: PropTypes.func.isRequired,
    setTargetDate: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    user: PropTypes.object,
    setTitle: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.handleSubmit = this.onSubmit();
  }

  componentDidMount () {
    track('Search Page');
    const {
      loadSearchState,
      query
    } = this.props;
    loadSearchState(query);
    setTimeout(() => {
      this.loadSearch(query);
      if (process.env !== 'production' && query.welcomeIntro === 'open') {
        this.props.openModal(WelcomeIntro, '', {}, {'c-welcome-intro': true});
        track('Welcome Modal');
      }
    }, 0);
  }

  componentWillReceiveProps (nextProps) { // refactor, review this
    if (!this.props.address && nextProps.address && !this.props.childProfiles && nextProps.childProfiles && !Object.keys(nextProps.query).length) { // initial search request
      this.loadSearch({
        ...nextProps.address,
        childProfiles: nextProps.childProfiles.join(',')
      }, true);
    }
    const propsDidChange = !'pets childProfiles streetAddress city state zip lat lng targetDate startTime endTime'.split(' ').reduce((p, c) => {
      return (p && this.props.query[c] === nextProps.query[c]);
    }, true);
    if (propsDidChange) {
      this.props.loadSearchState(nextProps.query);
      this.loadSearch(nextProps.query);
    }
  }

  loadSearch (query, flag = false) { // refactor, still need to clear search when user goes all the way back to state before search
    const {setTitle} = this.props;
    if (!(query.streetAddress && query.city && query.state && query.zip && query.lat && query.lng)) {
      setTitle('Find a Provider');
      return;
    }
    setTitle('Available Providers');
    const location = {
      streetAddress: query.streetAddress,
      city: query.city,
      state: query.state,
      zip: query.zip,
      lat: query.lat,
      lng: query.lng
    };
    const childProfiles = query.childProfiles.split(',');
    const searchRequest = {...query, location, childProfiles};
    setTimeout(() => {
      this.props.loadSitterSearch(this.props.authToken, searchRequest);
      if (flag) {
        this.props.setSearch(query);
      }
    }, 0);
  }

  onSubmit () {
    return ({providerSearch}) => {
      this.props.toggleSearchCollapse(true);
      track('Search Page - Conduct Search');
      const {
        address,
        children,
        pets,
        schedule
      } = providerSearch;
      const query = { // refactor, extract selector
        childProfiles: children,
        childrenCount: children.length,
        city: address.city,
        lat: address.lat,
        lng: address.lng,
        pets: pets,
        state: address.state,
        streetAddress: address.streetAddress,
        zip: address.zip
      };
      if (schedule.timeRangeActive) {
        query.startTime = schedule.startTime;
        query.endTime = schedule.endTime;
      }
      if (schedule.targetDateActive) {
        query.targetDate = schedule.targetDate;
      }
      this.props.setSearch(query);
    };
  }

  loadSuggestion () {
    return ({start_time, end_time}) => { // eslint-disable-line camelcase
      const {query} = this.props;
      const newQuery = {
        ...query,
        startTime: start_time, // eslint-disable-line camelcase
        endTime: end_time // eslint-disable-line camelcase
      };
      this.props.setSearch(newQuery);
      // this.props.loadSearchState(newQuery);
      // this.loadSearch(newQuery);
    };
  }

  render () { // refactor, this method is too large
    const {
      toggleSearchCollapse,
      condensed,
      authToken,
      providerSearch,
      query,
      onFormChange,
      search,
      openSearch,
      router,
      closeSearch,
      targetDateActive,
      timeRangeActive,
      loading,
      settings: {
        constants
      },
      user
    } = this.props;
    if (loading.constants || !user) {
      return null;
    }
    let addressLocation = JSON.parse(search.searchForm.location);
    const addressExists = 'streetAddress city state zip'.split(' ').reduce((p, c) => { // refactor, repeated
      return (p && !!addressLocation[c]);
    }, true);
    if (!addressExists && user.default_location) {
      addressLocation = {
        city: user.default_location.city,
        lat: user.default_location.geolocation.lat,
        lng: user.default_location.geolocation.lng,
        state: user.default_location.state,
        streetAddress: user.default_location.street,
        zip: user.default_location.zip_code
      };
    }
    const centerSearchForm = !loading.search && search.result === null;
    const searchClassNames = classNames({ // left side
      'o-layout__item': true,
      'u-1/1': true,
      'u-4/10@desktop': true,
      'u-push-3/10@desktop': centerSearchForm
    });
    const resultClassNames = classNames({ // right side
      'o-layout__item': true,
      'u-1/1': true,
      'u-5/10@desktop': true,
      'u-push-1/10@desktop': true
    });
    const children = (search.searchForm.childProfiles.length && search.searchForm.childProfiles) || user.child_profiles.map(({id}) => `${id}`).slice(-3);
    const initialValues = {
      providerSearch: {
        address: addressLocation,
        schedule: {
          endTime: search.searchForm.endTime,
          startTime: search.searchForm.startTime,
          targetDate: moment(search.searchForm.targetDate).startOf('day').toISOString(),
          targetDateActive: !!query.targetDate,
          timeRangeActive: !!query.startTime || !!query.endTime
        },
        children,
        pets: search.searchForm.pets
      }
    };
    return ( // refactor, Search container is being refactored
      <div className="o-layout">
        <div className={searchClassNames}>
          <h2 className="c-search-header">{'Find a Provider'}</h2>
          <SearchForm
              childProfiles={user.child_profiles}
              onSubmit={this.handleSubmit}
              {...{initialValues, constants, onFormChange, providerSearch, condensed, toggleSearchCollapse, timeRangeActive, targetDateActive}}
          />
        </div>
        <div className={resultClassNames}>
          {(loading.search || search.result) && (
            <ProviderSearchResults
                loadSuggestion={this.loadSuggestion()}
                {...{search, query, loading, closeSearch, openSearch, authToken, router, user}}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps (state, ownProps) {
  const {
    form,
    search,
    session: {
      authToken,
      user
    },
    settings,
    loading,
  } = state;
  const {
    location: {query},
    router
  } = ownProps;
  let providerSearch = {};
  if (form.search) {
    providerSearch = form.search.values.providerSearch;
  }
  const selector = formValueSelector('search');
  const valuesExist = getValuesExist(state, ownProps);
  return {
    childProfiles: valuesExist ? getChildProfiles(state, ownProps) : false,
    address: valuesExist ? getAddress(state, ownProps) : false,
    targetDateActive: valuesExist ? getTargetDateActive(state, ownProps) : false,
    timeRangeActive: valuesExist ? getTimeRangeActive(state, ownProps) : false,
    condensed: getCondensed(state, ownProps),
    providerSearch,
    authToken,
    user,
    query,
    search,
    loading,
    settings,
    startTime: selector(state, 'providerSearch.schedule.startTime'),
    router,
    endTime: selector(state, 'providerSearch.schedule.endTime'),
    targetDate: selector(state, 'providerSearch.schedule.startTime')
  };
}

const mapDispatchToProps = {
  loadSearchState: loadSearchStateAction,
  loadSitterSearch: loadSitterSearchAction,
  onFormChange: reduxFormChange,
  setChildrenCount: setChildrenCountAction,
  setEndTime: setEndTimeAction,
  setLocation: setLocationAction,
  setSearch: setSearchAction,
  closeSearch: closeSearchAction,
  setStartTime: setStartTimeAction,
  setTargetDate: setTargetDateAction,
  openSearch: openSearchAction,
  setTitle: setTitleAction,
  toggleSearchCollapse: toggleSearchCollapseAction,
  openModal: openModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
