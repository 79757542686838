import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {change as reduxFormChange} from 'redux-form';
import {browserHistory} from 'react-router';
import ProfileMainAction from 'components/parent/ProfileMainAction';
import {PAYMENT_STEP} from 'constants/parent/signUpFlow';
import TimePicker from 'components/TimePicker';
import * as SVGIcon from 'components/SVGIcon';
import FormButton from 'forms/controls/FormButton';
import PortraitFrame from 'components/PortraitFrame';
import VideoPlayer from 'components/VideoPlayer';
import SchedulePicker from 'components/SchedulePicker';
import ProfileSkillBadges from 'components/ProfileSkillBadges';
import ProfileVerificationBadges from 'components/ProfileVerificationBadges';
import {
  createBooking as createBookingAction,
  getPriceQuote as getPriceQuoteAction
} from 'actions/parent/booking';
import {
  openModal as openModalAction,
  closeModal as closeModalAction
} from 'actions/modal';
import {track} from 'utils/track';
import {
  BOOKING_CONFIRMATION_PAGE,
  COMPLETE_REGISTRATION_PAGE,
  LOGIN_PAGE,
  PROFILE_PAGE,
} from 'constants/parent/navigation';
import {
  loadSitterProfile as loadSitterProfileAction,
  loadSitterReviews as loadSitterReviewsAction,
  favoriteSitter as favoriteSitterAction,
  unfavoriteSitter as unfavoriteSitterAction,
  loadSchedule as loadScheduleAction
} from 'actions/parent/profile';
import {
  loadSitterSearch as loadSitterSearchAction
} from 'actions/parent/search';
import {loadFavorites as loadFavoritesAction} from 'actions/parent/profile';
import ProfileReviews from 'components/ProfileReviews';
import {
  getCurrentPage,
  getReviewsCount,
  getReviewsPerPage,
  getAvailabilities,
  getLoading,
  getFavorites,
  getSitter,
  getReviewsPageCount,
  getReviews,
} from 'selectors/parent/profile';
import {
  getUser,
  getIsAuthenticated,
  getAuthToken
} from 'selectors/session';
import {
  getEstimate
} from 'selectors/bookings';
import {
  getBadges
} from 'selectors/settings';

export class Profile extends React.Component {
  static propTypes = {
    getPriceQuote: PropTypes.func.isRequired,
    loadSitterSearch: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    availabilities: PropTypes.array.isRequired,
    loadSchedule: PropTypes.func.isRequired,
    authToken: PropTypes.string,
    id: PropTypes.string.isRequired,
    loadSitterReviews: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    favoriteSitter: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    unfavoriteSitter: PropTypes.func.isRequired,
    priceEstimate: PropTypes.object,
    reviews: PropTypes.object,
    search: PropTypes.string,
    searchId: PropTypes.string,
    sitter: PropTypes.object.isRequired,
    user: PropTypes.object,
    favorites: PropTypes.array.isRequired,
    loadFavorites: PropTypes.func.isRequired,
    reviewsTotalCount: PropTypes.number.isRequired,
    reviewsCountPerPage: PropTypes.number.isRequired,
    reviewsPageCount: PropTypes.number.isRequired,
    reviewsCurrentPage: PropTypes.number.isRequired,
    badges: PropTypes.object
  };

  state = {
    loadingDefaultSearch: false,
    scheduleSelected: false,
    bookingLocation: '',
    showAllReviews: true,
    priceEstimateLoading: false,
  };

  constructor (props) {
    super(props);
    this.handleFavorite = this.onFavorite();
    this.handleUnfavorite = this.onUnfavorite();
    if (!!this.props.sitter.id && this.props.query.exactMatch) {
      setTimeout(() => {
        this.handleTimeRangeSelect({
          startTime: this.props.query.startTime,
          endTime: this.props.query.endTime,
          targetDate: this.props.query.targetDate
        }, `/${BOOKING_CONFIRMATION_PAGE}/${this.props.sitter.id}${this.props.search}`, this.props.user, true);
      }, 0);
    }
  }

  componentDidMount () {
    track('Provider Profile Page');
    const {
      authToken,
      id,
      loadSitterProfile,
      loadSitterReviews,
      loadSchedule,
      reviewsCountPerPage,
      reviewsCurrentPage
    } = this.props;
    if (authToken) {
      loadSitterProfile(authToken, id);
      loadSitterReviews(authToken, id, reviewsCountPerPage, reviewsCurrentPage);
      loadSchedule(authToken, id);
    } else {
      loadSitterProfile(null, id);
      loadSitterReviews(null, id, reviewsCountPerPage, reviewsCurrentPage);
    }
  }

  componentWillReceiveProps (newProps) {
    if (!this.props.authToken && !!newProps.authToken) {
      newProps.loadSitterProfile(newProps.authToken, newProps.id);
      newProps.loadSitterReviews(newProps.authToken, newProps.id, newProps.reviewsCountPerPage, newProps.reviewsCurrentPage);
      newProps.loadSchedule(newProps.authToken, newProps.id);
    }

    const options = {
      startTime: newProps.query.startTime,
      endTime: newProps.query.endTime,
      targetDate: newProps.query.targetDate
    };
    const bookingLocation = `/${BOOKING_CONFIRMATION_PAGE}/${newProps.sitter.id}${newProps.search}`;
    if (!!newProps.user && this.props.query.exactMatch && (!this.props.sitter.id && !!newProps.sitter.id)) {
      this.handleTimeRangeSelect(options, bookingLocation, newProps.user, true);
    }
    if ((!this.props.user && !!newProps.user) && this.props.query.exactMatch && !!newProps.sitter.id) {
      this.handleTimeRangeSelect(options, bookingLocation, newProps.user, true);
    }

    if (newProps && (this.props.reviewsCurrentPage != newProps.reviewsCurrentPage)) {
      // Handle reviews pagination change
      newProps.loadSitterReviews(newProps.authToken, newProps.id, newProps.reviewsCountPerPage, newProps.reviewsCurrentPage);
    }
  }

  showVideo (videoUrl, posterUrl, title) {
    return () => {
      this.props.openModal(VideoPlayer, title, {
        posterUrl,
        videoUrl
      });
    };
  }

  generateExperienceCopy (skillList) {
    const first = skillList.sort()[0];
    let prefix = '';
    if (first === '0_mos_12_mos') {
      prefix = 'Newborns and children';
    } else if (first === '1_yrs_3_yrs') {
      prefix = 'Toddlers and children';
    } else if (first === '3_yrs_5_yrs') {
      prefix = 'Children from 3 years';
    } else {
      prefix = 'Children from 6 years';
    }
    return `${prefix} up to 12 years old`;
  }

  onFavorite () {
    return () => {
      this.props.favoriteSitter(this.props.authToken, this.props.sitter.id).then(() => {
        this.props.loadSitterProfile(this.props.authToken, this.props.id);
        this.props.loadFavorites(this.props.authToken);
      });
    };
  }

  onUnfavorite () {
    return () => {
      this.props.unfavoriteSitter(this.props.authToken, this.props.sitter.id).then(() => {
        this.props.loadSitterProfile(this.props.authToken, this.props.id);
        this.props.loadFavorites(this.props.authToken);
      });
    };
  }

  openSchedulePicker (query, availabilities, firstName) {
    return new Promise((resolve, reject) => {
      this.props.openModal(SchedulePicker, `${firstName}'s Availability`, {
        query,
        availabilities,
        resolve,
        reject
      });
    });
  }

  openTimePicker (timeBlock) {
    return new Promise((resolve, reject) => {
      this.props.openModal(TimePicker, 'Pick Time', {
        onFormChange: this.props.onFormChange,
        timeBlock,
        resolve,
        reject
      });
    });
  }

  addTimesToBookingLocation ({startTime, endTime, targetDate}, bookingLocation) {
    const [first, second] = bookingLocation.split('?');
    const params = second.split('&').reduce((p, c) => { const a = c.split('='); return Object.assign({}, p, {[a[0]]: decodeURIComponent(a[1])}); }, {});
    params.startTime = startTime;
    params.endTime = endTime;
    params.targetDate = targetDate;
    return first + '?' + Object.keys(params).map(k => `${k}=${encodeURIComponent(params[k])}`).join('&');
  }

  handleViewScheduleClick = (bookingLocation) => {
    return async () => {
      try {
        const timeBlock = await this.openSchedulePicker(this.props.query, this.props.availabilities, this.props.sitter.first_name);
        this.props.closeModal();
        const timeRange = await this.openTimePicker(timeBlock);
        this.props.closeModal();
        this.handleTimeRangeSelect(timeRange, bookingLocation, this.props.user);
      } catch (e) {
        console.log('schedule modal catch', e);
      }
    };
  }

  async handleTimeRangeSelect (timeRange, bookingLocation, user, exactMatch = false) {
    this.setState({ // eslint-disable-line react/no-set-state
      scheduleSelected: true,
      priceEstimateLoading: true,
      bookingLocation: exactMatch ? bookingLocation : this.addTimesToBookingLocation(timeRange, bookingLocation)
    });
    const {
      query,
      router
    } = this.props;
    if (!user.status.has_payment_method) { // eslint-disable-line no-constant-condition
      router.replace({
        pathname: `/${COMPLETE_REGISTRATION_PAGE}/${PAYMENT_STEP}`,
        state: {nextPathname: `${router.location.pathname}${router.location.search}`}
      });
    } else {
      const paymentMethodId = user.default_payment_method.id;
      await this.props.getPriceQuote(this.props.authToken, query.searchId, this.props.params.id, paymentMethodId, query.childProfiles.split(','), timeRange);
      this.setState({ // eslint-disable-line react/no-set-state
        priceEstimateLoading: false,
      });
    }
  }

  handleTimeBlockClick = (timeBlock, bookingLocation) => {
    return async () => {
      try {
        const timeRange = await this.openTimePicker(timeBlock);
        this.props.closeModal();
        this.handleTimeRangeSelect(timeRange, bookingLocation, this.props.user);
      } catch (e) {
        console.log('time block modal catch', e);
      }
    };
  }

  updateNavURL = (reviewPage) => {
    if (this.state.loadingDefaultSearch) {
      return;
    }

    const user = this.props.user;
    const providerId = this.props.sitter.id || this.props.id;
    const currentPage = reviewPage || this.props.reviewsCurrentPage;
    const location = {
      streetAddress: user.default_location.street,
      city: user.default_location.city,
      state: user.default_location.state,
      zip: user.default_location.zip_code,
      lat: user.default_location.geolocation.lat,
      lng: user.default_location.geolocation.lng
    };
    const locationQuery = Object.keys(location).map(c => `${c}=${encodeURIComponent(location[c])}`).join('&');
    const childProfiles = user.child_profiles.slice(-3).map(c => c.id);
    setTimeout(async () => {
      this.setState({loadingDefaultSearch: true});
      const result = await this.props.loadSitterSearch(this.props.authToken, {childProfiles, location});
      browserHistory.push(`/${PROFILE_PAGE}/${providerId}?reviewPage=${currentPage}&searchId=${result.response.result}&childProfiles=${childProfiles.join(',')}&${locationQuery}`);
      this.setState({loadingDefaultSearch: false});
    }, 0);
  }

  isFavoritedSitter () {
    const sitterId = this.props.sitter.id;
    const favorites = this.props.favorites;
    return favorites.some(favorite => {
      return sitterId === favorite.id; // eslint-disable-line no-unused-expressions
    });
  }

  handlePagination = pageIndex => {
    this.updateNavURL(pageIndex)
  }

  render () { /* eslint-disable camelcase */
    if (this.props.loading || (this.props.isAuthenticated && !this.props.user)) {
      return null;
    }

    if (this.props.isAuthenticated && (!this.props.searchId && !this.props.loadingDefaultSearch)) {
      this.updateNavURL();
      return null;
    }

    const sitterProfileClassName = classNames({
      'o-layout__item': true,
      'u-1/1': true,
      'u-3/5@desktop': true,
      'c-sitter-profile': true,
      'u-push-1/5@desktop': false
    });
    const actionsClassName = classNames({
      'u-hide u-show@desktop': true,
      'o-layout__item': true,
      'u-1/1@desktop': true,
      'u-1/2': !!this.props.searchId,
      'u-1/1': !this.props.searchId
    });

    const favorited = this.isFavoritedSitter();
    let reviews = []
    if (this.props.reviews) {
      reviews = Object.values(this.props.reviews)
    }

    return (
      <div>
        <div className="c-action-well">
          <div className="o-wrapper">
            <div className="o-layout">
              <div className="o-layout__item u-1/1 u-2/3@desktop u-push-1/6@desktop">
                <div className="c-action-well__wrapper">
                  <div className="o-layout">
                    <div className={actionsClassName}>
                      {!this.props.isAuthenticated && (
                        <div>
                          <Link
                              className="c-form-button c-form-button--block c-form-button--primary"
                              to={`/${LOGIN_PAGE}`}
                          >{'Favorites'}</Link>
                        </div>
                      )}
                      {this.props.isAuthenticated && (
                        <div>
                          <FormButton
                              className="c-form-button--primary c-form-button--inverse c-form-button--block"
                              icon="Favorites"
                              iconProps={{active: true, selected: favorited}}
                              label={favorited ? 'Remove Favorite' : 'Add to Favorites'}
                              onClick={favorited ? this.handleUnfavorite : this.handleFavorite}
                              type="button"
                          />
                        </div>
                      )}
                    </div>
                    <div className="o-layout__item u-1/1">
                      <ProfileMainAction
                          bookingLocation={this.state.bookingLocation}
                          handleTimeBlockClick={this.handleTimeBlockClick}
                          handleViewScheduleClick={this.handleViewScheduleClick}
                          isAuthenticated={this.props.isAuthenticated}
                          priceEstimate={this.props.priceEstimate}
                          priceEstimateLoading={this.state.priceEstimateLoading}
                          query={this.props.query}
                          scheduleSelected={this.state.scheduleSelected}
                          search={this.props.search}
                          searchId={this.props.searchId}
                          sitter={this.props.sitter}
                          user={this.props.user}
                      />
                      <div className="c-helper-blurb c-text-small c-text-small--muted">{'You will have an opportunity to confirm your booking details'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="o-layout c-sitter-profile-layout">
          <div className={sitterProfileClassName}>
            <div className="c-sitter-profile__details">
              <div className="o-layout">
                <div className="o-layout__item u-1/1 u-2/5@desktop">
                  {!this.props.isAuthenticated && (
                    <div className="c-sitter-profile__action-menu u-hide@desktop">
                      <ul className="o-list-bare">
                        <li>
                          <Link
                              className="c-form-button c-form-button--primary c-form-button--inverse"
                              to={`/${LOGIN_PAGE}`}
                          >
                            <SVGIcon.Favorites
                                active
                                selected={favorited}
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                  {this.props.isAuthenticated && (
                    <div className="c-sitter-profile__action-menu u-hide@desktop">
                      <ul className="o-list-bare">
                        <li>
                          <FormButton
                              className="c-form-button--primary c-form-button--inverse"
                              icon="Favorites"
                              iconProps={{active: true, selected: favorited}}
                              onClick={favorited ? this.handleUnfavorite : this.handleFavorite}
                              type="button"
                          />
                        </li>
                      </ul>
                    </div>
                  )}
                  <PortraitFrame
                      classes={['c-portrait-frame--large c-portrait-frame--mobile']}
                      src={this.props.sitter.photo_url || ''}
                  />
                  { this.props.sitter.video_url && (
                    <div className="c-sitter-profile__play-video">
                      <FormButton
                          className="c-form-button--primary c-form-button--block c-sitter-profile__play-button"
                          icon="Play"
                          iconProps={{active: true}}
                          label={'Play Video'}
                          onClick={this.showVideo(this.props.sitter.video_url, this.props.sitter.photo_url, this.props.sitter.first_name)}
                          type="button"
                      />
                    </div>)}
                </div>
                <div className="o-layout__item u-1/1 u-3/5@desktop">
                  <h2 className="c-sitter-profile__name c-h1">{`${this.props.sitter.first_name}`}</h2>
                  <div className="c-sitter-profile__info c-text-small c-text-small--muted">
                    <span>{`${Math.floor(this.props.sitter.age)} years old`}</span> {'·'} <span>{`${this.props.sitter.default_location.city}, ${this.props.sitter.default_location.state}`}</span>
                  </div>
                  <ProfileVerificationBadges
                      badges={this.props.badges}
                      verificationList={this.props.sitter.verification_list}
                  />
                  <ProfileSkillBadges
                      badges={this.props.badges}
                      skillsList={this.props.sitter.skill_list}
                  />
                </div>
              </div>
            </div>
            <div className="c-sitter-profile__summary">
              <div className="c-sitter-profile__experience">
                <h6 className="c-sitter-profile__experience-leader c-h4">{'Experience with'}</h6>
                <h5 className="c-text-strong c-text-strong--super-strong">{this.generateExperienceCopy(this.props.sitter.skill_list)}</h5>
              </div>
              <p>{this.props.sitter.summary}</p>
            </div>
            <div>
              <h6 className="c-sitter-profile__section-title c-h4">{`${this.props.reviewsTotalCount} Reviews`}</h6>
              <ProfileReviews
                  currentPage={this.props.reviewsCurrentPage}
                  isAuthenticated={this.props.isAuthenticated}
                  onPageChange={this.handlePagination}
                  pageCount={this.props.reviewsPageCount}
                  reviews={reviews}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } /* eslint-enable camelcase */
}

function mapStateToProps (state, ownProps) {
  const {
    params: {
      id
    },
    location: {
      query,
      search
    },
    router
  } = ownProps;
  const {
    searchId
  } = query;
  return {
    router,
    availabilities: getAvailabilities(state, ownProps),
    isAuthenticated: getIsAuthenticated(state, ownProps),
    search,
    authToken: getAuthToken(state, ownProps),
    id,
    loading: getLoading(state, ownProps),
    priceEstimate: getEstimate(state, ownProps),
    query,
    searchId,
    sitter: getSitter(state, ownProps),
    user: getUser(state, ownProps),
    favorites: getFavorites(state, ownProps),
    reviewsTotalCount: getReviewsCount(state, ownProps),
    reviewsCountPerPage: getReviewsPerPage(state, ownProps),
    reviewsCurrentPage: getCurrentPage(state, ownProps),
    reviewsPageCount: getReviewsPageCount(state, ownProps),
    reviews: getReviews(state, ownProps),
    badges: getBadges(state, ownProps)
  };
}

const mapDispatchToProps = {
  onFormChange: reduxFormChange,
  loadSitterSearch: loadSitterSearchAction,
  closeModal: closeModalAction,
  loadSchedule: loadScheduleAction,
  createBooking: createBookingAction,
  loadSitterProfile: loadSitterProfileAction,
  favoriteSitter: favoriteSitterAction,
  unfavoriteSitter: unfavoriteSitterAction,
  loadSitterReviews: loadSitterReviewsAction,
  getPriceQuote: getPriceQuoteAction,
  openModal: openModalAction,
  loadFavorites: loadFavoritesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
