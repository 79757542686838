import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {closeModal as closeModalAction} from 'actions/modal';
import DoneButton from 'forms/fields/DoneButton';
import {LOGIN_PAGE} from 'constants/parent/navigation';

import {browserHistory} from 'react-router';

export class PasswordResetSuccess extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props);
    this.handleSubmit = this.onSubmit();
  }

  onSubmit () {
    return () => {
      this.props.closeModal();
      browserHistory.push(`/${LOGIN_PAGE}`);
    };
  }

  render () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <p className="u-center-text">{'Check your email for the reset link'}</p>
          <DoneButton onClick={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return () => {
    return {
    };
  };
};

const mapDispatchToProps = {
  closeModal: closeModalAction
};

export default connect(makeMapStateToProps, mapDispatchToProps)(PasswordResetSuccess);
