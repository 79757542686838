import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Truncate from 'react-truncate';
import moment from 'moment';
import classNames from 'classnames';

import FormButton from 'forms/controls/FormButton';
import PortraitFrame from 'components/PortraitFrame';
import {
  OPEN_SEARCHES_PAGE,
  COMPLETE_REGISTRATION_PAGE,
  PROFILE_PAGE
} from 'constants/parent/navigation';
import {PAYMENT_STEP} from 'constants/parent/signUpFlow';

function isFullyQualifiedSearch (query) {
  return !!(query.startTime && query.endTime);
}

export default class ProviderSearchResults extends React.Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    loadSuggestion: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    openSearch: PropTypes.func.isRequired,
    closeSearch: PropTypes.func.isRequired
  };

  buildOpenSearch (search) {
    return (
      <div className="u-margin-bottom-large">
        <div className="o-layout">
          <div className="o-layout__item u-1/1">
            <div className="c-no-providers__primary u-margin-bottom">{'All of our providers are booked for that time'}</div>
            <div className="c-no-providers__alternate u-margin-bottom">{'Would you like us to notify you when someone becomes available?'}</div>
          </div>
          <div className="o-layout__item u-1/1">
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="Notify me when someone becomes available"
                onClick={() => {
                  this.props.openSearch(this.props.authToken, search.result.id);
                }}
                type="button"
            />
          </div>
          <div className="o-layout__item u-1/1">
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="Book the next available provider"
                onClick={() => {
                  const {
                    router,
                    user
                  } = this.props;
                  if (!user.status.has_payment_method) { // eslint-disable-line no-constant-condition
                    router.replace({
                      pathname: `/${COMPLETE_REGISTRATION_PAGE}/${PAYMENT_STEP}`,
                      state: {nextPathname: `${router.location.pathname}${router.location.search}`}
                    });
                  } else {
                    this.props.openSearch(this.props.authToken, search.result.id, true);
                  }
                }}
                type="button"
            />
          </div>
        </div>
      </div>
    );
  }

  buildCloseSearch (search) {
    return (
      <div className="u-margin-bottom-large">
        <div className="o-layout">
          <div className="o-layout__item u-1/1">
            <div className="c-no-providers__primary u-margin-bottom">{'We are working on your request'}</div>
            <div className="c-no-providers__alternate u-margin-bottom">{'We will get back to you as soon as possible.  You can always visit '}<Link to={`/${OPEN_SEARCHES_PAGE}`}>{'Open Search Requests'}</Link>{' to monitor the status of your request.'}</div>
          </div>
          <div className="o-layout__item u-1/1">
            <FormButton
                className="c-form-button--primary c-form-button--inverse c-form-button--block"
                label="Cancel this request"
                onClick={() => {
                  this.props.closeSearch(this.props.authToken, search.result.id);
                }}
                type="button"
            />
          </div>
        </div>
      </div>
    );
  }

  buildTimeBlocks (timeBlocks) {
    return (
      <ul className="o-list-bare c-time-block">
        {timeBlocks.map((timeBlock, key) => {
          return (
            <li
                className="c-time-block__item"
                {...{key}}
            >
              <div className="c-form-button c-form-button--primary c-form-button--inverse c-time-block">{moment(timeBlock.start_time).format('MMM D h:mma')}</div>
            </li>
          );
        })}
      </ul>
    );
  }

  buildProviders (sitters, exactMatch, fullyQualifiedSearch, searchId, searchParams) { /* eslint-disable camelcase */
    const className = classNames({
      'c-sitter-result__summary': true,
      'u-margin-bottom@desktop': exactMatch || !fullyQualifiedSearch
    });
    return sitters.map(({sitter, time_blocks}) => {
      const {
        photo_url,
        first_name,
        reviews_count,
        references_count,
        id,
        default_location
      } = sitter;
      let queryParams = `searchId=${searchId}&${searchParams}`;
      if (fullyQualifiedSearch && !exactMatch) {
        queryParams += '&timeBlocks=' + encodeURIComponent(time_blocks.map(o => encodeURIComponent([o.start_time, o.end_time].join(';'))));
      } else if (exactMatch) {
        queryParams += '&exactMatch=true';
      }

      const totalReviews = (reviews_count || 0) + (references_count || 0)

      return (
        <Link
            className="c-sitter-result"
            key={id}
            to={`/${PROFILE_PAGE}/${id}?${queryParams}`}
        >
          <div className="o-media c-sitter-result__body u-margin-bottom-large@desktop">
            <div className="o-media__img">
              <PortraitFrame
                  classes={['c-portrait-frame--medium']}
                  src={photo_url || ''}
              />
            </div>
            <div className="o-media__body">
              <h3 className="c-sitter-result__title">{`${first_name}`}</h3>
              {default_location && (
                <div className="c-sitter-result__location">{`${default_location.city}, ${default_location.state}`}</div>
              )}
              {!!reviews_count && (
                <p {...{className}}>
                  <Truncate
                      ellipsis={<span>{'...'}</span>}
                      lines={3}
                  >{`${totalReviews} ${totalReviews === 1 ? 'Review' : 'Reviews' }`}</Truncate>
                </p>
              )}
              {(fullyQualifiedSearch && !exactMatch) && (
                this.buildTimeBlocks(time_blocks)
              )}
            </div>
          </div>
        </Link>
      );
    });
  } /* eslint-enable camelcase */

  render () {
    if (this.props.loading.search) {
      return (<h2 className="c-h2">{'Searching...'}</h2>);
    }
    const query = this.props.query; // refactor, extract selectors
    const searchParams = Object.keys(query).map(k => k + '=' + encodeURIComponent(query[k])).join('&');
    const searchId = this.props.search.result.id;
    const fullyQualifiedSearch = isFullyQualifiedSearch(query);
    const {
      results,
      suggestions,
      status
    } = this.props.search.result;
    const isSearchOpen = status === 'open';
    return (
      <div>
        <h2 className="c-search-header">{'Available Providers'}</h2>
        <div>
          {!!results.length && (
            this.buildProviders(results, true, fullyQualifiedSearch, searchId, searchParams, query)
          )}
        </div>
        {(!results.length && fullyQualifiedSearch && isSearchOpen) && (
          this.buildCloseSearch(this.props.search)
        )}
        {(!results.length && fullyQualifiedSearch && !isSearchOpen) && (
          this.buildOpenSearch(this.props.search)
        )}
        {(!!results.length && !!suggestions.length) && (
          <h3 className="c-h3 c-line-behind u-margin-top u-margin-bottom-large c-text-small c-text-small--muted">{'Providers available at similar times'}</h3>
        )}
        <div>
          {!!suggestions.length && (
            this.buildProviders(suggestions, false, fullyQualifiedSearch, searchId, searchParams, query)
          )}
        </div>
      </div>
    );
  }
}
